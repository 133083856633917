export default {
  permissionType: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  groupID: {
    value: null,
    options: [],
    required: false,
    state: null
  },
  accountID: {
    value: null,
    options: [],
    required: false,
    state: null
  },
  permissionAvailable: {
    value: [],
    options: [],
    required: true,
    defaultArray: true,
    state: null
  },
  permissionUsed: {
    value: [],
    options: [],
    required: true,
    defaultArray: true,
    state: null
  }
}
