import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'

import ContactTypeRepository from '@/shared/http/repositories/settings/contacttype.js'
import ContactTypepModel from '@/shared/models/settings/contacttypemodel.js'
import ContactTypeForms from './contacttype-form'

export default {
  name: 'ContactType',
  mixins: [ActionsForm],
  data () {
    return {
      data: ContactTypepModel,
      form: ContactTypeForms
    }
  },
  methods: {
    onLoad () {
      loading.push()
      ContactTypeRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then((response) => {
          this.onResultGrid(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onCreate () {
      loading.push()
      ContactTypeRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      ContactTypeRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      ContactTypeRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}
