<template>
  <div class="w-100 h-100 d-flex align-items-center justify-content-center">
    <h2 class="text-third">Autenticando...</h2>
  </div>
</template>

<script>
import JWTService from '@/shared/services/jwt.js'
import APIClient from '@/shared/http/clients/api'

import { mapActions } from 'vuex'
import CommonMixin from '@/store/module/common/mixin'
import { globalActions as authActions } from '@/store/module/auth/actionTypes'

export default {
  name: 'SupportAuth',
  mixins: [CommonMixin],
  mounted() {
    this.onTryAuthenticate()
  },
  methods: {
    ...mapActions({
      fetchLoggedInUserInfo: authActions.FETCH_USER_INFO
    }),
    async onTryAuthenticate () {
      const token = decodeURIComponent(this.$route.query.token)

      await JWTService.SaveToken(token)
      APIClient.defaults.headers.common.Authorization = `Bearer ${token}`

      const result = await this.fetchLoggedInUserInfo(true)

      if (result) {
        this.$router.push({ name: 'StartPage' })
      } else {
        this.$router.push({ name: 'SignIn' })
      }
    }
  }
}
</script>