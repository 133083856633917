import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'

import OriginLeadRepository from '@/shared/http/repositories/settings/originlead.js'
import OriginLeadpModel from '@/shared/models/settings/originleadmodel.js'
import OriginLeadForms from './originlead-form'

export default {
  name: 'OriginLead',
  mixins: [ActionsForm],
  data () {
    return {
      data: OriginLeadpModel,
      form: OriginLeadForms
    }
  },
  methods: {
    onLoad () {
      if (!this.isModal) {
        loading.push()
        OriginLeadRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
          .then((response) => {
            this.onResultGrid(response)
          })
          .catch((err) => {
            this.onException(err)
          })
      }
    },
    onCreate () {
      loading.push()
      OriginLeadRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      OriginLeadRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      OriginLeadRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}
