export default {
  contactTypeID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  reminder: {
    value: null,
    required: false,
    state: null
  },
  description: {
    value: '',
    maxlength: '250',
    required: true,
    rows: '4',
    noResize: true
  }
}