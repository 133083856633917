<template>
  <header class="app-header navbar">
    <slot />
  </header>
</template>
<script>
export default {
  name: 'NavbarApp',
  created () {
    this._navbar = true
  }
}
</script>
