<template>
  <div v-if="showModal" class="animated fadeIn">
    <!--size="xl"-->
    <b-modal
      ref="modal-dynamic-form"
      size="md"
      id="modal-dynamic-form"
      title="Preencher"
      centered
      @hide="onHide"
    >
      <template #modal-header>
        <div class="w-100">
          <h5 class="text-bold"><i class="fas fa-form"></i> {{title}}</h5>
        </div>
      </template>
      <div class="row">
        <div class="col-md-6">
          <label><small class="text-danger">*</small> Data:</label>
          <b-form-datepicker
            v-model="date.value"
            class="mb-2"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </div>
        <div class="col-md-3">
          <label><small class="text-danger">*</small> Hora Inicial:</label>
          <b-form-input v-model="hour.value" placeholder="00:00" v-mask="['##:##']" autocomplete="off"></b-form-input>
        </div>
        <div class="col-md-3">
          <label><small class="text-danger">*</small> Hora Final:</label>
          <b-form-input v-model="endHour.value" placeholder="00:00" v-mask="['##:##']" autocomplete="off"></b-form-input>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100 text-right">
          <button
            type="button"
            class="btn btn-danger btn-sm mr-2"        
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonSave"
            @click="onHide"
          >
            <i class="fa fa-times-circle" /> {{ $lang.Common.Buttons.Close }}
          </button>
          <button
            type="button"
            class="btn btn-third btn-sm"        
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonSave"
            @click="onSave"
          >
            <i class="fa fa-check-circle" /> {{ $lang.Common.Buttons.Save }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import ActionForms from '@/shared/mixins/actionsForm.js'
import CardRepository from '@/shared/http/repositories/onboarding/card.js'
import CardDetailRepository from '@/shared/http/repositories/onboarding/carddetail.js'

import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'DynamicForm',
  mixins: [
    ActionForms
  ],
  components: {
  },
  props: {
    columns: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showModal: false,
      title: null,
      columnIndex: null,
      cardIndex: null,
      prop: null,
      date: {
        value: null
      },
      hour: {
        value: null
      },
      endHour: {
        value: null
      }
    }
  },
  methods: {
    show (title, columnIndex, cardIndex, prop) {
      this.title = title
      this.columnIndex = columnIndex
      this.cardIndex = cardIndex
      this.prop = prop

      this.showModal = true
      this.date.value = null
      this.hour.value = null
      this.endHour.value = null

      this.$nextTick(() => {
        this.$refs['modal-dynamic-form'].show()
      })
    },
    hide () {
      this.onClear()
      this.$refs['modal-dynamic-form'].hide()
    },
    onClear () {
      this.title = null
      this.columnIndex = null
      this.cardIndex = null
      this.prop = null
      this.date.value = null
      this.hour.value = null
      this.endHour.value = null

      this.$nextTick(() => {
        this.showModal = false
      })
    },
    async onSave () {
      if (this.date.value && this.hour.value && this.endHour.value) {
        const initialDate = this.moment(`${this.date.value} ${this.hour.value}`)
        const endDate = this.moment(`${this.date.value} ${this.endHour.value}`)

        if(endDate.diff(initialDate) > 0) {
          loading.push()

          let card = this.columns[this.columnIndex].cards[this.cardIndex]
          card.cardDetail[this.prop] = this.date.value

          let data = JSON.parse(JSON.stringify(card))
          data.cardDetail[this.prop] = this.moment(this.date.value)
          data.opportunity = null
          console.log(this.prop)
          await CardDetailRepository.UpdateDate(data.id, this.prop, initialDate.format('MM-DD-YYYY HH:mm'), endDate.format('MM-DD-YYYY HH:mm'))
            .then(() => {
              toast.success(this.$lang.Common.Alerts.Success)
            })
            .catch(this.onException)
          
          loading.pop()
          this.hide()
          this.$emit('saved')
        }
      }
    },
    onHide (event) {
      if (event.trigger !== null) {
        this.onClear()
        this.$emit('reload')
      }
    },
    onChangeDate (date) {
      this.date.value = date[0]
    }
  }
}
</script>