import { toast } from '@/shared/utils/UIHelper'
import AuthRepository from '@/shared/http/repositories/access/auth.js'
export default {
  name: 'LostPassword',
  data () {
    return {
      version: process.env.VUE_APP_VERSION,
      email: null
    }
  },
  methods: {
    onResetPassword () {
      if (this.email) {
        this.onSendEmailReset(this.email)
        toast.success('<strong>Solicitação enviada com sucesso!</strong><br><br>Abra seu email para concluir!')
        this.email = null
        var self = this
        setTimeout(() => self.$router.push({ name: 'SignIn' }), 1000);
      } else {
        toast.error('ATENÇÃO! Informe seu email')
      }
    },
    onSendEmailReset(email) {
      let data = {
        email: email,
        token: null
      }
      AuthRepository.ResetPassword(data)
        .then((response) => {
          console.log(response)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
