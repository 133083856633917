<template>
  <div class="animated fadeIn">
    <Forms ref="frmDefault"
           :title="this.$lang.Company.TitleForm"
           :showHelp="true"
           :showClearButton="true"
           :showSaveButton="true"
           v-on:Clear="onClear"
           v-on:Save="onSave"
    >
      <div class="form-group row">
        <div class="col-md-6">
          <label for="txtCompanyName"><small class="text-danger">*</small> {{$lang.Company.LabelName}}:</label>
          <div class="input-group">
            <b-form-input type="text"
                          name="txtCompanyName"
                          :placeholder="$lang.Company.PlaceholderEnterName"
                          v-model="form.name.value"
                          :size="form.name.maxlength"
                          :required="form.name.required"
                          :state="form.name.state"
                          class="text-toupper"
            ></b-form-input>
          </div>
        </div>
        <div class="col-md-6">
          <label for="txtCNPJ"><small class="text-danger">*</small> {{$lang.Company.LabelCNPJ}}:</label>
          <div class="input-group">
            <b-form-input type="text"
                          id="txtCNPJ"
                          name="txtCNPJ"
                          :placeholder="$lang.Company.PlaceholderEnterCNPJ"
                          v-model="form.cnpj.value"
                          :size="form.cnpj.maxlength"
                          :required="form.cnpj.required"
                          :state="form.cnpj.state"
                          v-mask="['##.###.###/####-##','###.###.###/####-##']"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-4">
          <label for="txtGroup"><small class="text-danger">*</small> {{$lang.Common.LabelCoutry}}:</label>
          <div class="input-group">
            <b-form-select v-model="form.countryID.value" :options="form.countryID.options">
              <template slot="first">
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="col-md-4">
          <label for="txtGroup"><small class="text-danger">*</small> {{$lang.Common.LabelState}}:</label>
          <div class="input-group">
            <b-form-select v-model="form.stateID.value" :options="form.stateID.options">
              <template slot="first">
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="col-md-4">
          <label for="txtGroup"><small class="text-danger">*</small> {{$lang.Common.LabelCity}}:</label>
          <div class="input-group">
            <b-form-select v-model="form.cityID.value" :options="form.cityID.options">
              <template slot="first">
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-12">
          <label for="txtDescription"> {{$lang.Company.LabelDescription}}:</label>
          <b-form-textarea id="txtDescription"
                           name="txtDescription"
                           v-model.trim="form.description.value"
                           :placeholder="$lang.Company.PlaceholderEnterDescription"
                           :rows="form.description.rows"
                           :size="form.description.maxlength"
                           :noResize="form.description.noResize"
          ></b-form-textarea>
          <small class="small-help"><i class="fa fa-info-circle"></i> <span v-html="FieldSizeTip"></span>.</small>
        </div>
      </div>
    </Forms>
    <Tables ref="tableGrid"
            :columns="[$lang.Company.DataTable.Name,
                       $lang.Company.DataTable.CNPJ,
                       $lang.Common.DataTable.Status,
                       $lang.Common.DataTable.ActionDate,
                       $lang.Common.DataTable.AccountAction,
                       $lang.Common.DataTable.Actions]"
            :showSearch="false"
            :showPrint="false"
            :showDownload="false"
            :showFullscreen="false"
            v-on:Load="onLoad"
    >
      <tr v-for="obj in this.objList" :key="obj.id">
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.name" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.cnpj" />
        <TableColumn :IsStatus="true" v-on:Edit="onEdit(obj)" :status="obj.status" />
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
        <TableColumn :IsActionsButtons="true" v-on:Change="onChange(obj)" v-on:Delete="onDelete(obj)" :status="obj.status" />
      </tr>
    </Tables>
  </div>
</template>

<script>
import Company from './company.js'
export default Company
</script>
