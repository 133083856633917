<template>
  <div class="animated fadeIn">
    <!--size="xl"-->
    <b-modal id="modal-lose" ref="modal-lose" size="lg" centered title="Motivo da perda">
      <template #modal-header>
        <div class="w-100">
          <h5 class="text-bold"><i class="fas fa-user-minus"></i> Motivo da perda</h5>
        </div>
      </template>
      <b-container fluid>
        <div class="form-group row pt-2">
          <div class="col-md-12">
            <label for="txtGroup"><small class="text-danger" v-if="form.reasonLossID.required">*</small> Motivo:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-bullhorn"></i></span>
              <b-form-select v-model="form.reasonLossID.value" :options="form.reasonLossID.options">
                <template slot="first">
                  <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
                </template>
              </b-form-select>
              <span class="input-group-addon" v-b-tooltip.hover title="Clique para adicionar um nova opção" @click="showReasonLossModal"><i class="fas fa-plus"></i></span>
            </div>
          </div>
        </div>
        <div class="form-group row pt-2 pb-2">
          <div class="col-md-12">
            <label for="txtGroup"> Observações:</label>
            <div class="input-group">
              <b-form-textarea
                id="txtDescription"
                name="txtDescription"
                v-model="form.description.value"
                :placeholder="'Utilize essa campo para descrever mais informações sobre o motivo da perda'"
                :rows="6"
                :noResize="true"
              >
              </b-form-textarea>
            </div>
          </div>
        </div>
      </b-container>
      <template #modal-footer>
        <div class="w-100 text-right">
          <button
            type="button"
            class="btn btn-third btn-sm"
            @click="onSave"        
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonSave"
          >
            <i class="fa fa-check-circle" /> {{ $lang.Common.Buttons.Save }}
          </button>&nbsp;
          <button
            type="button"
            class="btn btn-default btn-sm text-secundary"
            @click="hide"
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonClose"
          >
            <i class="fas fa-times-circle"></i> {{ $lang.Common.Buttons.Close }}
          </button>
        </div>
      </template>
    </b-modal>
    <ReasonLoss
      ref="reasonLoss"
      v-on:Updated="onUpdateReasonLoss"
      modalID="reasonLossModal"
      isModal
    />
  </div>
</template>
<script>
import { loading, toast } from '@/shared/utils/UIHelper'
import ActionForms from '@/shared/mixins/actionsForm.js'

import CardRepository from '@/shared/http/repositories/onboarding/card.js'
import ReasonLossRepository from '@/shared/http/repositories/settings/reasonloss.js'
import ReasonLoss from '@/views/private/settings/reasonloss/reasonloss.vue';

export default {
  name: 'Lose',
  mixins: [
    ActionForms
  ],
  props: {
    cardID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      form: {
        reasonLossID: {
          value: null,
          options: [],
          required: false,
          state: null
        },
        description: {
          value: null,
          required: true,
          state: null
        }
      }
    }
  },
  components: {
    ReasonLoss
  },
  methods: {
    show () {
      this.$refs['modal-lose'].show()
      this.onClear()
      this.onLoadAllReasonLoss()
    },
    hide () {
      this.$refs['modal-lose'].hide()
    },
    onClear () {
      this.form.description.value = null
      this.form.description.required = true
      this.form.description.state = null
    },
    onLoadAllReasonLoss () {
      loading.push()
      ReasonLossRepository.GetAll()
        .then((response) => {
          this.form.reasonLossID.options = this.onResultDropdown(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    async onSave () {
      loading.push()

      let data = {
        description: this.form.description.value,
        reasonLossID: this.form.reasonLossID.value
      }
      await CardRepository.UpdateStatus(this.cardID, 2, data)
        .then(res => {
          let { code } = res.data

          if (code === 200) {
            toast.success(this.$lang.Common.Alerts.Success)
            this.$emit('Save')
            this.hide()
          } else {
            toast.error(this.$lang.Common.Alerts.Error)
          }
        })
        .catch(er => {
          console.log(er)
        })

      loading.pop()
    },
    showReasonLossModal () {
      this.hide ()
      this.$bvModal.show('reasonLossModal')
    },
    onUpdateReasonLoss () {
      this.onLoadAllReasonLoss()
    },
  }
}
</script>