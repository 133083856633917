<template>
  <div class="animated fadeIn">
    <BudgetList ref="BudgetList" v-on:New="onNew" v-on:Edit="onEdit" v-on:Reload="onReload" />
    <BudgetAdd ref="BudgetAdd" v-on:Reload="onReload"/>
  </div>
</template>
<script>
import BudgetAdd from './add/budgetadd.vue'
import BudgetList from './list/budgetlist.vue'

export default {
  name: 'Budget',
  props: {
    cardID: {
      type: String,
      required: true
    },
    lead: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      data: null,
      form: null,
    }
  },
  watch: {
    cardID: {
      deep: true,
      immediate: true,
      handler(value) {
        if(value) {
          this.onLoadAll(value)
        }
      }
    }
  },
  computed: {
    getCardID () {
      let result = null
      if (this.cardID) {
        result = this.cardID
      }
      return result
    }
  },
  components: {
    BudgetAdd,
    BudgetList
  },
  methods: {
    onNew () {
      this.$refs.BudgetAdd.onNew(this.lead)
    },
    onEdit(obj) {
      this.$nextTick(() => {
        this.$refs.BudgetAdd.onEdit(this.lead, obj)
      })
    },
    onLoadAll (value) {
      this.$nextTick(() => {
        this.$refs.BudgetList.cardID = value
      })
    },
    onReload () {
      this.$refs.BudgetList.onReload()
      this.onUpdateTimeLine()
    },
    onUpdateTimeLine() {
      this.$emit('UpdateTimeline')
    }
  }
}
</script>
