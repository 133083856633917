import APIClient from '@/shared/http/clients/api'

const resourceName = 'businesstype'
const APIBOnboarding = process.env.VUE_APP_ONBOARDING

const GetAll = (data = {}, config = {}) => APIClient.get(`${APIBOnboarding}/${resourceName}`, data, config)
const GetAllPaging = (limit, index, config = {}) => APIClient.get(`${APIBOnboarding}/${resourceName}?limit=${limit}&index=${index}`, config)
const GetById = (id, config = {}) => APIClient.get(`${APIBOnboarding}/${resourceName}/${id}`, config)
const Create = (data = {}, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}`, data, config)
const Update = (data = {}, config = {}) => APIClient.put(`${APIBOnboarding}/${resourceName}`, data, config)
const Delete = (id, config = {}) => APIClient.delete(`${APIBOnboarding}/${resourceName}/${id}`, config)

const BusinessTypeRepository = {
  GetAll,
  GetAllPaging,
  GetById,
  Create,
  Update,
  Delete
}

export default BusinessTypeRepository
