<template>
  <div class="animated fadeIn card p-4 mb-1">
    <div class="form-group row">
      <div class="col-md-12 text-left">
        <h6 class="text-third text-bold"><i class="fas fa-comments"></i> FOLLOW UP</h6>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-6 pt-2">
        <label for="txtGroup"><small class="text-danger">*</small> Forma de contato:</label>
        <div class="input-group">
          <span class="input-group-addon"><i class="fas fa-headset"></i></span>
          <b-form-select
            v-model="form.contactTypeID.value"
            :options="form.contactTypeID.options"
            :required="form.contactTypeID.required"
            :status="form.contactTypeID.status"
          >
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-3 pt-2">
        <label>Lembrar-me em:</label>
        <b-form-datepicker
          v-model="dateTime.date"
          placeholder="Selecionar"
          style="z-index: 9999;"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </div>
      <div class="col-md-2 pt-2">
        <label>às</label>
        <div class="input-group">
          <b-form-input type="text"
                        :placeholder="'00:00'"
                        v-model="dateTime.hour"
                        :size="'5'"
                        :required="form.reminder.required"
                        :state="form.reminder.state"
                        v-mask="['##:##']"
          ></b-form-input>
        </div>
      </div>
      <div class="col-md-1 pt-2">
        <label for="txtGroup">&nbsp;</label>
        <div>
          <b-button class="btn btn-third" size="sm" @click="onGotToNotification" v-b-tooltip.hover title="Clique para visualizar os Lembretes"><i class="fas fa-bell"></i></b-button>
        </div>
      </div>
    </div>
    <div class="form-group row pt-2">
      <div class="col-md-12">
        <label for="txtGroup"><small class="text-danger">*</small> Descrição da conversa:</label>
        <div class="input-group">
          <b-form-textarea 
            id="txtDescription"
            name="txtDescription"
            v-model="form.description.value"
            :placeholder="'Digite as informações sobre o que foi conversado'"
            :rows="form.description.rows"
            :size="form.description.maxlength"
            :noResize="form.description.noResize"
            :required="form.description.required"
            :status="false"
          >
          </b-form-textarea>
        </div>
      </div>
    </div>
    <div class="form-group row pt-3" v-if="hasRole(this.roles.RW_CRM_ADDEDFOLLOWUP)">
      <div class="col-md-12">
        <b-button class="btn btn-sm btn-default" size="sm" @click="onClear"><i class="fas fa-minus-circle"></i>&nbsp; Limpar</b-button>&nbsp;
        <b-button class="btn btn-sm btn-third" size="sm" @click="onSave"><i class="fas fa-plus-circle"></i>&nbsp; Gravar</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import Followup from './followup.js'
export default Followup
</script>
