<template>
  <div class="animated fadeIn">
    <div class="form-group row">
      <div class="col-md-12">
        <h3 class="pt-2 pb-2"><i class="fas fa-address-book"></i> Contatos</h3>
        <Tables ref="tableGrid"
                :columns="['Contato',
                           'Reponsável',
                           'Telefone',
                           'Celular',
                           'E-mail',
                           'Produto',
                           'Valor',
                           'Dt. Entrada',
                           'Situação',
                           'Dt. Situação',
                           'Motivo',
                           'Origem',
                           'Reponsável Lead',
                           'Avaliador',
                           'Dt. Interação',
                           'Conta Interação']"
                :showSearch="true"
                :showReload="true"
                :showTipFooter="false"
                v-on:Load="onLoad"
                v-on:Search="onShowSearch"
                v-on:Reload="onLoadAll"
        >
          <tr v-for="obj in this.objList" :key="obj.id">
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.opportunity ? obj.opportunity.name.toUpperCase() : ''" />
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="''" />
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.opportunity ? obj.opportunity.phone : ''" />
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.opportunity ? obj.opportunity.cellPhone : ''" />
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.opportunity ? obj.opportunity.email : ''" />
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.product ? obj.product.name : ''" />
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="toCurrency(obj.opportunityValue)" />
            <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.createDate" />
            <TableColumn :IsCustom="true" v-on:Edit="onEdit(obj)" :status="obj.status">
              <span v-if="obj.leadStatus === 2"><a href="javascript:void(0)" class="text-danger">PERDIDO</a></span>
              <span v-else-if="obj.leadStatus === 1"><a href="javascript:void(0)" class="text-success">GANHOU</a></span>
              <span v-else><a href="javascript:void(0)" >{{obj.boardColumn.name.toUpperCase()}}</a></span>
            </TableColumn>
            <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.leadStatusDate" />
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.reasonLoss ? obj.reasonLoss.name : ''" />
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.opportunity && obj.opportunity.leadOrigin ? obj.opportunity.leadOrigin.name : ''" />
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.cardDetail.manager && obj.cardDetail.manager.name ? obj.cardDetail.manager.name.toUpperCase() : ''" />
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.cardDetail.responsibleAppraiser && obj.cardDetail.responsibleAppraiser.name ? obj.cardDetail.responsibleAppraiser.name.toUpperCase() : ''" />
            <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
          </tr>
        </Tables>
      </div>
    </div>
  </div>
</template>
<script>
import Tools from '@/components/tools/Tools.js'
import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'
import CardRepository from '@/shared/http/repositories/onboarding/card.js'

import SearchScreen from './search/contactsearch.vue'
const searchModel = {
  contactName: null,
  createDateStart: null,
  createDateEnd: null,
  boardColumnID: null,
  leadStatus: null,
  leadOriginID: null,
  managerID: null,
  responsibleAppraiserID: null
}

export default {
  name: 'Contact',
  mixins: [ActionsForm],
  data () {
    return {
      data: null,
      form: null,
      filters: null
    }
  },
  beforeDestroy () {
    this.$eventHub.off('Filter')
    Tools.MinimizedASidebar()
  },
  methods:{
    onLoad () {
      this.onLoadAll()
      this.$eventHub.on('Filter', ({ filters }) => {
        // console.log(filters)
        this.filters = filters
        this.$refs.tableGrid.Clear()
        this.$nextTick(() => {
          this.onLoadAll()
        })
      })
    },
    onLoadAll () {
      loading.push()
      if(this.filters) {
        CardRepository.Search(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex, this.filters)
          .then((response) => {
            this.onResultGrid(response)
          })
          .catch(this.onException)
      } else {
        CardRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
          .then((response) => {
            this.onResultGrid(response)
          })
          .catch(this.onException)
      }
    },
    onEdit (obj) {
      if (obj) {
        this.$router.push({ name: 'QuadroDetalhes', params: { cardID: obj.id, back: 'Contatos' } })
      }
    },
    onShowSearch () {
      let aside = {
        title: 'Pesquisar',
        self: this,
        component: SearchScreen
      }
      this.$eventHub.emit('Search', aside)
    },
    toCurrency (number) {
      return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(number)
    }
  }
}
</script>
