import router from '@/router'
import { JWTService } from '@/shared/services/jwt'
import ClientFactory from '@/shared/factories/client'

const config = {
  baseURL: process.env.APIBaseURLAccess,
  headers: { 'Content-Type': 'application/json' }
}

const APIClient = ClientFactory.New(config)

APIClient.interceptors.request.use((request) => {
  const token = JWTService.GetToken()

  /* eslint-disable-next-line */
  if (token) request.headers.Authorization = `Bearer ${JWTService.GetToken()}`

  return request
}, error => Promise.reject(error))

APIClient.interceptors.response.use(response => response, (error) => {
  console.log(error)
  if (error.response.status.toString().match(/4\d[13]/)) {
    JWTService.DestroyToken()
    router.replace({ name: 'SignIn' })
  }
  return Promise.reject(error)
})

export default APIClient
