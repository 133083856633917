import { globalActions as commonActions } from '@/store/module/common/actions'
import store from '@/store/index'

/**
 * @function performToast
 * @description Exibe um toastr.
 * @param {string} type O tipo de toastr a ser exibido. (success, error, warning ou info)
 * @param {string} msg A mensagem a ser exibida no toastr.
 * @param {string} title O título a ser exibido no toastr.
 * @returns {void}
 */
const performToast = ({ type, title, msg }) => {
  store.dispatch(commonActions.TOAST, {
    type,
    msg,
    title
  }, { root: true })
}

export const loading = {
  /**
   * @function push
   * @description Adiciona um item à fila de itens carregando.
   * @returns {void}
   */
  push () {
    store.dispatch(commonActions.SHOW_LOADING, true, { root: true })
  },

  /**
   * @function pop
   * @description Remove um item da fila de itens carregando.
   * @returns {void}
   */
  pop () {
    store.dispatch(commonActions.SHOW_LOADING, false, { root: true })
  },

  /**
   * @function close
   * @description Remove um item da fila de itens carregando.
   * @returns {void}
   */
  close () {
    this.pop()
  }
}

export const toast = {
  /**
   * @function make
   * @description Exibe um toastr.
   * @param {string} type O tipo de toastr a ser exibido. (success, error, warning ou info)
   * @param {string} msg A mensagem a ser exibida no toastr.
   * @param {string} title O título a ser exibido no toastr.
   * @returns {void}
   */
  make: performToast,

  /**
   * @function error
   * @description Exibe um toastr do tipo error.
   * @param {string} msg A mensagem a ser exibida no toastr.
   * @param {string} title O título a ser exibido no toastr.
   * @returns {void}
   */
  error (msg, title) {
    performToast({ type: 'error', msg, title })
  },

  /**
   * @function warning
   * @description Exibe um toastr do tipo warning.
   * @param {string} msg A mensagem a ser exibida no toastr.
   * @param {string} title O título a ser exibido no toastr.
   * @returns {void}
   */
  warning (msg, title) {
    performToast({ type: 'warning', msg, title })
  },

  /**
   * @function info
   * @description Exibe um toastr do tipo info.
   * @param {string} msg A mensagem a ser exibida no toastr.
   * @param {string} title O título a ser exibido no toastr.
   * @returns {void}
   */
  info (msg, title) {
    performToast({ type: 'info', msg, title })
  },

  /**
   * @function success
   * @description Exibe um toastr do tipo success.
   * @param {string} msg A mensagem a ser exibida no toastr.
   * @param {string} title O título a ser exibido no toastr.
   * @returns {void}
   */
  success (msg, title) {
    performToast({ type: 'success', msg, title })
  }
}

const UIHelper = {
  toast,
  loading
}

export default UIHelper
