<template>
  <div class="animated fadeIn card p-4 mt-0 pb-0 mb-0 history">
    <div class="form-group row">
      <div class="col-md-12 text-left">
        <h6 class="text-third text-bold"><i class="fas fa-stream"></i> HISTÓRICO</h6>
      </div>
    </div>
    <div class="form-group row flex-grow-1">
      <div class="col-md-12 text-left">
        <div class="time-scroll">
          <div
            v-if="data === null || data.length === 0"
            class="d-flex h-100 align-items-center justify-content-center"
          >
            <span>Nenhum registro foi encontrado.</span>
          </div>
          <div
            v-else
            v-for="action in data"
            :key="action.id"
            class="timeline"
          >
            <div class="w-100 d-flex">
              <div class="timeline-type">
                <b-avatar v-if="action.type === 0" variant="warning">
                  <i class="fas fa-exchange-alt" />
                </b-avatar>
                <b-avatar v-else-if="action.type === 1 && action.stringValue === 'Telefone'" variant="primary">
                  <i class="fas fa-phone-volume" />
                </b-avatar>
                <b-avatar v-else-if="action.type === 1 && (action.stringValue === 'E-mail' || action.stringValue === 'Email')" variant="primary">
                  <i class="fas fa-envelope-open-text"></i>
                </b-avatar>
                <b-avatar v-else-if="action.type === 1 && action.stringValue === 'SMS'" variant="primary">
                  <i class="fas fa-sms"></i>
                </b-avatar>
                <b-avatar v-else-if="action.type === 1 && action.stringValue === 'Messenger'" variant="primary">
                  <i class="fab fa-facebook-messenger"></i>
                </b-avatar>
                <b-avatar v-else-if="action.type === 1 && action.stringValue === 'WhatsApp'" variant="primary">
                  <i class="fab fa-whatsapp"></i>
                </b-avatar>
                <b-avatar v-else-if="action.type === 1 && action.stringValue === 'Direct Instagram'" variant="primary">
                  <i class="fab fa-instagram"></i>
                </b-avatar>
                <b-avatar v-else-if="action.type === 1 && (action.stringValue === 'Chat online site' || action.stringValue === 'Chat online')" variant="primary">
                  <i class="fas fa-comments"></i>
                </b-avatar>
                <b-avatar v-else-if="action.type === 1" variant="primary">
                  <i class="fas fa-headset" />
                </b-avatar>
                <b-avatar v-else-if="action.type === 2 || action.type === 3 || action.type === 4" variant="success">
                  <i class="fas fa-calendar-alt" />
                </b-avatar>
                <b-avatar v-else-if="action.type === 5" variant="info">
                  <i class="fas fa-briefcase" />
                </b-avatar>
                <b-avatar v-else-if="action.type === 6" variant="success">
                  <i class="fas fa-dollar-sign" />
                </b-avatar>
                <b-avatar v-else-if="action.type === 7" variant="info">
                  <i class="fas fa-user-edit" />
                </b-avatar>
                <b-avatar v-else-if="action.type === 8" variant="info">
                  <i class="fas fa-user-tie" />
                </b-avatar>
                <b-avatar v-else-if="action.type === 9" variant="success">
                  <i class="fas fa-user" />
                </b-avatar>
                <b-avatar v-else-if="action.type === 10" variant="danger">
                  <i class="fas fa-heart-broken"></i>
                </b-avatar>
                <b-avatar v-else-if="action.type === 11" variant="success">
                  <i class="fas fa-sync"></i>
                </b-avatar>
                <b-avatar v-else-if="action.type === 12" variant="success">
                  <i class="fas fa-user-check"></i>
                </b-avatar>
                <b-avatar v-else-if="action.type === 13" variant="info">
                  <i class="fas fa-user-check" />
                </b-avatar>
                <b-avatar v-else-if="action.type === 14" variant="warning">
                  <i class="fas fa-briefcase" />
                </b-avatar>
                <b-avatar v-else-if="action.type === 15" variant="danger">
                  <i class="fas fa-briefcase" />
                </b-avatar>
              </div>
              <div class="d-flex align-items-center">
                <strong>{{moment(action.dateCreated).format('DD/MM/yyyy HH:mm')}}</strong>&nbsp;
                -&nbsp;
                {{action.accountUserNameAction}}&nbsp;
                <strong>
                  {{
                    action.type === 0 ? 'moveu para a coluna'
                    : action.type === 1 ? 'contatou via'
                      : action.type === 2 ? action.dateValue === null ? 'removeu o agendamento' : 'agendou para'
                        : action.type === 3 ? action.dateValue === null ? 'removeu o reagendamento' : 'reagendou para'
                          : action.type === 4 ? action.dateValue === null ? 'removeu o comparecimento' : 'compareceu em'
                            : action.type === 5 ? 'adicinou o item no orçamento.'
                              : action.type === 6 ? 'alterou o valor do produto'
                                : action.type === 7 ? 'alterou os dados do Lead no card de cliente'
                                  : action.type === 8 ? 'alterou o responsável pelo Lead'
                                    : action.type === 9 ? 'cadastrou o Lead em'
                                      : action.type === 10 ? 'deu o Lead como perdido. Motivo: '
                                        : action.type === 11 ? 'reativou o Lead'
                                          : action.type === 12 ? 'deu ganho no Lead'
                                            : action.type === 13 ? 'alterou o avaliador responsável pelo Lead'
                                              : action.type === 14 ? 'alterou o item do orçamento'
                                                : action.type === 15 ? 'excluiu o item do orçamento. '
                                                  : null
                  }}
                </strong>&nbsp;
                {{
                  action.type === 0 || action.type === 1 ? action.stringValue
                  : action.type === 6 ? toCurrency(action.stringValue)
                    : action.type === 2 || action.type === 3 || action.type === 4 || action.type === 9 ? action.dateValue !== null ? moment(action.dateValue).format('DD/MM/yyyy HH:mm') : null
                      : action.type === 10 ? action.stringValue
                        : null
                }}
              </div>
            </div>
            <div v-if="(action.type >= 1)" class="px-4 pb-2">
              <hr class="mb-2 mt-0" v-if="action.description || action.type === 5 || action.type === 15" />
              <span class="text-bold" v-if="action.description || action.type === 5 || action.type === 15">Descrição:</span><br/>
              <span class="text-justify timeline-description" v-if="action.type === 5 || action.type === 15">{{getDataProduct(action.stringValue)}}</span>
              <span class="text-justify timeline-description" v-else-if="action.type === 14">De {{getDataProduct(action.description)}}<br>Para {{getDataProduct(action.stringValue)}}</span>
              <span class="text-justify timeline-description" v-else-if="action.description">{{action.description}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Timeline from './timeline.js'
export default Timeline
</script>
<style scoped>
/* .history {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
} */
.time-scroll {
  overflow-y: auto;
  min-height: 638px;
  max-height: 638px;
}
.timeline {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 8px;
  margin-top: 7px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.11);
}
.timeline-type {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 98px;
  max-width: 98px;
  padding: 10px;
  margin-right: 0px;
  text-align: center;
}
.timeline-history {
  flex-grow: 1;
  font-size: 12px;
  padding: 12px 15px 15px 5px;
  text-align: justify;
  vertical-align: middle;
}
.timeline-date {
  font-size: 11px;
  text-align: center;
  padding-top: 5px;
}
.timeline-user {
  font-size: 11px;
}
.timeline-description {
  width: 100%;
  display: block;
  position: relative;
  background-color: #fafafa;
  padding: 15px;
  border-radius: 7px;
}
</style>