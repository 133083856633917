<template>
  <footer class="app-footer">
    <span class="d-sm-down-none"><a href="javascript:void(0);"><strong>SD</strong></a> | Version {{ version }}</span>
    <span class="float-right">Powered by <a href="https://saludigital.com.br"><strong>Saludigital</strong></a></span>
  </footer>
</template>
<script>
export default {
  name: 'FooterApp',
  data () {
    return {
      version: process.env.VUE_APP_VERSION
    }
  }
}
</script>
