import { mapGetters, mapActions } from 'vuex'
import PermissionModule, { MODULE_NAME } from './index'

const PermisionMixin = {
  namespaced: PermissionModule.namespaced,
  computed: { ...mapGetters(MODULE_NAME, Object.keys(PermissionModule.getters)) },
  methods: { ...mapActions(MODULE_NAME, Object.keys(PermissionModule.actions)) }
}

export default PermisionMixin
