<template>
  <div class="limiter" @keyup.enter="validateAndSignIn">
    <div class="container-login100">
      <div class="wrap-login100">        
        <div class="login100-more" style="background-image: url('/static/img/bg/bg-08.jpg');"></div>
        <!--
        <form autocomplete="off"> will turn off autocomplete for the form in most browsers
        except for username/email/password fields
        -->
        <form class="login100-form validate-form" autocomplete="off" action="javascript:void(0)">
          <!--  fake fields are a workaround for chrome/opera autofill getting the wrong fields -->
          <input id="username" style="display:none" type="text" name="fakeusernameremembered">
          <input id="password2" style="display:none" type="password" name="fakepasswordremembered">

          <img src="/static/img/logotipo/SaluDigital02.png" class="text-text" style="width: 240px;display: block;position: absolute;top: -56px;left: 45px;">                   
          <span class="login100-form-title p-t-110 p-b-4" style="color: #236192;">
            Acessar Plataforma
          </span>
          <div class="text-center p-b-43" style="color: #236192;">
            <small>Digite as informações abaixo para acessar</small>
          </div>
          <div class="wrap-input100 validate-input">
            <!--
              <input autocomplete="nope"> turns off autocomplete on many other browsers that don't respect
              the form's "off", but not for "password" inputs.
            -->
            <b-form-input
              type="text"
              class="input100"
              :placeholder="errors.has('email') ? errors.first('email') : $lang.Login.Email"
              v-model="credentials.email"
              data-vv-name="email"
              v-validate="'required|email'"
              aria-describedby="emailErrorFeedback"
              :state="errors.has('email') ? false : getValidationState('email')"
              @blur.native="getValidationState('email')"
            />
            <span class="focus-input100 pt-2"><i class="icon-user pl-3" /> {{$lang.Login.Email}}</span>
          </div>
          <div class="wrap-input100 validate-input">  
            <!--
            <input type="password" autocomplete="new-password" will turn it off for passwords everywhere
            -->         
            <b-form-input
              class="input100"
              type="password"
              autocomplete="new-password"
              :placeholder="errors.has('password') ? errors.first('password') : $lang.Login.Password"
              v-model="credentials.password"
              data-vv-name="password"
              data-vv-as="senha"
              v-validate="'required'"
              aria-describedby="passwordErrorFeedback"
              :state="errors.has('password') ? false : getValidationState('password')"
              @blur.native="getValidationState('password')"
            />
            <span class="focus-input100 pt-2"><i class="icon-lock pl-3" /> {{$lang.Login.Password}}</span>
          </div>
          <div class="flex-sb-m w-full p-t-3 p-b-32">
            <div v-if="false" class="contact100-form-checkbox">
              <input class="input-checkbox100" id="ckb1" type="checkbox" name="remember-me">
              <label class="label-checkbox100" for="ckb1">
                Lembrar-me
              </label>
            </div>
            <div class="text-right w-100">
              <a href="javascript:void(0)" class="txt1" style="color: #236192;" @click="onLostPassowrd">
                {{ $lang.Login.LostPassowrd }}
              </a>
            </div>
          </div>
          <div class="container-login100-form-btn">
            <button class="login100-form-btn" style="background-color: #236192;border: solid 1px #236192" @click="validateAndSignIn">
              {{ $lang.Login.ButtonEnter }}
            </button>
          </div>
          <div class="text-center p-t-46 p-b-20" style="color: #236192;">
            <span class="txt2">
              Powered by Saludigital &nbsp;-&nbsp; {{ $lang.Login.LabelVersion }} {{ version }}
            </span>
          </div>
          <div class="login100-form-social flex-c-m d-none">
            <a href="#" class="login100-form-social-item flex-c-m bg1 m-r-5">
              <i class="fa fa-facebook-f" aria-hidden="true"></i>
            </a>
            <a href="#" class="login100-form-social-item flex-c-m bg2 m-r-5">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import SignInMixin from '@/store/module/signIn/mixin'
import { globalActions as authActions } from '@/store/module/auth/actionTypes'
import { toast } from '@/shared/utils/UIHelper'

export default {
  name: 'UserPassword',
  data () {
    return {
      version: process.env.VUE_APP_VERSION
    }
  },
  mixins: [
    SignInMixin
  ],

  methods: {
    ...mapActions({
      signIn: authActions.SIGN_IN
    }),
    validateAndSignIn () {
      this.$validator.validateAll()
        .then((valid) => {
          if (valid) {
            this.signIn(this.credentials)
              .then((success) => {
                if (success) {
                  this.$router.push({ name: 'StartPage' })
                }
              })
              .catch((e) => {
                if (e.message === 'Not authorized') {
                  toast.error(this.$lang.Login.InvalidCredentials)
                } else {
                  toast.error(this.$lang.Login.ServiceOff)
                }
              })
          }
        })
    },
    getValidationState (fieldName) {
      const field = this.$validator.fields.find({ name: fieldName })

      if (!field) return null

      const isDirty = field.flags.dirty
      const isValid = !this.errors.has(fieldName)
      return isDirty ? isValid : null
    },
    onLostPassowrd () {
      this.$router.push({ name: 'LostPassword' })
    }
  }
}
</script>
