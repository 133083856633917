import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'

import GroupRepository from '@/shared/http/repositories/access/group'
import GroupModel from '@/shared/models/access/groupmodel'
import GroupForms from './groups-form'

export default {
  name: 'group',
  mixins: [ActionsForm],
  data () {
    return {
      data: GroupModel,
      form: GroupForms
    }
  },
  methods: {
    onLoad () {
      loading.push()
      GroupRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then((response) => {
          this.onResultGrid(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onCreate () {
      loading.push()
      GroupRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      GroupRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      GroupRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}
