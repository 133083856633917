<template>
  <ul class="nav">
    <li class="nav-item" >
      <div class="nav-link" @click="setAction" exact><i class="ico icon-arrow-left"></i>  {{$lang.Menu.Common.MenuDefault}}</div>
    </li>
    <li class="nav-title">
      {{$lang.Menu.Section.Settings}}
    </li>
    <router-link v-if="hasRole(this.roles.MENU_ACCESS)" tag="li" class="nav-item nav-dropdown" :to="{ path: '/acessos' }" disabled>
      <div class="nav-link nav-dropdown-toggle" @click="handleClick"><i class="fas fa-user-shield"></i> {{$lang.Menu.Items.Access.Access}}</div>
      <ul class="nav-dropdown-items">
        <li class="nav-item" v-if="hasRole(this.roles.RD_ADM_ACCOUNTS) || hasRole(this.roles.RW_ADM_ACCOUNTS)">
          <router-link :to="{ name: 'Contas' }" class="nav-link" exact><i class="fa fa-caret-right" /> {{$lang.Menu.Items.Access.Accounts}}</router-link>
        </li>
        <li class="nav-item" v-if="hasRole(this.roles.RD_ADM_GROUPS) || hasRole(this.roles.RW_ADM_GROUPS)">
          <router-link :to="{ name: 'Grupos' }" class="nav-link" exact><i class="fa fa-caret-right" /> {{$lang.Menu.Items.Access.Groups}}</router-link>
        </li>
        <li class="nav-item" v-if="hasRole(this.roles.RD_ADM_PERMISSIONS) || hasRole(this.roles.RW_ADM_PERMISSIONS)">
          <router-link :to="{ name: 'Permissoes' }" class="nav-link" exact><i class="fa fa-caret-right" /> {{$lang.Menu.Items.Access.Permissions}}</router-link>
        </li>
      </ul>
    </router-link>

    <li class="divider" />
    <li class="nav-title">
      CADASTROS
    </li>
   
    <router-link tag="li" class="nav-item nav-dropdown" :to="{ path: '/configuracoes/institucional' }" disabled v-if="hasRole(this.roles.RD_CFG_COMPANY) || hasRole(this.roles.RW_CFG_COMPANY) || hasRole(this.roles.RD_CFG_BRANCH) || hasRole(this.roles.RW_CFG_BRANCH)">
      <div class="nav-link nav-dropdown-toggle" @click="handleClick"><i class="fas fa-briefcase"></i> {{$lang.Menu.Items.Configuration.Institutional}}</div>
      <ul class="nav-dropdown-items">
        <li class="nav-item" v-if="hasRole(this.roles.RD_CFG_COMPANY) || hasRole(this.roles.RW_CFG_COMPANY)">
          <router-link :to="{ name: 'Empresa' }" class="nav-link" exact><i class="fa fa-caret-right" /> {{$lang.Menu.Items.Configuration.Company}}</router-link>
        </li>
        <li class="nav-item" v-if="hasRole(this.roles.RD_CFG_BRANCH) || hasRole(this.roles.RW_CFG_BRANCH)">
          <router-link :to="{ name: 'Filial' }" class="nav-link" exact><i class="fa fa-caret-right" /> {{$lang.Menu.Items.Configuration.Branch}}</router-link>
        </li>
      </ul>
    </router-link>

    <router-link tag="li" class="nav-item nav-dropdown" :to="{ path: '/configuracoes/produtos' }" disabled v-if="hasRole(this.roles.RD_CFG_BUSINEESSTYPE) || hasRole(this.roles.RW_CFG_BUSINEESSTYPE) || hasRole(this.roles.RD_CFG_CATEGORY) || hasRole(this.roles.RW_CFG_CATEGORY) || hasRole(this.roles.RD_CFG_PRODUCT) || hasRole(this.roles.RW_CFG_PRODUCT)">
      <div class="nav-link nav-dropdown-toggle" @click="handleClick"><i class="fas fa-gift"></i> {{$lang.Menu.Items.Configuration.Products}}</div>
      <ul class="nav-dropdown-items">
        <li class="nav-item" v-if="hasRole(this.roles.RD_CFG_BUSINEESSTYPE) || hasRole(this.roles.RW_CFG_BUSINEESSTYPE)">
          <router-link :to="{ name: 'Segmento' }" class="nav-link"><i class="fa fa-caret-right"></i> {{$lang.Menu.Items.Configuration.BusinessType}}</router-link>
        </li>
        <li class="nav-item" v-if="hasRole(this.roles.RD_CFG_CATEGORY) || hasRole(this.roles.RW_CFG_CATEGORY)">
          <router-link :to="{ name: 'Categoria' }" class="nav-link"><i class="fa fa-caret-right"></i> {{$lang.Menu.Items.Configuration.Category}}</router-link>
        </li>
        <li class="nav-item" v-if="hasRole(this.roles.RD_CFG_PRODUCT) || hasRole(this.roles.RW_CFG_PRODUCT)">
          <router-link :to="{ name: 'Produto' }" class="nav-link"><i class="fa fa-caret-right"></i> {{$lang.Menu.Items.Configuration.Product}}</router-link>
        </li>
      </ul>
    </router-link>

    <router-link tag="li" class="nav-item nav-dropdown" :to="{ path: '/configuracoes/crm' }" disabled v-if="hasRole(this.roles.RW_CFG_CONTACTTYPE) || hasRole(this.roles.RW_CFG_LEADORIGIN) || hasRole(this.roles.RW_CFG_REASONLOSS)">
      <div class="nav-link nav-dropdown-toggle" @click="handleClick"><i class="fas fa-address-card"></i> {{$lang.Menu.Items.Configuration.CRM}}</div>
      <ul class="nav-dropdown-items">
        <li class="nav-item" v-if="hasRole(this.roles.RW_CFG_CONTACTTYPE)">
          <router-link :to="{ name: 'FormaContato' }" class="nav-link" exact><i class="fa fa-caret-right" /> {{$lang.Menu.Items.Configuration.Contacttype}}</router-link>
        </li>
        <li class="nav-item" v-if="hasRole(this.roles.RW_CFG_LEADORIGIN)">
          <router-link :to="{ name: 'Origemlead' }" class="nav-link" exact><i class="fa fa-caret-right" /> {{$lang.Menu.Items.Configuration.LeadOrigin}}</router-link>
        </li>
        <li class="nav-item" v-if="hasRole(this.roles.RW_CFG_REASONLOSS)">
          <router-link :to="{ name: 'MotivoPerda' }" class="nav-link" exact><i class="fa fa-caret-right" /> {{$lang.Menu.Items.Configuration.ReasonLoss}}</router-link>
        </li>
      </ul>
    </router-link>
  </ul>
</template>
<script>
import Roles from '@/shared/utils/roles.js'
import PermissionMixin from '@/store/module/permission/mixin'
export default {
  name: 'SidebarSettings',
  mixins: [ PermissionMixin ],
  data () {
    return {
      roles: Roles
    }
  },
  methods: {
    handleClick (e) {
      e.preventDefault()
      e.target.parentElement.classList.toggle('open')
    },
    setAction () {
      this.$emit('Action')
    },
    hasRole (role) {
      var result = false
      if (this.permissions) {
        for (var i in this.permissions) {
          if (this.permissions[i].role === role) {
            result = true
          }
        }
      }
      return result
    }
  }
}
</script>
<style lang="css">
  .nav-link {
    cursor:pointer;
  }
</style>
