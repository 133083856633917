import APIClient from '@/shared/http/clients/api'

const resourceName = 'board'
const APIAccess = process.env.VUE_APP_ONBOARDING

const GetFavorite = (config = {}) => APIClient.get(`${APIAccess}/${resourceName}/favorite`, config)
const GetFavoritePaging = (limit, index, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/favorite?limit=${limit}&index=${index}`, config)
const SaveConfiguration = (data = {}, config = {}) => APIClient.put(`${APIAccess}/${resourceName}/configuration/save`, data, config)
const MoveCard = (data = {}, config = {}) => APIClient.put(`${APIAccess}/${resourceName}/move/card`, data, config)
const SearchFavoritePaging = (limit, index, filters, config = {}) => APIClient.post(`${APIAccess}/${resourceName}/favorite/search?limit=${limit}&index=${index}`, filters, config)

const BoardRepository = {
  GetFavorite,
  GetFavoritePaging,
  SaveConfiguration,
  MoveCard,
  SearchFavoritePaging
}

export default BoardRepository
