<template>
  <div>
    <b-modal
      ref="modal-datepicker"
      size="md"
      id="modal-datepicker"
      title="Preencher"
      centered
    >
      <template #modal-header>
        <div class="w-100">
          <h5 class="text-bold"><i class="fas fa-form"></i> Informar data</h5>
        </div>
      </template>
      <div class="row">
        <div class="col-md-6">
          <label><small class="text-danger">*</small> Data:</label>
          <b-form-datepicker
            v-model="local.date"
            class="mb-2"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            :state="isValid"
          />
        </div>
        <div class="col-md-3">
          <label><small class="text-danger">*</small> Hora Inicial:</label>
          <b-form-input v-model="local.initialHour" placeholder="00:00" v-mask="['##:##']" :state="isValid"></b-form-input>
        </div>
        <div class="col-md-3">
          <label><small class="text-danger">*</small> Hora Final:</label>
          <b-form-input v-model="local.endHour" placeholder="00:00" v-mask="['##:##']" :state="isValid"></b-form-input>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100 text-right">
          <button
            type="button"
            class="btn btn-danger btn-sm mr-2"        
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonSave"
            @click="onHide"
          >
            <i class="fa fa-times-circle" /> {{ $lang.Common.Buttons.Close }}
          </button>
          <button
            type="button"
            class="btn btn-third btn-sm"        
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonSave"
            @click="onSave"
          >
            <i class="fa fa-check-circle" /> {{ $lang.Common.Buttons.Save }}
          </button>
        </div>
      </template>
    </b-modal>
    <div class="pointer" @click="show">
      <i class="fas fa-calendar mr-1"/>
      <span v-if="!date || !initialHour || !endHour" class="text-bold">Selecionar</span>
      <span v-else class="text-bold">{{`${moment(date).format('DD/MM/yyyy')} ${initialHour} - ${endHour}`}}</span>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  name: 'SaluDigitalDatePicker',
  props: {
    date: {
      type: String,
      default: ''
    },
    initialHour: {
      type: String,
      default: ''
    },
    endHour: {
      type: String,
      default: ''
    }
  },
  directives: {mask},
  data () {
    return {
      showModal: false,
      isValid: null,
      local: {
        date: null,
        initialHour: null,
        endHour: null
      }
    }
  },
  methods: {
    show () {
      this.showModal = true

      this.local.date = this.date
      this.local.initialHour = '' // this.initialHour
      this.local.endHour = '' // this.endHour ? this.endHour : ''

      this.$nextTick(() => {
        this.$refs['modal-datepicker'].show()
      })
    },
    hide () {
      this.$refs['modal-datepicker'].hide()
      this.showModal = false
      this.isValid = null
      this.$emit('closed', this.date)
    },
    onClear () {
      this.local.date = this.date
      this.local.initialHour = this.initialHour
      this.local.endHour = this.endHour
    },
    onSave () {
      if (this.local.date && this.local.initialHour && this.local.endHour) {
        this.date = this.local.date
        this.initialHour = this.local.initialHour
        this.endHour = this.local.endHour

        this.hide()
        this.$nextTick(() => {
          this.$emit('save', {
            date: this.date,
            initialHour: this.initialHour,
            endHour: this.endHour
          })
        })
      } else {
        this.setInvalid()
      }
    },
    setInvalid () {
      this.isValid = false
    },
    onHide () {
      this.onClear()
      this.hide()
    }
  }
}
</script>