<template>
  <div class="animated fadeIn">
    <div class="form-group row">
      <div class="col-md-12">
        <label> Empresa:</label>
        <div class="input-group mb-3">
          <b-form-select
            v-model="form.companyID.value"
            :options="form.companyID.options"
            :required="form.companyID.required"
            :state="form.companyID.state"
          >
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Filial <small>(Unidade)</small>:</label>
        <div class="input-group mb-3">
          <b-form-select
            v-model="form.branchID.value"
            :options="form.branchID.options"
            :required="form.branchID.required"
            :state="form.branchID.state"
          >
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Origem:</label>
        <div class="input-group mb-3">
          <b-form-select v-model="form.leadOriginID.value" :options="form.leadOriginID.options">
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12" v-if="false">
        <label> Entrada:</label>
        <div class="mb-3">
          <b-form-datepicker
            v-model="dateTimeStart.date"
            placeholder="Data Inicial"
            class="mb-2"
            style="z-index: 9999;"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
          <b-form-datepicker
            v-model="dateTimeEnd.date"
            placeholder="Data Final"
            class="mb-2"
            style="z-index: 9999;"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </div>
      </div>
      <div class="col-md-12 pt-2">
        <button type="button" class="btn btn-third w-100" v-on:click="onFilter">Filtrar</button>
      </div>

      <div class="col-md-12 pt-2">
        <button type="button" class="btn btn-default w-100" v-on:click="onClearFilters">Limpar Filtros</button>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardSearch from './dashboardsearch.js'
export default DashboardSearch
</script>
