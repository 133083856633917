import { mapGetters, mapActions } from 'vuex'
import GroupModule, { MODULE_NAME } from './index'

const GroupMixin = {
  namespaced: GroupModule.namespaced,
  computed: { ...mapGetters(MODULE_NAME, Object.keys(GroupModule.getters)) },
  methods: { ...mapActions(MODULE_NAME, Object.keys(GroupModule.actions)) }
}

export default GroupMixin
