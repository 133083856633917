<template>
  <div class="animated fadeIn">
    <Alerts ref="alert" />
    <Forms
      ref="frmGroup"
      :title="this.$lang.MyAccount.TitleForm"
      :show-help="true"
      :show-clear-button="true"
      :show-save-button="true"
      @Clear="onClear"
      @Save="onSave"
    >
      <div class="form-group row">
        <div class="col-md-12 pt-1">
          <h5 class="text-third">
            <i class="fa fa-user" /> {{ $lang.MyAccount.MyProfile }}
          </h5>
        </div>
      </div>
      <div class="form-group row pb-3">
        <div class="col-md-4">
          <label for="txtName"><small class="text-danger">*</small> {{ $lang.MyAccount.LabelName }}:</label>
          <div class="input-group">
            <b-form-input
              type="text"
              :placeholder="$lang.MyAccount.PlaceholderEnterName"
              v-model="form.name.value"
              :size="form.name.maxlength"
              :required="form.name.required"
              :state="form.name.state"
            />
          </div>
        </div>
        <div class="col-md-4">
          <label for="txtEmail"><small class="text-danger">*</small> {{ $lang.MyAccount.LabelEmail }}:</label>
          <div class="input-group">
            <b-form-input
              type="email"
              :placeholder="$lang.MyAccount.PlaceholderEnterEmail"
              v-model="form.email.value"
              :size="form.email.maxlength"
              :required="form.email.required"
              :state="form.email.state"
            />
          </div>
        </div>
        <div class="col-md-4">
          <label for="txtPhone"><small
            v-show="form.cellPhone.required"
            class="text-danger"
          >*</small> {{ $lang.MyAccount.LabelMobilePhone }}:</label>
          <div class="input-group">
            <b-form-input
              type="text"
              name="txtPhone"
              :placeholder="$lang.MyAccount.PlaceholderMobilePhone"
              v-model="form.cellPhone.value"
              :size="form.cellPhone.maxlength"
              :required="form.cellPhone.required"
              :state="form.cellPhone.state"
              v-mask="['(##) # ####-####']"
            />
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12 pt-1">
          <h5 class="text-third">
            <i class="fa fa-lock" /> {{ $lang.MyAccount.AccessPasswords }}
          </h5>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-4">
          <label for="txtPassword"><small class="text-info">*</small> {{ $lang.MyAccount.LabelOldPassword }}:</label>
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-lock" /></span>
            <b-form-input
              type="password"
              id="txtOldPassword"
              name="txtOldPassword"
              :placeholder="$lang.MyAccount.PlaceholderOldPassword"
              v-model="form.oldpassword.value"
              :size="form.oldpassword.maxlength"
              :required="form.oldpassword.required"
              :state="form.oldpassword.state"
            />
          </div>
        </div>
        <div class="col-md-4">
          <label for="txtPassword"><small class="text-info">*</small> {{ $lang.MyAccount.LabelPassword }}:</label>
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-lock" /></span>
            <b-form-input
              type="password"
              id="txtPassword"
              name="txtPassword"
              :placeholder="$lang.MyAccount.PlaceholderEnterPassword"
              v-model="form.password.value"
              :size="form.password.maxlength"
              :required="form.password.required"
              :state="form.password.state"
            />
          </div>
        </div>
        <div class="col-md-4">
          <label for="txtConfirmation"><small class="text-info">*</small> {{ $lang.MyAccount.LabelConfirmation }}:</label>
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-lock" /></span>
            <b-form-input
              type="password"
              id="txtConfirmation"
              name="txtConfirmation"
              :placeholder="$lang.MyAccount.PlaceholderConfirmationPassword"
              v-model="form.confirmation.value"
              :size="form.confirmation.maxlength"
              :required="form.confirmation.required"
              :state="form.confirmation.state"
            />
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <small class="text-third"><i class="fa fa-info-circle" /> {{ $lang.MyAccount.PasswordOnlyChange }}</small>
        </div>
      </div>
    </Forms>
  </div>
</template>
<script>
import Profile from './profile.js'

export default Profile
</script>
