import APIClient from '@/shared/http/clients/api'

const resourceName = 'state'
const APICore = process.env.VUE_APP_Core

const GetAll = (countryID, data = {}, config = {}) => APIClient.get(`${APICore}/${resourceName}/country/${countryID}`, data, config)
const GetAllPaging = (countryID, limit, index, config = {}) => APIClient.get(`${APICore}/${resourceName}/country/${countryID}?limit=${limit}&index=${index}`, config)
const GetAllCountryID = (countryID, data = {}, config = {}) => APIClient.get(`${APICore}/${resourceName}/country/${countryID}`, data, config)
const GetById = (id, config = {}) => APIClient.get(`${APICore}/${resourceName}/${id}`, config)

const StateRepository = {
  GetAll,
  GetAllPaging,
  GetAllCountryID,
  GetById
}

export default StateRepository
