export default {
  id: null,
  boardColumnID: null,
  companyID: null,
  branchID: null,
  name: null,
  contactName: null,
  contactPhone: null,
  contactCellPhone: null,
  email: null,
  phone: null,
  cellPhone: null,
  leadOriginID: null,
  status: null
}
