export default {
  managerID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  responsibleAppraiserID: {
    value: null,
    options: [],
    required: false,
    state: null
  }
}
