<template>
  <div class="animated fadeIn">
    <div class="card p-4 mb-1">
      <div class="form-group row">
        <div class="col-md-12">
          <label for="txtName"><small class="text-danger" v-if="opportunityForm.name.required">*</small> Cliente:</label>
          <div class="input-group">
            <span class="input-group-addon"><i class="fas fa-user"></i></span>
            <b-form-input type="text"
                          id="txtName"
                          name="txtName"
                          :placeholder="'Digite o nome do Clente (Paciente)'"
                          v-model="opportunityForm.name.value"
                          :size="opportunityForm.name.maxlength"
                          :required="opportunityForm.name.required"
                          :state="opportunityForm.name.state"
            >
            </b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-6">
          <label for="txtPhone"> Telefone:</label>
          <div class="input-group">
            <span class="input-group-addon" v-b-tooltip.hover :title="'Clique para ligar'" @click="onCallPhone(opportunityForm.phone.value)" ><i class="fas fa-phone-alt text-bold" style="color: #017bdc;"></i></span>
            <b-form-input type="text"
                          id="txtPhone"
                          name="txtPhone"
                          :placeholder="'(99) 9999-9999'"
                          v-model="opportunityForm.phone.value"
                          :size="opportunityForm.phone.maxlength"
                          :required="opportunityForm.phone.required"
                          :state="opportunityForm.phone.state"
                          v-mask="['(##) ####-####']"
            ></b-form-input>
          </div>
        </div>
        <div class="col-md-6">
          <label for="txtCellPhone"> Celular:</label>
          <div class="input-group">
            <span class="input-group-addon cursor-pointer" v-b-tooltip.hover :title="'Clique para ligar'" @click="onCallCellPhone(opportunityForm.cellPhone.value)"><i class="fas fa-mobile-alt text-bold" style="color: #017bdc;"></i></span>
            <b-form-input type="text"
                          id="txtCellPhone"
                          name="txtCellPhone"
                          :placeholder="'(99) 9 9999-9999'"
                          v-model="opportunityForm.cellPhone.value"
                          :size="opportunityForm.cellPhone.maxlength"
                          :required="opportunityForm.cellPhone.required"
                          :state="opportunityForm.cellPhone.state"
                          v-mask="['(##) # ####-####']"
            ></b-form-input>
            <span class="input-group-addon cursor-pointer" v-b-tooltip.hover :title="'Clique para falar via Whatsapp'" @click="onOpenWhatsapp(opportunityForm.cellPhone.value)"><i class="fab fa-whatsapp text-bold" style="color: #128c7e;"></i></span>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-12">
          <label for="txtEmail"> Email:</label>
          <div class="input-group">
            <span class="input-group-addon"><i class="fas fa-envelope"></i></span>
            <b-form-input type="text"
                          id="txtEmail"
                          name="txtEmail"
                          :placeholder="'Digite o E-mail'"
                          v-model="opportunityForm.email.value"
                          :size="opportunityForm.email.maxlength"
                          :required="opportunityForm.email.required"
                          :state="opportunityForm.email.state"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-12">
          <label for="txtContact"> Responsável:</label>
          <div class="input-group">
            <span class="input-group-addon"><i class="fas fa-user-friends"></i></span>
            <b-form-input type="text"
                          id="txtContact"
                          name="txtContact"
                          :placeholder="'Digite o nome do Responsável (Pai, Mãe, etc.)'"
                          v-model="opportunityForm.contactName.value"
                          :size="opportunityForm.contactName.maxlength"
                          :required="opportunityForm.contactName.required"
                          :state="opportunityForm.contactName.state"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-6">
          <label> Telefone: <small class="text-muted">(Responsável)</small></label>
          <div class="input-group">
            <span class="input-group-addon" v-b-tooltip.hover :title="'Clique para ligar'" @click="onCallPhone(opportunityForm.contactPhone.value)" ><i class="fas fa-phone-alt text-bold" style="color: #017bdc;"></i></span>
            <b-form-input type="text"
                          :placeholder="'(99) 9999-9999'"
                          v-model="opportunityForm.contactPhone.value"
                          :size="opportunityForm.contactPhone.maxlength"
                          :required="opportunityForm.contactPhone.required"
                          :state="opportunityForm.contactPhone.state"
                          v-mask="['(##) ####-####']"
            ></b-form-input>
          </div>
        </div>
        <div class="col-md-6">
          <label> Celular: <small class="text-muted">(Responsável)</small></label>
          <div class="input-group">
            <span class="input-group-addon cursor-pointer" v-b-tooltip.hover :title="'Clique para ligar'" @click="onCallCellPhone(opportunityForm.contactCellPhone.value)"><i class="fas fa-mobile-alt text-bold" style="color: #017bdc;"></i></span>
            <b-form-input type="text"
                          :placeholder="'(99) 9 9999-9999'"
                          v-model="opportunityForm.contactCellPhone.value"
                          :size="opportunityForm.contactCellPhone.maxlength"
                          :required="opportunityForm.contactCellPhone.required"
                          :state="opportunityForm.contactCellPhone.state"
                          v-mask="['(##) # ####-####']"
            ></b-form-input>
            <span class="input-group-addon cursor-pointer" v-b-tooltip.hover :title="'Clique para falar via Whatsapp'" @click="onOpenWhatsapp(opportunityForm.contactCellPhone.value)"><i class="fab fa-whatsapp text-bold" style="color: #128c7e;"></i></span>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-12">
          <label for="txtGroup"><small class="text-danger" v-if="opportunityForm.leadOriginID.required">*</small> Origem:</label>
          <div class="input-group">
            <span class="input-group-addon"><i class="fas fa-bullhorn"></i></span>
            <b-form-select v-model="opportunityForm.leadOriginID.value" :options="opportunityForm.leadOriginID.options">
              <template slot="first">
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
            <div class="input-group-addon pointer" v-b-tooltip.hover title="Clique para adicionar um nova opção" @click="showOriginLeadModal"><i class="fas fa-plus"></i></div>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-12">
          <label for="txtGroup"><small class="text-danger" v-if="opportunityForm.description.required">*</small> Observações:</label>
          <div class="input-group">
            <b-form-textarea id="txtDescription"
                             name="txtDescription"
                             v-model="opportunityForm.description.value"
                             :placeholder="'Digite uma observação sobre o cliente se necessário'"
                             :rows="opportunityForm.description.rows"
                             :size="opportunityForm.description.maxlength"
                             :noResize="opportunityForm.description.noResize"
            >
            </b-form-textarea>
          </div>
        </div>
      </div>
      <div class="form-group row pt-3" v-if="hasRole(this.roles.RW_CRM_ADDEDPRODUCT)">
        <div class="col-md-12">
          <b-button class="btn btn-sm btn-third" size="sm" @click="onSaveOpportunity"><i class="fas fa-plus-circle"></i>&nbsp; Gravar</b-button>
        </div>
      </div>
    </div>
    <OriginLead
      ref="originlead"
      v-on:Updated="onUpdateOriginLead"
      modalID="originLeadModal"
      isModal
    />
  </div>
</template>
<script>
import ClienteDetails from './clientdetails.js'
export default ClienteDetails
</script>