import Vue from 'vue'
import Router from 'vue-router'
import { JWTService } from '@/shared/services/jwt'

// Containers
import PublicBaseLayout from '@/containers/PublicBaseLayout'
import PrivateBaseLayout from '@/containers/PrivateBaseLayout'

// Public Views
import SupportAuth from '@/views/public/support/SupportAuth.vue'
import Authentication from '@/views/public/auth/Authentication.vue'
import AuthUserPassword from '@/views/public/auth/UserPassword.vue'
import LostPassword from '@/views/public/password/lost/LostPassword.vue'
import ChangePassword from '@/views/public/password/change/ChangePassword.vue'

// Privates Views
import StartPage from '@/views/private/StartPage'
import AdmAccount from '@/views/private/access/account/account.vue'
import AdmGroups from '@/views/private/access/groups/groups.vue'
import AdmPermission from '@/views/private/access/permission/permission.vue'
import MyAccount from '@/views/private/myaccount/profile/profile.vue'
import Kanban from '@/views/private/crm/kanban.vue'
import KanbanDetails from '@/views/private/crm/details/kanbandetails.vue'
import Dashboard from '@/views/private/dashboard/dashboard.vue'
import Company from '@/views/private/settings/company/company.vue'
import Branch from '@/views/private/settings/branch/branch.vue'
import BusinessType from '@/views/private/settings/businesstype/businesstype.vue'
import Category from '@/views/private/settings/category/category.vue'
import Product from '@/views/private/settings/product/product.vue'
import ContactType from '@/views/private/settings/contacttype/contacttype.vue'
import OriginLead from '@/views/private/settings/originlead/originlead.vue'
import ReasonLoss from '@/views/private/settings/reasonloss/reasonloss.vue'
import Contact from '@/views/private/contact/contact.vue'
import Notification from '@/views/private/notification/notification.vue'

Vue.use(Router)

/**
 * @function assertLoggedIn
 * @description Middleware para ser executado antes da troca de rota para garantir
 * que os usuários estão autenticados para ver esta rota.
 * @param {Object} to A rota de onde o usuário está vindo.
 * @param {Object} from A rota que o usuário deseja acessar.
 * @param {Object} next A rota que será renderizada.
 */
const assertLoggedIn = (to, from, next) => {
  if (!JWTService.GetToken()) {
    next({ name: 'SignIn' })
    return
  }
  next()
}
/**
 * @function assertNotLoggedIn
 * @description Middleware para ser executado antes da troca de rota para garantir
 * que os usuários não estão autenticados para ver esta rota.
 * @param {Object} to A rota de onde o usuário está vindo.
 * @param {Object} from A rota que o usuário deseja acessar.
 * @param {Object} next A rota que será renderizada.
 */
const assertNotLoggedIn = (to, from, next) => {
  if (JWTService.GetToken()) {
    next({ name: 'StartPage' })
    return
  }
  next()
}

/**
 * @function route
 * @description Cria um objeto de rota.
 * @param {string} path O caminho da rota.
 * @param {string} name O nome da rota.
 * @param {Object} component O componente a ser renderizado na rota.
 * @param {Array} children As children routes desta rota.
 * @returns {Object} Um objeto de rota do vue-router.
 */
const route = (path, name, component, children, beforeEnter, meta) => ({
  path,
  name,
  component,
  children,
  beforeEnter,
  meta
})

/**
 * @function children
 * @description Transforma um objetos com rotas em array.
 * @param {...} routes As rotas para serem retornadas em forma de array.
 * @returns {Array} O array com as rotas.
 */
const children = (...routes) => routes

/**
 * @property adminRoutes
 * @description As rotas privadas da aplicação.
 * @type {Object}
 */
const adminRoutes = {
  path: '/',
  redirect: '/pagina-inicial',
  component: PrivateBaseLayout,
  beforeEnter: assertLoggedIn,
  children: children(
    route('/pagina-inicial', 'StartPage', StartPage),
    route('/acessos/contas', 'Contas', AdmAccount),
    route('/acessos/grupos', 'Grupos', AdmGroups),
    route('/acessos/permissoes', 'Permissoes', AdmPermission),
    route('/minha-conta', 'MinhaConta', MyAccount),
    route('/crm', 'Kanban', Kanban, null, null, { removeContainer: true }),
    route('/crm/detalhes/:cardID', 'QuadroDetalhes', KanbanDetails, null, null, { removeContainer: true }),
    route('/crm/report', 'QuadroRelatorio', Dashboard, null, null, { removeContainer: true }),
    route('/dashboard', 'Dashboard', Dashboard, null, null, { removeContainer: true }),
    route('/configuracoes/empresa', 'Empresa', Company),
    route('/configuracoes/filial', 'Filial', Branch),
    route('/configuracoes/segmento', 'Segmento', BusinessType),
    route('/configuracoes/categoria', 'Categoria', Category),
    route('/configuracoes/produto', 'Produto', Product),
    route('/configuracoes/forma-contato', 'FormaContato', ContactType),
    route('/configuracoes/origem-lead', 'Origemlead', OriginLead),
    route('/configuracoes/motivo-perda', 'MotivoPerda', ReasonLoss),
    route('/contatos', 'Contatos', Contact),
    route('/notificacoes', 'Notificacao', Notification)
  )
}

/**
 * @property publicRoutes
 * @description As rotas públicas da aplicação. (Não requerem autenticação)
 * @type {Object}
 */

const publicRoutes = {
  path: '/',
  redirect: '/login',
  component: PublicBaseLayout,
  children: children(
    route('/support/auth', 'SupportSignIn', SupportAuth),
    route('/loginOld', 'SignInOld', Authentication, null, assertNotLoggedIn),
    route('/login', 'SignIn', AuthUserPassword, null, assertNotLoggedIn),
    route('/redefinir-senha', 'LostPassword', LostPassword, null, assertNotLoggedIn),
    route('/recovery/password', 'ChangePassword', ChangePassword, null, assertNotLoggedIn)
    //route('/redefinir-senha/token/:token', 'ChangePassword', ChangePassword, null, assertNotLoggedIn)
  )
}

export default new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    publicRoutes,
    adminRoutes,
    {
      path: '*',
      component: PublicBaseLayout
    },
    {
      'method': 'GET',
      'path': '/static/fonts/:file',
      'type': 'passthrough',
      'service': 'static/fonts/:file'
    }
  ]
})
