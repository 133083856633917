<template>
  <div class="row">
    <div class="col-12">
      <div
        v-show="show"
        :class="classNow"
        role="alert"
        @click="show=false"
      >
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <span v-html="alertMessage" />
        <div><small>{{ ShowMessageTimeClosed }}</small></div>
        <div style="border: solid 1px #efefef; background-color: #f5f5f5;border-radius: 5px;">
          <b-progress-bar
            style="height: 7px"
            :value="timerReload"
            :max="7"
            :variant="progresstype"
            animated
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Alerts',
  data () {
    return {
      primary: 'alert alert-primary fade show',
      secondary: 'alert alert-secondary fade show',
      info: 'alert alert-info fade show',
      warning: 'alert alert-warning fade show',
      success: 'alert alert-success fade show',
      danger: 'alert alert-danger fade show',
      light: 'alert alert-light fade show',
      dark: 'alert alert-dark fade show',
      classNow: '',
      alertMessage: '',
      show: false,
      timerReload: 7,
      showtimer: 0,
      progresstype: 'secondary'
    }
  },
  computed: {
    ShowMessageTimeClosed () {
      return this.$lang.Common.Alerts.TimeoutClosed.replace('@TIME@', this.timerReload)
    }
  },
  methods: {
    CountDownReload () {
      this.timerReload--
      if (this.timerReload <= 0) {
        clearInterval(this.showtimer)
        this.Close()
      }
    },
    Close () {
      this.show = false
      this.alertMessage = ''
    },
    ShowAlert (type, message) {
      switch (type) {
      case 'info':
        this.classNow = this.info
        this.progresstype = 'info'
        break
      case 'warning':
        this.classNow = this.warning
        this.progresstype = 'warning'
        break
      case 'success':
        this.classNow = this.success
        this.progresstype = 'success'
        break
      case 'error':
        this.classNow = this.danger
        this.progresstype = 'danger'
        break
      case 'primary':
        this.classNow = this.primary
        this.progresstype = 'primary'
        break
      case 'secondary':
        this.classNow = this.secondary
        this.progresstype = 'secondary'
        break
      case 'light':
        this.classNow = this.light
        this.progresstype = 'secondary'
        break
      case 'dark':
        this.classNow = this.dark
        this.progresstype = 'dark'
        break
      }
      this.alertMessage = message
      this.show = true
      this.timerReload = 7
      this.showtimer = setInterval(this.CountDownReload, 1000)
      window.scrollTo(0, 0)
    },
    ShowSuccess () {
      this.ShowAlert('success', this.$lang.Common.Alerts.Success)
    },
    ShowExperidSession () {
      this.ShowAlert('info', this.$lang.Common.Alerts.ExpiredSession)
    },
    ShowError () {
      this.ShowAlert('error', this.$lang.Common.Alerts.Error)
    }
  }
}
</script>
