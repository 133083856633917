export default {
   companyID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  branchID: {
    value: null,
    options: [],
    clearOption: true,
    required: true,
    state: null
  },
  situation: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  leadOriginID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
   managerID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  responsibleAppraiserID: {
    value: null,
    options: [],
    required: false,
    state: null
  },
  createDateStart: {
    value: null,
    required: false,
    state: null
  },
  createDateEnd: {
    value: null,
    required: false,
    state: null
  }
}
