import PermissionRepository from '@/shared/http/repositories/access/permission'
import { mutationTypes as mutation } from './mutations'

const GET_ALL_PERMISSIONS = 'getAllPermissons'

export const actionTypes = {
  GET_ALL_PERMISSIONS
}

export const actions = {
  async [GET_ALL_PERMISSIONS] ({ commit }, payload) {
    PermissionRepository.GetAll(payload)
      .then((res) => {
        commit(mutation.SET_PERMISSIONS, res.data.data)
      })
  }
}
