const SET_NOTIFICATIONS = 'setNotifications'

export const mutationTypes = {
  SET_NOTIFICATIONS
}

export const mutations = {
  [SET_NOTIFICATIONS] (state, payload) {
    state.notifications = payload
  }
}
