<template>
  <div v-if="isModal">
    <b-modal
      centered
      no-close-on-backdrop
      size="lg"
      class="pl-0"
      :id="modalID"
    >
      <template #modal-header>
        <h5>{{ title }}</h5>
      </template>
      <slot />
      <template #modal-footer>
        <div class="row">
          <div class="col-md-12">
            <button
              type="button"
              class="btn btn-third btn-sm"
              v-show="HasButtonSave"
              @click="SaveForm"
              :disabled="disabledSave"
              v-b-tooltip.hover
              :title="$lang.Common.Tooltip.ButtonSave"
            >
              <i class="fa fa-check-circle" /> {{ $lang.Common.Buttons.Save }}
            </button>&nbsp;
            <button
              type="button"
              class="btn btn-default btn-sm text-secundary"
              @click="CloseModal"
              v-b-tooltip.hover
              :title="$lang.Common.Tooltip.ButtonClose"
            >
              <i class="fa fa-minus-circle" /> {{ $lang.Common.Buttons.Close }}
            </button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
  <div
    v-else
    v-show="showForm"
    @keyup.enter="OnKeyUpEnter"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="card card-bottom-radius-8">
          <div class="card-header text-third">
            <strong><i class="icon-note" />&nbsp; {{ this.title }}</strong>
            <div
              class="card-actions"
              v-show="HasHelp"
            >
              <a
                href="javascript:void(0);"
                target="_blank"
                v-b-tooltip.hover
                :title="$lang.Common.Tooltip.LearnToUse"
                placement="left"
              >
                <i class="icon-question text-third" />
              </a>
            </div>
          </div>
          <div class="card-block">
            <form
              autocomplete="off"
              role="presentation"
            >
              <slot />
            </form>
          </div>
          <div class="card-footer card-bottom-radius-8">
            <div class="row">
              <div class="col-md-6">
                <button
                  type="button"
                  class="btn btn-third btn-sm"
                  v-show="HasButtonSearch"
                  @click="$emit('Search')"
                  v-b-tooltip.hover
                  :title="$lang.Common.Tooltip.ButtonSearch"
                >
                  <i class="fa fa-search" /> {{ $lang.Common.Buttons.Search }}
                </button>&nbsp;
                <button
                  type="button"
                  class="btn btn-third btn-sm"
                  v-if="HasButtonSave"
                  @click="SaveForm"
                  :disabled="disabledSave"
                  v-b-tooltip.hover
                  :title="$lang.Common.Tooltip.ButtonSave"
                >
                  <i class="fa fa-check-circle" /> {{ $lang.Common.Buttons.Save }}
                </button>&nbsp;
                <button
                  type="button"
                  class="btn btn-default btn-sm text-secundary"
                  v-show="HasButtonClear"
                  @click="$emit('Clear')"
                  v-b-tooltip.hover
                  :title="$lang.Common.Tooltip.ButtonClear"
                >
                  <i class="fa fa-minus-circle" /> {{ $lang.Common.Buttons.Clear }}
                </button>
              </div>
              <div class="col-md-6 text-right">
                <button
                  type="button"
                  class="btn btn-fourth btn-sm"
                  v-show="HasButtonExport"
                  @click="$emit('Export')"
                  v-b-tooltip.hover
                  :title="$lang.Common.Tooltip.ButtonExportExcel"
                >
                  <i class="fa fa-download" /> {{ $lang.Common.Buttons.ExportExcel }}
                </button>&nbsp;
                <button
                  type="button"
                  class="btn btn-info btn-sm"
                  v-show="HasButtonRegulation"
                  @click="$emit('NewRegulation')"
                  v-b-tooltip.hover
                  :title="$lang.Common.Tooltip.ButtonNew"
                >
                  <i class="fa fa-pencil" /> {{ $lang.Common.Buttons.NewRegulation }}
                </button>
                <button
                  type="button"
                  class="btn btn-third btn-sm text-secundary"
                  v-show="HasButtonBack"
                  @click="$emit('Back')"
                  v-b-tooltip.hover
                  :title="$lang.Common.Tooltip.ButtonBack"
                >
                  <i class="fa fa-undo-alt" /> {{ $lang.Common.Buttons.Back }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RequiredFieldBlock
      :show-message="showTipFooter"
      :message="messageTipFooter"
      :type="messageTypeTipFooter"
    />
  </div>
</template>
<script>
import RequiredFieldBlock from './RequiredFieldBlock'

export default {
  name: 'Forms',
  data () {
    return {
      showForm: true,
      disabledSave: false
    }
  },
  props: {
    title: {
      type: String,
      required: true,
      default: function () {
        return this.$lang.TitleForms
      }
    },
    showHelp: {
      type: Boolean,
      default: false
    },
    showClearButton: {
      type: Boolean,
      default: false
    },
    showSaveButton: {
      type: Boolean,
      default: false
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    showSearchButton: {
      type: Boolean,
      default: false
    },
    showExportButton: {
      type: Boolean,
      default: false
    },
    showNewRegulationButton: {
      type: Boolean,
      default: false
    },
    showTipFooter: {
      type: Boolean,
      required: false,
      default: true
    },
    messageTipFooter: {
      type: String,
      required: false
    },
    messageTypeTipFooter: {
      type: String,
      required: false
    },
    disabledKeyUpEnter: {
      type: Boolean,
      required: false,
      default: false
    },
    isModal: {
      type: Boolean,
      required: false,
      default: false
    },
    modalID: {
      type: String,
      required: false,
      default: null
    }
  },
  components: {
    RequiredFieldBlock
  },
  computed: {
    HasHelp: function () {
      return this.showHelp
    },
    HasButtonClear: function () {
      return this.showClearButton
    },
    HasButtonSave: function () {
      return this.showSaveButton
    },
    HasButtonBack: function () {
      return this.showBackButton
    },
    HasButtonExport: function () {
      return this.showExportButton
    },
    HasButtonSearch: function () {
      return this.showSearchButton
    },
    HasButtonRegulation: function () {
      return this.showNewRegulationButton
    }
  },
  methods: {
    ClearForm () {
      if (this.HasButtonClear) {
        this.$emit('Clear')
      }
    },
    SaveForm () {
      this.disabledSave = true
      if (this.HasButtonSave) {       
        setTimeout(() => {
          this.$emit('Save')
        }, 300);
        setTimeout(() => {
          this.disabledSave = false
        }, 3000);
        return false
      }
    },
    SearchForm () {
      if (this.HasButtonSearch) {
        this.$emit('Search')
      }
    },
    ExportForm () {
      if (this.HasButtonExport) {
        this.$emit('Export')
      }
    },
    ValidateForm () {
      // console.log('VALIDAR')

      // console.log(this.$children)
      return false
    },
    OnKeyUpEnter () {
      if (this.HasButtonSave && !this.disabledKeyUpEnter) {
        this.$emit('Save')
      }
    },
    CloseModal () {
      this.$emit('Clear')
      this.$bvModal.hide(this.modalID)
    }
  }
}
</script>
