<template>
  <div class="animated fadeIn">
    <b-modal id="modal-budget" ref="modal-budget" title="Cadastrar Lead">
      <template #modal-header>
        <div class="w-100">
          <h5 class="text-bold"><i class="fas fa-user-plus"></i> ORÇAMENTO | PRODUTOS</h5>
        </div>
      </template>
      <b-container fluid>
        <div class="form-group row pt-1">
          <div class="col-md-12">
            <label for="txtGroup"><small class="text-danger" v-if="form.businessTypeID.required">*</small> Segmento:</label>
            <div class="input-group">
              <b-form-select
                v-model="form.businessTypeID.value"
                :options="form.businessTypeID.options"
                :required="form.businessTypeID.required"
                :state="form.businessTypeID.state"
              >
                <template slot="first">
                  <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
                </template>
              </b-form-select>
              <div v-if="false" class="input-group-addon pointer" v-b-tooltip.hover title="Clique para adicionar um nova opção" @click="showBusinessTypeModal"><i class="fas fa-plus"></i></div>
            </div>
          </div>
          <div class="col-md-12 pt-3">
            <label for="txtGroup"><small class="text-danger" v-if="form.businessTypeID.required">*</small> Categoria <small>(Especialidade)</small>:</label>
            <div class="input-group">
              <b-form-select
                :disabled="form.businessTypeID.value === null || (form.categoryID.options !== null && form.categoryID.options.length === 0)"
                v-model="form.categoryID.value"
                :options="form.categoryID.options"
                :required="form.categoryID.required"
                :state="form.categoryID.state"
              >
                <template slot="first">
                  <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
                </template>
              </b-form-select>
              <span v-if="false" class="input-group-addon pointer" v-b-tooltip.hover title="Clique para adicionar um nova opção" @click="showCategoryModal"><i class="fas fa-plus"></i></span>
            </div>
          </div>
          <div class="col-md-12 pt-3">
            <label for="txtGroup"><small class="text-danger" v-if="form.categoryID.required">*</small> Produto / Serviço <small>(Procedimento)</small>:</label>
            <div class="input-group">
              <b-form-select
                :disabled="form.categoryID.value === null || (form.productID.options !== null && form.productID.options.length === 0)"
                v-model="form.productID.value"
                :options="form.productID.options"
                :required="form.productID.required"
                :state="form.productID.state"
              >
                <template slot="first">
                  <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
                </template>
              </b-form-select>
              <span v-if="false" class="input-group-addon pointer" v-b-tooltip.hover title="Clique para adicionar um nova opção" @click="showProductModal"><i class="fas fa-plus"></i></span>
            </div>
          </div>
          <div class="col-md-12 pt-3">
            <label for="txtGroup"><small class="text-danger" v-if="form.budgetStatus.required">*</small> Aprovação <small>(Situação)</small>: </label>
            <div class="input-group">
              <b-form-select
                v-model="form.budgetStatus.value"
                :options="form.budgetStatus.options"
                :required="form.budgetStatus.required"
                :state="form.budgetStatus.state"
              >
                <template slot="first">
                  <option :value="-1">REPROVADO</option>
                  <option :value="null" selected>PENDENTE</option>
                  <option :value="1">APROVADO</option>
                </template>
              </b-form-select>
              <div v-if="false" class="input-group-addon pointer" v-b-tooltip.hover title="Clique para adicionar um nova opção" @click="showBusinessTypeModal"><i class="fas fa-plus"></i></div>
            </div>
          </div>
        </div>
        <div class="form-group row pt-2 pb-4">
          <div class="col-md-12">
            <label for="txtOpportunityValue"> Valor total:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-coins"></i></span>
              <money
                :placeholder="$lang.Currency.Placeholder"
                class="form-control text-bold"
                style="text-align: right;padding-left: 4px;"
                v-model.lazy="form.opportunityValue.value"
                :required="form.opportunityValue.required"
                :state="form.opportunityValue.state"
                v-bind="$lang.Currency.Format"
              ></money>
            </div>
          </div>
        </div>
      </b-container>
      <template #modal-footer>
        <div class="w-100 text-right">
          <!--v-if="hasRole(this.roles.RW_CRM_ADDEDPRODUCT)"-->
          <button 
            type="button"
            class="btn btn-third btn-sm"
            @click="onSave"
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonSave"
          >
            <i class="fa fa-check-circle" /> {{ $lang.Common.Buttons.Save }}
          </button>&nbsp;
          <button
            type="button"
            class="btn btn-default btn-sm text-secundary"
            @click="hideModal"
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonClose"
          >
            <i class="fas fa-times-circle"></i> {{ $lang.Common.Buttons.Close }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import BudgetAdd from './budgetadd.js'
export default BudgetAdd
</script>
