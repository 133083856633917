<template>
  <div class="animated fadeIn">
    <Alerts ref="alert"/>
    <Forms ref="frmGroup"
           :title="this.$lang.Accounts.TitleForm"
           :showHelp="true"
           :showClearButton="true"
           :showSaveButton="true"
           v-on:Clear="onClear"
           v-on:Save="onSave"
    >
      <div class="form-group row">
        <div class="col-md-6">
          <label for="txtName"><small class="text-danger">*</small> {{$lang.Accounts.LabelName}}:</label>
          <div class="input-group">
            <b-form-input type="text"
                          :placeholder="$lang.Accounts.PlaceholderEnterName"
                          v-model="form.name.value"
                          :size="form.name.maxlength"
                          :required="form.name.required"
                          :state="form.name.state"
            ></b-form-input>
          </div>
        </div>
        <div class="col-md-6">
          <label for="txtEmail"><small class="text-danger">*</small> {{$lang.Accounts.LabelEmail}}:</label>
          <div class="input-group">
            <b-form-input type="email"
                          :placeholder="$lang.Accounts.PlaceholderEnterEmail"
                          v-model="form.email.value"
                          :size="form.email.maxlength"
                          :required="form.email.required"
                          :state="form.email.state"
                          autocomplete="false"
                          @blur.native="onCheckEmail"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label for="txtPhone"><small v-show="form.cellPhone.required" class="text-danger">*</small> {{$lang.Accounts.LabelMobilePhone}}:</label>
          <div class="input-group">
            <b-form-input type="text"
                          name="txtPhone"
                          :placeholder="$lang.Accounts.PlaceholderMobilePhone"
                          v-model="form.cellPhone.value"
                          :size="form.cellPhone.maxlength"
                          :required="form.cellPhone.required"
                          :state="form.cellPhone.state"
                          v-mask="['(##) # ####-####']"
            ></b-form-input>
          </div>
        </div>
        <div class="col-md-6">
          <label for="txtGroup"><small class="text-danger">*</small> {{$lang.Accounts.LabelGroup}}:</label>
          <div class="input-group">
            <b-form-select v-model="form.groupID.value" :options="form.groupID.options" class="mb-3" >
              <template slot="first">
                <!-- this slot appears above the options from 'options' prop -->
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12 pt-1">
          <h5 class="text-third"><i class="fa fa-lock"></i> {{$lang.Accounts.AccessPasswords}}</h5>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label for="txtPassword"><small class="text-info">*</small> {{$lang.Accounts.LabelPassword}}:</label>
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-lock"></i></span>
            <b-form-input type="password"
                          :placeholder="$lang.Accounts.PlaceholderEnterPassword"
                          v-model="form.password.value"
                          :size="form.password.maxlength"
                          :required="form.password.required"
                          :state="form.password.state"
                          autocomplete="new-password"
            ></b-form-input>
          </div>
        </div>
        <div class="col-md-6">
          <label for="txtConfirmation"><small class="text-info">*</small> {{$lang.Accounts.LabelConfirmation}}:</label>
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-lock"></i></span>
            <b-form-input type="password"
                          :placeholder="$lang.Accounts.PlaceholderConfirmationPassword"
                          v-model="form.confirmation.value"
                          :size="form.confirmation.maxlength"
                          :required="form.confirmation.required"
                          :state="form.confirmation.state"
                          autocomplete="new-password"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <small class="text-third"><i class="fa fa-info-circle"></i> {{$lang.Accounts.PasswordOnlyNewRegistration}}</small>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12 pt-3">
          <h5 class="text-third"><i class="fas fa-store"></i> Acesso as Filiais <small>(Unidades)</small></h5>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-12 pb-2">
          <div style="border: solid 1px #ccc;border-radius: 7px;padding: 20px; padding-bottom: 35px; display: block;position: relative;background-color: #fefefe;">
            <label for="txtGroup">Disponível:</label>
            <div class="input-group pb-1">
              <b-form-select v-model="form.branchID.value" :options="form.branchID.options" @change="onChangeBranch">
                <template slot="first">
                  <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
                </template>
              </b-form-select>
            </div>
            <label class="pt-2">Liberadas:</label>
            <b-form-tags v-model="form.tagsBranch.value" 
                         input-id="tags-separators"
                         separator=" ,;"
                         placeholder="Clique no x para remover!"
                         no-add-on-enter
                         remove-on-delete
            ></b-form-tags>
          </div>
        </div>
      </div>
    </Forms>
    <Tables ref="tableGrid"
            :columns="[$lang.Accounts.DataTable.Name,
                       $lang.Accounts.DataTable.Group,
                       $lang.Common.DataTable.Status,
                       $lang.Common.DataTable.ActionDate,
                       $lang.Common.DataTable.AccountAction,
                       $lang.Common.DataTable.Actions]"
            :showSearch="true"
            :showPrint="false"
            :showDownload="false"
            :showFullscreen="false"
            v-on:Load="onLoad"
    >
      <tr v-for="obj in this.objList" :key="obj.id">
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.name" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.group ? obj.group.name : ''" />
        <TableColumn :IsStatus="true" v-on:Edit="onEdit(obj)" :status="obj.status" />
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
        <TableColumn :IsActionsButtons="true" v-on:Change="onChange(obj)" v-on:Delete="onDelete(obj)" :status="obj.status" />
      </tr>
    </Tables>
  </div>
</template>
<script>
import Account from './account.js'

export default Account
</script>
