export default {
  businessTypeID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  categoryID: {
    value: null,
    options: [],
    clearOption: true,
    required: true,
    state: null
  },
  productID: {
    value: null,
    options: [],
    clearOption: true,
    required: true,
    state: null
  },
  opportunityValue: {
    value: 0,
    required: true,
    state: null
  },
  budgetStatus: {
    value: 0,
    options: [],
    required: false,
    state: null
  }
}
