<template>
  <div class="animated fadeIn">
    <b-modal ref="modal-column-events" size="lg" id="modal-column-events" title="Eventos">
      <template #modal-header>
        <div class="w-100">
          <h5 class="text-bold"><i class="fas fa-bolt"></i> Eventos</h5>
        </div>
      </template>

      <div class="row">
        <div class="col-md-12">
          <b-form-select v-model="event" :options="availableEvents" class="m-0" >
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>

          <hr class="my-3"/>
        </div>
        
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 mb-2">
              <div class="row">
                <div class="col-md">
                  <label>Tipo de evento:</label>
                  <div class="input-group">
                    <b-form-select v-model="field.eventType" :options="eventTypes" :disabled="(event === null || event === undefined)" class="m-0" >
                      <template slot="first">
                        <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
                      </template>
                    </b-form-select>
                  </div>
                </div>
                <div class="col-md">
                  <label>Campo:</label>
                  <div class="input-group">
                    <b-form-select v-model="field.details" :options="availableFields" :disabled="(event === null || event === undefined)" class="m-0" >
                      <template slot="first">
                        <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
                      </template>
                    </b-form-select>
                  </div>
                </div>
                <div class="col-md-auto">
                  <label> Obrigatório</label>
                  <div class="input-group d-flex align-items-center justify-content-center">
                    <b-form-checkbox :disabled="(event === null || event === undefined) || field.eventType !== 1" v-model="field.isRequired" switch class="ml-3 mt-1" />
                  </div>
                </div>
                <div class="col-md-auto d-flex align-items-end">
                  <button v-if="isEditing" type="button" :disabled="(event === null || event === undefined)" class="btn btn-third" style="width: 112px;" @click="onSaveField"><i class="fa fa-check mr-1"/>Salvar</button>
                  <button v-else type="button" :disabled="(event === null || event === undefined)" class="btn btn-third" @click="onAddField"><i class="fa fa-plus mr-1"/>Adicionar</button>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-hover" style="border: 1px solid #dcdede;">
                  <thead class="thead-third" style="border: solid 1px #0277bd; height: 44px;">
                    <tr>
                      <th class="text-center">Tipo</th>
                      <th class="text-center">Campo</th>
                      <th class="text-center">Obrigatório</th>
                      <th class="text-center">{{$lang.Common.DataTable.Actions}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!selectedEvent || !selectedEvent.fields || selectedEvent.fields.length === 0">
                      <td colspan="4" class="text-center">
                        Não há eventos
                      </td>
                    </tr>
                    <tr v-else v-for="obj in selectedEvent.fields" :key="obj.id">
                      <td class="text-center">
                        <a @click="onEditField(obj)" href="javascript:void(0)">{{obj.eventType === 0 ? 'Limpar' : obj.eventType === 1 ? 'Preencher' : 'N/D' }}</a>
                      </td>
                      <td class="text-center">
                        <a @click="onEditField(obj)" href="javascript:void(0)">{{$lang.Boards.Configuration.Column.Events.Fields[obj.name]}}</a>
                      </td>
                      <td class="text-center">
                        <a @click="onEditField(obj)" href="javascript:void(0)">{{obj.eventType === 0 ? 'N/D' : obj.isRequired ? 'Sim' : 'Não' }}</a>
                      </td>
                      <td class="text-center text-third">
                        <span style="font-size: 16px;" class="pointer" @click="onDeleteField(obj)"><i class="fa fa-trash px-2"/></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100 text-right">
          <button
            type="button"
            class="btn btn-third btn-sm"        
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonSave"
            @click="onSave"
          >
            <i class="fa fa-check-circle" /> {{ $lang.Common.Buttons.Save }}
          </button>&nbsp;
          <button
            type="button"
            class="btn btn-default btn-sm text-secundary"
            @click="hide"
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonClose"
          >
            <i class="fas fa-times-circle"></i> {{ $lang.Common.Buttons.Close }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

import Guid from '@/shared/utils/guid.js'
import { toast, loading } from '@/shared/utils/UIHelper'

import ColumnEventRepository from '@/shared/http/repositories/onboarding/columnevent.js'

export default {
  name: 'Event',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      boardColumnID: null,
      columnEvents: [],
      fields: [],
      event: null,
      isEditing: false,
      field: {
        id: null,
        eventType: null,
        details: null,
        isRequired: true
      }
    }
  },
  computed: {
    availableEvents: function () {
      return [
        {
          value: 0,
          text: 'Ao adicionar'
        },
        {
          value: 1,
          text: 'Ao mover'
        }
      ]
    },
    availableFields: function () {
      let event = this.selectedEvent

      if (event) {
        if (this.isEditing === true) {
          return this.fields
        }

        let fields = this.fields.filter(f => {
          let hasField = event.fields.findIndex(ev => ev.name === f.value.name) >= 0

          if(!hasField) {
            return f
          }
        })

        return fields
      } else {
        return []
      }
    },
    selectedEvent: function () {
      let index = this.columnEvents.findIndex(c => c.trigger === this.event)

      return this.columnEvents[index]
    },
    eventTypes: function () {
      return [
        {
          value: 0,
          text: 'Limpar'
        },
        {
          value: 1,
          text: 'Preencher'
        }
      ]
    },
  },
  watch: {
    'event': function (value) {
      if (value >= 0) {
        let index = this.columnEvents.findIndex(e => e.trigger === value)

        if (index < 0) {
          let event = {
            id: Guid.new(),
            boardColumnID: this.boardColumnID,
            trigger: value,
            fields: []
          }

          this.columnEvents.push(event)
        }
      }

      this.onClearField()
    }
  },
  methods: {
    show (boardColumnID) {
      loading.push()
      if (boardColumnID && boardColumnID !== Guid.empty) {
        this.boardColumnID = boardColumnID
        this.onLoadAllEvents(boardColumnID)
        this.onLoadAllFields()
        this.$refs['modal-column-events'].show()
      }
      loading.pop()
    },
    hide () {
      this.$refs['modal-column-events'].hide()
      this.onClear()
    },
    onClear () {
      this.boardColumnID = null
      this.columnEvents = []
      this.fields = []
      this.event = null
      this.isEditing = false
      this.field = {
        id: null,
        eventType: null,
        details: null,
        isRequired: true
      }
    },
    onSave () {
      ColumnEventRepository.Save(this.columnEvents)
        .then(res => {
          let { code } = res.data

          if (code === 200) {
            this.hide()
          }
        })
        .catch(this.onException)
    },
    onLoadAllEvents (boardColumnID) {
      ColumnEventRepository.GetEvents(boardColumnID)
        .then(res => {
          let { code, data } = res.data

          if (code === 200) {
            this.columnEvents = data
          }
        })
        .catch(this.onException)
    },
    onLoadAllFields () {
      ColumnEventRepository.GetFields()
        .then(res => {
          let { code, data } = res.data

          if(code === 200) {
            this.fields = data.map(f => {
              return {
                value: f,
                text: this.$lang.Boards.Configuration.Column.Events.Fields[f.name]
              }
            })
          }
        })
        .catch(this.onException)
    },
    onClearField () {
      this.field.id = null
      this.field.details = null
      this.field.eventType = null
      this.field.isRequired = true
      this.isEditing = false
    },
    onAddField () {
      if (this.event !== null && this.event !== undefined) {
        let field = this.field

        if (field.eventType === null || field.eventType === undefined) {
          toast.error('O tipo de evento é obrigatório!')
          return
        }

        if (!field.details) {
          toast.error('O tipo de evento é obrigatório!')
          return
        }

        let index = this.columnEvents.findIndex(c => c.trigger === this.event)

        this.columnEvents[index].fields.push({
          id: Guid.new(),
          columnEventID: this.selectedEvent.id,
          eventType: field.eventType,
          name: field.details.name,
          type: field.details.type,
          isRequired: field.isRequired
        })

        this.onClearField()
      } else {
        toast.error('É necessário selecionar um evento!')
      }

      this.isEditing = false
    },
    onSaveField () {
      let field = this.field
      let index = this.columnEvents.findIndex(c => c.trigger === this.event)

      if (index >= 0) {
        let columnField = this.columnEvents[index].fields.find(f => f.id === field.id)
        columnField.eventType = field.eventType,
        columnField.name = field.details.name,
        columnField.type = field.details.type,
        columnField.isRequired = field.isRequired
      } else {
        toast.error('Ocorreu um erro ao salvar o evento.')
      }

      this.onClearField()
    },
    onEditField (field) {
      let cachedField = this.fields.find(f => f.value.name === field.name)

      if (cachedField) {
        this.isEditing = true
        this.field.id = field.id
        this.field.details = cachedField.value
        this.field.eventType = field.eventType
        this.field.isRequired = field.isRequired
      }
    },
    onDeleteField (field) {
      let index = this.columnEvents.findIndex(c => c.trigger === this.event)

      if (index >= 0) {
        this.columnEvents[index].fields.splice(index, 1)
      }
    }
  }
}
</script>