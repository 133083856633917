import ActionsForm from '@/shared/mixins/actionsForm'
import { toast, loading } from '@/shared/utils/UIHelper'

import AccountRepository from '@/shared/http/repositories/access/account'
import AccountModel from '@/shared/models/access/accountmodel'
import AccountForms from './profile-form'
import { JWTService } from '@/shared/services/jwt'

export default {
  name: 'profile',
  mixins: [ActionsForm],
  data () {
    return {
      data: AccountModel,
      form: AccountForms,
      accountID: JWTService.GetTokenPayload().Sid
    }
  },
  mounted() {
  },
  methods: {
    onLoad () {
      loading.push()
      AccountRepository.GetById(this.accountID)
        .then((response) => {
          if (response) {
            if (response.data.data) {
              var obj = response.data.data
              if (obj) {
                this.form.name.value = obj.name
                this.form.email.value = obj.email
                this.form.cellPhone.value = obj.cellPhone
                this.data = obj
              }
            }
          }
          loading.close()
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onUpdate() {
      if (this.form.oldpassword.value) {
        if (this.form.password.value) {
          if (this.form.password.value === this.form.oldpassword.value) {
            toast.warning('A nova senha não pode ser igual a atual!')
            return false
          }

          if (this.form.password.value != this.form.confirmation.value) {
            toast.warning('Sua nova senha e a confirmação precisam ser iguais!')            
            return false
          }

          loading.push()
          this.data.password = this.form.password.value.toString()
          AccountRepository.Update(this.data)
            .then((response) => {
              this.onResult(response)
            })
            .catch((err) => {
              this.onException(err)
            })
        } else {
          toast.warning('Você precisa informar a nova senha!')
        }
      } else {
        toast.warning('Você precisa informar a senha atual!')
      }
    },
    onCheckEmail () {
      var email = this.form.email.value
      loading.push()
      AccountRepository.GetByEmail(email)
        .then((response) => {
          if (response) {
            if (response.data.data) {
              var obj = response.data.data
              if (obj) {
                toast.warning(this.$lang.Accounts.AlertEmailExisted)
              }
            }
          }
          loading.pop()
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}
