import VeeValidate from 'vee-validate'
import ptBR from 'vee-validate/dist/locale/pt_BR'

VeeValidate.Validator.localize('pt_BR', ptBR)

const VeeValidateConfig = {
  aria: true,
  classNames: {},
  classes: false,
  delay: 0,
  dictionary: null,
  errorBagName: 'errors', // change if property conflicts
  events: 'input|blur',
  fieldsBagName: 'veeFields',
  i18n: null, // the vue-i18n plugin instance
  i18nRootKey: 'validations', // the nested key under which the validation messsages will be located
  inject: true,
  locale: 'pt_BR',
  strict: true,
  validity: false
}

export default VeeValidateConfig
