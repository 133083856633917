import ActionsForm from '@/shared/mixins/actionsForm'
import Tools from '@/components/tools/Tools.js'
import { loading } from '@/shared/utils/UIHelper'
import GeoMixin from '@/shared/mixins/geo.mixin.js'

import CompanyRepository from '@/shared/http/repositories/settings/company'
import CompanyModel from '@/shared/models/settings/companymodel'
import CompanyForms from './company-form'

export default {
  name: 'Company',
  mixins: [ActionsForm, GeoMixin],
  data () {
    return {
      data: CompanyModel,
      form: CompanyForms
    }
  },
  computed: {
    FieldSizeTip: function () {
      return this.$lang.Common.Tips.MaximumSize.replace('@SIZE@', this.form.description.maxlength)
    }
  },
  methods: {
    onLoad () {
      loading.push()
      CompanyRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then(this.onResultGrid)
        .catch(this.onException)
    },
    onEdit (obj) {
      loading.push()
      Tools.SetValueInForm(this.form, this.data, obj)
      this.form.cityID.value = null
      if(obj.city) {
        if (!this.form.countryID.value && this.form.countryID.options.length === 1) {
          this.form.countryID.value = this.form.countryID.options[0].value
        }
        this.form.stateID.value = obj.city.stateID
        setTimeout(() => {
          this.form.cityID.value = obj.cityID
        }, 300)
      }
      loading.pop()
    },
    onCreate () {
      loading.push()
      CompanyRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      CompanyRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      CompanyRepository.Delete(obj.id)
        .then(this.onResult)
        .catch(this.onException)
    }
  }
}
