<template>
  <div class="animated fadeIn">
    <div class="card p-4 mb-2">
      <div class="form-group row">
        <div class="col-md-12 text-left">
          <h6 class="text-third text-bold"><i class="fas fa-briefcase"></i> ORÇAMENTO</h6>
          <small class="pb-3">Adicione o produto ou serviço relacionado no orçamento:</small>
        </div>
      </div>
      <div class="form-group row pt-1">
        <div class="col-md-12">
          <Tables ref="tableGrid"
                  :columns="[
                    'Produto',
                    'Categoria',
                    'Valor',
                    'Aprovado',
                    $lang.Common.DataTable.ActionDate,
                    $lang.Common.DataTable.AccountAction,
                    $lang.Common.DataTable.Actions
                  ]"
                  :showNew="hasRole(this.roles.RW_CRM_ADDEDPRODUCT)"
                  :showSearch="false"
                  :showReload="true"
                  :showTipFooter="false"
                  v-on:Load="onLoad"
                  v-on:New="onNewProduct"
                  v-on:Reload="onReload"
          >
            <tr v-for="obj in this.objList" :key="obj.id">             
              <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.product ? obj.product.name : ''" />
              <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.product && obj.product.category ? obj.product.category.name : ''" />
              <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="formatValue(obj.value)" />
              <TableColumn :IsCustom="true" v-on:Edit="onEdit(obj)">
                <span v-if="obj.budgetStatus === -1" class="badge badge-danger text-toupper">&nbsp;REPROVADO&nbsp;</span>
                <span v-if="obj.budgetStatus === 1" class="badge badge-success text-toupper">&nbsp;APROVADO&nbsp;</span>
                <span v-else-if="!obj.budgetStatus || obj.budgetStatus === 0" class="badge badge-primary text-toupper">&nbsp;PENDENTE&nbsp;</span>
              </TableColumn>
              <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
              <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction.split(' ')[0]" />
              <TableColumn :IsActionsButtons="true" :HasChange="false" v-on:Delete="onDelete(obj)" :status="obj.status" />
            </tr>
            <template slot="table-footer">
              <tr>
                <td colspan="3" class="text-right text-third"><strong><i class="fas fa-funnel-dollar text-third"></i>&nbsp; {{formatValue(totalValue)}}</strong></td>
                <td colspan="4">&nbsp;</td>
              </tr>
            </template>
          </Tables>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BudgetList from './budgetlist.js'
export default BudgetList
</script>
