export default {
  leadName: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  },
  leadPhone: {
    value: '',
    maxlength: '20',
    required: true,
    state: null
  },
  companyID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  branchID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  situation: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  leadOriginID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  reasonLossID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
   managerID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  responsibleAppraiserID: {
    value: null,
    options: [],
    required: false,
    state: null
  },
  createDateStart: {
    value: null,
    required: false,
    state: null
  },
  createDateEnd: {
    value: null,
    required: false,
    state: null
  }
}
