import Tools from '@/components/tools/Tools.js'
import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'

import ProfileRepository from '@/shared/http/repositories/access/profile'
import GroupRepository from '@/shared/http/repositories/access/group'
import GroupProfileRepository from '@/shared/http/repositories/access/groupprofile'
import AccountRepository from '@/shared/http/repositories/access/account'
import AccountProfileRepository from '@/shared/http/repositories/access/accountprofile'
import PermissionRepository from '@/shared/http/repositories/access/permission'
import PermissionModel from '@/shared/models/access/permissionmodel'
import PermissionForms from './permission-form'

import PermissionMixin from '@/store/module/permission/mixin'
import { actionTypes as PermissionActions } from '@/store/module/permission/actions'

export default {
  name: 'Permission',
  mixins: [ActionsForm, PermissionMixin],
  data () {
    return {
      data: PermissionModel,
      form: PermissionForms,
      permissionTypeUser: false,
      permissionTypeGroup: false,
      profiles: null
    }
  },
  computed: {
    FieldSizeTip: function () {
      return this.$lang.Common.Tips.MaximumSize.replace('@SIZE@', this.form.description.maxlength)
    }
  },
  methods: {
    onLoad () {
      this.onLoadAllProfiles()
      this.form.permissionType.value = 'GRP'
      this.onChangePermissionType(this.form.permissionType.value)
    },
    onClear () {
      Tools.ClearDataForms(this.form, this.data)
      if (this.$refs.alert) {
        this.$refs.alert.Close()
      }
      this.onClearPermissions()
    },
    onReloadPermission () {
      this[PermissionActions.GET_ALL_PERMISSIONS]()
        .then((response) => {
        })
    },
    async onCreate () {
      loading.push()
      /*
      var self = this
      setTimeout(function () {
        self.onReloadPermission()
      }, 3000)
      */
      await PermissionRepository.Create(this.data)
        .then(this.onResultSimple)
        .catch(this.onException)
      this.onReloadPermission()
    },
    onLoadAllProfiles () {
      loading.push()
      ProfileRepository.GetAll()
        .then((response) => {
          this.profiles = response.data.data
          loading.close()
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onLoadAllGroup () {
      loading.push()
      this.onClearPermissions()
      GroupRepository.GetAll()
        .then((response) => {
          this.form.groupID.options = this.onResultDropdown(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onLoadAllAccount () {
      loading.push()
      this.onClearPermissions()
      AccountRepository.GetAll()
        .then((response) => {
          this.form.accountID.options = this.onResultDropdown(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onChangePermissionType (e) {
      this.onClearPermissions()
      switch (e) {
        case 'GRP':
          this.form.groupID.value = null
          this.onLoadAllGroup()
          this.permissionTypeGroup = true
          this.permissionTypeUser = false
          break
        case 'URS':
          this.form.accountID.value = null
          this.onLoadAllAccount()
          this.permissionTypeUser = true
          this.permissionTypeGroup = false
          break
        default :
          this.permissionTypeUser = false
          this.permissionTypeGroup = false
          this.form.accountID.value = null
          this.form.groupID.value = null
          break
      }
    },
    onChangeGroup (e) {
      this.onClearPermissions()
      if (e) {
        loading.push()
        GroupProfileRepository.GetAllByGroupId(e)
          .then((response) => {
            var data = response.data.data
            var profileList = this.profiles

            if (data && data.length > 0) {
              for (var x in data) {
                for (var i in profileList) {
                  if (profileList[i].id === data[x].profileID) {
                    this.form.permissionUsed.options.push({ 'text': profileList[i].name, 'value': profileList[i].id })
                    profileList.splice(i, 1)
                  }
                }
              }
              this.form.permissionAvailable.options = this.onSetDropdown(profileList)
              this.onLoadAllProfiles()
            } else {
              this.form.permissionAvailable.options = this.onSetDropdown(this.profiles)
            }
            loading.close()
          })
          .catch((err) => {
            this.onException(err)
          })
      }
    },
    onChangeAccount (e) {
      this.onClearPermissions()
      if (e) {
        loading.push()
        AccountProfileRepository.GetAllByAccountId(e)
          .then((response) => {
            var data = response.data.data
            var profileList = this.profiles
            if (data && data.length > 0) {
              for (var x in data) {
                for (var i in profileList) {
                  if (profileList[i].id === data[x].profileID) {
                    this.form.permissionUsed.options.push({ 'text': profileList[i].name, 'value': profileList[i].id })
                    profileList.splice(i, 1)
                  }
                }
              }
              this.form.permissionAvailable.options = this.onSetDropdown(profileList)
              this.onLoadAllProfiles()
            } else {
              this.form.permissionAvailable.options = this.onSetDropdown(this.profiles)
            }
            loading.close()
          })
          .catch((err) => {
            this.onException(err)
          })
      }
    },
    onClearPermissions () {
      this.form.permissionAvailable.options = []
      this.form.permissionUsed.options = []
    },
    onAddPermissions () {
      var selected = this.form.permissionAvailable.value
      var options = this.form.permissionAvailable.options
      if (selected) {
        for (var x in selected) {
          for (var i in options) {
            if (options[i].value === selected[x]) {
              this.form.permissionUsed.options.push(options[i])
              this.form.permissionAvailable.options.splice(i, 1)
            }
          }
        }
      }
    },
    onRemovePermissions () {
      var selected = this.form.permissionUsed.value
      var options = this.form.permissionUsed.options
      if (selected) {
        for (var x in selected) {
          for (var i in options) {
            if (options[i].value === selected[x]) {
              this.form.permissionAvailable.options.push(options[i])
              this.form.permissionUsed.options.splice(i, 1)
            }
          }
        }
      }
    }
  }
}
