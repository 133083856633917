import APIClient from '@/shared/http/clients/api'

const resourceName = 'dashboard'
const APIBOnboarding = process.env.VUE_APP_ONBOARDING

const GetTotalLeadCreated = (data, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/lead/total/created`, data, config)
const GetTotalScheduled = (data, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/lead/total/scheduled`, data, config)
const GetTotalAccomplished = (data, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/lead/total/accomplished`, data, config)
const GetTotalLeadGain = (data, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/lead/total/gain`, data, config)
const GetTotalRevenues = (data, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/lead/total/revenues`, data, config)
const GetTotalBudgetRevenue = (data, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/lead/total/budgetrevenue`, data, config)
const GetTotalSchedulingOrigin = (data, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/schedules/origin`, data, config)
const GetTotalGainOrigin = (data, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/gain/origin`, data, config)
const GetTotalReasonLoss = (data, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/reason/loss`, data, config)
const GetLeadsGain = (data, limit, index, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/lead/gain?limit=${limit}&index=${index}`, data, config)
const GetLeadsLost = (data, limit, index, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/lead/lost?limit=${limit}&index=${index}`, data, config)
const GetAllTotalNewLeadsDays = (data, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/lead/total/news`, data, config)
const GetAllTotalReactivatedLeadsDays = (data, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/lead/total/reactivated`, data, config)

const DashboardRepository = {
  GetTotalLeadCreated,
  GetTotalScheduled,
  GetTotalAccomplished,
  GetTotalLeadGain,
  GetTotalRevenues,
  GetTotalBudgetRevenue,
  GetTotalSchedulingOrigin,
  GetTotalGainOrigin,
  GetTotalReasonLoss,
  GetLeadsGain,
  GetLeadsLost,
  GetAllTotalNewLeadsDays,
  GetAllTotalReactivatedLeadsDays,
}

export default DashboardRepository
