<template>
  <ol class="breadcrumb fixed-breadcrumb">
    <li class="breadcrumb-item">
      <router-link :to="{ name: 'StartPage' }">
        Home
      </router-link>
    </li>
    <li
      class="breadcrumb-item"
      v-for="(item, index) in list"
      :key="index"
      v-if="isValid(item) && isGuid(item) === false"
    >
      <span class="active text-capitalize">{{ decodeURIComponent(item.replace('-', ' ').replace('-', ' ')) }}</span>
    </li>
    <!--
    <li class="breadcrumb-item" v-for="(item, index) in list" :key="index"><span class="active" v-if="isLast(index)">{{ showName(item) }}</span>
      <router-link :to="item.path" v-else>{{ showName(item) }}</router-link>
    </li>
    -->
  </ol>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    isGuid (text) {
      let regEx = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/g
      let result = text.match(regEx)

      if (result !== null) {
        return true
      } else return false
    },
    isValid (item) {
      if (parseInt(item.length) > 1) return true
      else return false
    },
    isLast (index) {
      return index === this.list.length - 1
    },
    showName (item) {
      if (item.meta && item.meta.label) {
        item = item.meta && item.meta.label
      }
      if (item.name) {
        item = item.name
      }
      return item.replace('-', ' ')
    }
  }
}
</script>
