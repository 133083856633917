<template>
  <div class="animated fadeIn card p-4 mb-1">
    <div class="form-group row pb-3">
      <div class="col-md-6">
        <label for="txtGroup"><small class="text-danger" v-if="form.companyID.required">*</small> Empresa:</label>
        <div class="input-group">
          <span class="input-group-addon"><i class="fas fa-briefcase"></i></span>
          <b-form-select v-model="form.companyID.value" :options="form.companyID.options">
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-6">
        <label for="txtGroup"><small class="text-danger" v-if="form.branchID.required">*</small> Filial <small>(Unidade)</small>:</label>
        <div class="input-group">
          <span class="input-group-addon"><i class="fas fa-building"></i></span>
          <b-form-select v-model="form.branchID.value" :options="form.branchID.options">
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ActionsForm from '@/shared/mixins/actionsForm'
import { loading, toast } from '@/shared/utils/UIHelper'
import Tools from '@/components/tools/Tools.js'

import CardRepository from '@/shared/http/repositories/onboarding/card.js'
import CompanyRepository from '@/shared/http/repositories/settings/company'
import BranchRepository from '@/shared/http/repositories/settings/branch'

const OrgForms = {
  companyID: {
    value: null,
    options: [],
    required: false,
    state: null
  },
  branchID: {
    value: null,
    options: [],
    required: false,
    state: null
  }
}
const OrgModel = {
  companyID: null,
  branchID: null
}

export default {
  name: 'CrmDetailsOrganization',
  mixins: [ActionsForm],
  data () {
    return {
      data: OrgModel,
      form: OrgForms,
      eventLoad: false
    }
  },
  props: {
    cardID: {
      type: String,
      required: true
    },
    lead: {
      type: Object,
      required: true
    }
  },
  watch: {
    'form.companyID.value': function (value) {
      if (value !== null) {
        this.onLoadAllBranch(value)
      } else {
        this.form.companyID.value = null
      }
    },
    'form.branchID.value': function (value) {
      if (value !== null) {
        if(!this.eventLoad) {
          this.onSave()
        }
      } else {
        this.form.branchID.value = null
      }
    }
  },
  created () {
    this.onLoadAllCompany()
  },
  methods: {
    onLoad () {
      if (this.lead) {
        this.eventLoad = true
        this.form.companyID.value = this.lead.companyID
        this.form.branchID.value = this.lead.branchID
        this.$nextTick(() => {
          this.eventLoad = false
        })
      }
    },
    onLoadAllCompany () {
      loading.push()
      CompanyRepository.GetAll()
        .then((response) => {
          this.form.companyID.options = this.onResultDropdown(response)
        })
        .catch(this.onException)
    },
    onLoadAllBranch (companyID) {
      loading.push()
      BranchRepository.GetAllByCompanyID(companyID)
        .then((response) => {
          this.form.branchID.options = this.onResultDropdown(response)

          if(this.form.branchID.options && this.form.branchID.options.length === 1) {
            this.form.branchID.value = this.form.branchID.options[0].value
          }

          if (this.lead) {
            this.form.branchID.value = this.lead.branchID
          }
        })
        .catch(this.onException)
    },
    onSave () {
      if (Tools.ValidateForms(this.form, this.data, self, toast)) {
        console.log('data', this.data)
        loading.push()
        CardRepository.UpdateCompanyBranch(this.cardID, this.data)
          .then(res => {
            let { code } = res.data
            if (code === 200) {
              toast.success(this.$lang.Common.Alerts.Success)
            } else {
              toast.error(this.$lang.Common.Alerts.Error)
            }
          })
          .catch(this.onException)

        loading.pop()
      }
    }
  }
}
</script>