export default {
  name: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  },
  contactName: {
    value: '',
    maxlength: '70',
    required: false,
    state: null
  },
  email: {
    value: '',
    maxlength: '70',
    required: false,
    state: null
  },
  phone: {
    value: '',
    maxlength: '13',
    required: false,
    state: null
  },
  cellPhone: {
    value: '',
    maxlength: '14',
    required: false,
    state: null
  },
  contactPhone: {
    value: '',
    maxlength: '13',
    required: false,
    state: null
  },
  contactCellPhone: {
    value: '',
    maxlength: '14',
    required: false,
    state: null
  },
  leadOriginID: {
    value: null,
    options: [],
    required: false,
    state: null
  },
  description: {
    value: '',
    maxlength: '500',
    required: false,
    rows: '3',
    noResize: true
  }
}
