<template>
  <div class="animated fadeIn">
    <Alerts ref="alert"/>
    <Forms ref="frmGroup"
           :title="this.$lang.Product.TitleForm"
           :isModal="isModal"
           :modalID="modalID"
           :showHelp="true"
           :showClearButton="true"
           :showSaveButton="true"
           v-on:Clear="onClear"
           v-on:Save="onSave"
    >
      <div class="form-group row">
        <div class="col-md-6">
          <label for="txtGroup"><small v-if="form.businessTypeID.required" class="text-danger">*</small> {{$lang.Product.LabelBusinessType}}:</label>
          <div class="input-group">
            <b-form-select v-model="form.businessTypeID.value" :options="form.businessTypeID.options" class="mb-3" @change="onChangeBusinessType">
              <template slot="first">
                <!-- this slot appears above the options from 'options' prop -->
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="col-md-6">
          <label for="txtGroup"><small v-if="form.categoryID.required" class="text-danger">*</small> {{$lang.Product.LabelCategory}}:</label>
          <div class="input-group">
            <b-form-select v-model="form.categoryID.value" :options="form.categoryID.options" class="mb-3" >
              <template slot="first">
                <!-- this slot appears above the options from 'options' prop -->
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <label for="txtName"><small v-if="form.name.required" class="text-danger">*</small> {{$lang.Product.LabelName}}:</label>
          <div class="input-group">
            <b-form-input type="text"
                          id="txtName"
                          name="txtName"
                          :placeholder="$lang.Product.PlaceholderEnterName"
                          v-model="form.name.value"
                          :size="form.name.maxlength"
                          :required="form.name.required"
                          :state="form.name.state"
                          class="text-toupper"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-12">
          <label for="txtDescription"> {{$lang.Product.LabelDescription}}:</label>
          <b-form-textarea id="txtDescription"
                           name="txtDescription"
                           v-model="form.description.value"
                           :placeholder="$lang.Product.PlaceholderEnterDescription"
                           :rows="form.description.rows"
                           :size="form.description.maxlength"
                           :noResize="form.description.noResize"
          ></b-form-textarea>
          <small class="small-help"><i class="fa fa-info-circle"></i> <span v-html="FieldSizeTip"></span>.</small>
        </div>
      </div>
    </Forms>

    <Tables v-if="!isModal" ref="tableGrid"
            :columns="[$lang.Product.DataTable.Name,
                       $lang.Product.DataTable.Category,
                       $lang.Product.DataTable.BusinessType,
                       $lang.Common.DataTable.Status,
                       $lang.Common.DataTable.ActionDate,
                       $lang.Common.DataTable.AccountAction,
                       $lang.Common.DataTable.Actions]"
            :showSearch="true"
            :showPrint="false"
            :showDownload="false"
            :showFullscreen="false"
            v-on:Load="onLoad"
    >
      <tr v-for="obj in this.objList" :key="obj.id">
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.name" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.category ? obj.category.name : ''" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.category && obj.category.businessType ? obj.category.businessType.name : ''" />
        <TableColumn :IsStatus="true" v-on:Edit="onEdit(obj)" :status="obj.status" />
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
        <TableColumn :IsActionsButtons="true" v-on:Change="onChange(obj)" v-on:Delete="onDelete(obj)" :status="obj.status" />
      </tr>
    </Tables>
  </div>
</template>

<script>
import Product from './product.js'
export default Product
</script>
