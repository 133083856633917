import APIClient from '@/shared/http/clients/api'

const resourceName = 'accountprofile'
const APIAccess = process.env.VUE_APP_Core

const GetAllByAccountId = (accountID, data = {}, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/account/${accountID}`, data, config)
const Create = (data = {}, config = {}) => APIClient.post(`${APIAccess}/${resourceName}`, data, config)
const Update = (data = {}, config = {}) => APIClient.put(`${APIAccess}/${resourceName}`, data, config)
const Delete = (id, config = {}) => APIClient.delete(`${APIAccess}/${resourceName}/${id}`, config)

const AccountProfileRepository = {
  GetAllByAccountId,
  Create,
  Update,
  Delete
}

export default AccountProfileRepository
