import { getNamespacedBy } from '@/store/utils'

export const actionTypes = {
  SIGN_IN: 'signIn',
  SIGN_OUT: 'signOut',
  SIGN_UP: 'signUp',
  FETCH_USER_INFO: 'fetchUserInfo'
}

export const globalActions = getNamespacedBy('auth', actionTypes)
