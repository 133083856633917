// import { globalActions as authActions } from '@/store/module/auth/actionTypes'
import { loading, toast } from '@/shared/utils/UIHelper'
import AuthRepository from '@/shared/http/repositories/access/auth'

const SIGN_IN = 'signIn'

export const actionTypes = {
  SIGN_IN
}

export const globalActions = {
  SIGN_IN: `signIn/${SIGN_IN}`
}

export const actions = {

  [SIGN_IN]: async ({ state }) => {
    loading.push()

    return AuthRepository.SignIn(state.credentials)
      .then((res) => {
        loading.pop()
        return Promise.resolve({ success: true, res })
      })
      .catch((err) => {
        toast.error('Usuário ou senha inválidos')
        loading.pop()
        // return Promise.reject({ success: false, err })
        return Promise.reject(new Error('Not authorized', err))
      })
  }
}
