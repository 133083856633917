const SET_GROUP = 'setGroup'
const SET_GROUPS = 'setGroups'

export const mutationTypes = {
  SET_GROUP,
  SET_GROUPS
}

export const mutations = {
  [SET_GROUP] (state, payload) {
    state.group = payload
  },

  [SET_GROUPS] (state, payload) {
    state.groups = payload
  }
}
