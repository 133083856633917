import { loading } from '@/shared/utils/UIHelper'

import CountryRepository from '@/shared/http/repositories/geo/country.js'
import StateRepository from '@/shared/http/repositories/geo/state.js'
import CityRepository from '@/shared/http/repositories/geo/city.js'

export default {
  name: 'GeoMixin',
  created () {
    this.onLoadAllCountry()
  },
  watch: {
    'form.countryID.value': function (value) {
      if (value !== null) {
        this.onLoadAllState(value)
      } else {
        this.form.stateID.value = null
      }
    },
    'form.stateID.value': function (value) {
      if (value !== null) {
        this.onLoadAllCity(value)
      } else {
        this.form.cityID.value = null
      }
    }
  },
  methods: {
    onLoadAllCountry () {
      loading.push()
      CountryRepository.GetAll()
        .then((response) => {
          this.form.countryID.options = this.onResultDropdown(response)
          if (response && response.data.code >= 0) {
            let data = response.data.data
            if (data.length === 1) {
              this.form.countryID.value = data[0].id
            }
          }
        })
        .catch(this.onException)
    },
    onLoadAllState (countryID) {
      loading.push()
      StateRepository.GetAllCountryID(countryID)
        .then((response) => {
          this.form.stateID.options = this.onResultDropdown(response)          
        })
        .catch(this.onException)
    },
    onLoadAllCity (stateID) {
      loading.push()
      CityRepository.GetAllByStateID(stateID)
        .then((response) => {
          this.form.cityID.options = this.onResultDropdown(response)          
        })
        .catch(this.onException)
    }
  }
}