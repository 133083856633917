<template>
  <div
    v-if="canRender"
    class="task-card"
    :style="`border-left: ${color} solid 3px;`"
    @click="Open"
  >
    <div class="task-header">
      <div>
        <p class="task-name pointer" @contextmenu="onOpenExternal(task)">{{task.opportunity ? task.opportunity.name : 'N/D'}} <small><i class="fas fa-external-link-alt pointer"></i></small></p> 
      </div>
      <b-avatar variant="info" class="task-image" size="3em" :text="initial" v-b-tooltip.hover :title="this.task && this.task.cardDetail && this.task.cardDetail.manager && this.task.cardDetail.manager.name ? this.task.cardDetail.manager.name + ' (RESPONSÁVEL)' : 'Não há um responsável definido!'"/>
    </div>
    <div class="task-details" v-if="boardPosition > 0 && showDetails">
      <span class="task-value"><small>Valor total:</small><br><strong class="f-12px">{{toCurrency(task.opportunityValue)}}</strong></span>
    </div>
    <div>
      <b-badge v-b-tooltip.hover title="Dias sem contato" v-if="task.daysInColumn > 8" variant="danger">{{task.daysInColumn}} dias</b-badge>
      <b-badge v-b-tooltip.hover title="Dias sem contato" v-else-if="task.daysInColumn >= 4 && task.daysInColumn <= 8" variant="warning">{{task.daysInColumn}} dias</b-badge>
      <b-badge v-b-tooltip.hover title="Dias sem contato" v-else variant="success">{{task.daysInColumn}} dias</b-badge>
      <span class="pl-2" v-b-tooltip.hover title="Contatos realizados"><i class="fas fa-headset"></i> {{task.totalCallphone}}</span>
      <span class="pl-2" v-b-tooltip.hover title="E-mail enviado"><i class="fas fa-envelope-open-text"></i> {{task.totalEmail}}</span>
      <span class="pl-2 text-danger" v-if="task.reactivated" v-b-tooltip.hover title="Quantidade de Reativações"><i class="fas fa-recycle"></i> <strong>{{task.reactivated}}</strong></span>
      <span class="pl-2" v-else v-b-tooltip.hover title="Quantidade de Reativações"><i class="fas fa-recycle"></i> 0</span>

      <span v-if="task.closingPercentage < 25" class="pl-2 text-third" v-b-tooltip.hover title="Percentual de fechamento"><i class="fas fa-thermometer-empty"></i> {{task.closingPercentage}}%</span>
      <span v-else-if="task.closingPercentage >= 25 && task.closingPercentage < 50" class="pl-2 text-third" v-b-tooltip.hover title="Percentual de fechamento"><i class="fas fa-thermometer-quarter"></i> {{task.closingPercentage}}%</span>
      <span v-else-if="task.closingPercentage >= 50 && task.closingPercentage < 75" class="pl-2 text-third" v-b-tooltip.hover title="Percentual de fechamento"><i class="fas fa-thermometer-half"></i> {{task.closingPercentage}}%</span>
      <span v-else-if="task.closingPercentage >= 75" class="pl-2 text-third" v-b-tooltip.hover title="Percentual de fechamento"><i class="fas fa-thermometer-three-quarters"></i> {{task.closingPercentage}}%</span>
      
      <span v-if="task.cardDetail.accomplished" v-b-tooltip.hover title="Data de comparecimento">&nbsp;|&nbsp; <i class="far fa-calendar-check"></i>&nbsp;{{moment(task.cardDetail.accomplished).format('DD/MM/yyyy')}}</span>
      <span v-else-if="task.cardDetail.reschedule" v-b-tooltip.hover title="Data de reagendamento">&nbsp;|&nbsp; <i class="far fa-calendar-alt"></i>&nbsp;{{moment(task.cardDetail.reschedule).format('DD/MM/yyyy')}}</span>
      <span v-else-if="task.cardDetail.scheduling" v-b-tooltip.hover title="Data de agendamento">&nbsp;|&nbsp; <i class="far fa-calendar-alt"></i>&nbsp;{{moment(task.cardDetail.scheduling).format('DD/MM/yyyy')}}</span>
    </div>
  </div>
</template>

<script>
const URLBASE_APP = process.env.VUE_APP_URLBASE_APP
export default {
  name: 'TaskCard',
  data () {
    return {
      showDetails: false
    }
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    color: {
      type: String,
      default: 'green'
    },
    boardColumnID: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    boardPosition: {
      type: Number,
      required: true
    },
    search: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    canRender: function () {
      let search = this.search
      let task = this.task

      return !search ||
        search.trim() === '' ||
        (task.opportunity && task.opportunity.name.toLowerCase().includes(search.toLowerCase())) ||
        (task.opportunity && task.opportunity.contact && task.opportunity.contact.name.toLowerCase().includes(search.toLowerCase()))
    },
    initial: function () {
      let result = ''
      if (this.task && this.task.cardDetail && this.task.cardDetail.manager && this.task.cardDetail.manager.name) {
        var names = this.task.cardDetail.manager.name.split(' ')
        if (names && names.length > 1) {
          result = names[0].substring(0,1) + names[names.length - 1].substring(0,1)
        } else if (names && names.length > 0) {
          result = names[0].substring(0,1)
        }
      }
      return result
    }
  },
  watch: {
    'boardColumnID': function (value) {
      this.task.boardColumnID = value
    },
    'index': function (value) {
      this.task.position = value
    }
  },
  mounted () {
    this.task.boardColumnID = this.boardColumnID
    this.task.position = this.index
  },
  methods: {
    Open () {
      this.$emit('Open', { cardID: this.task.id })
    },
    toCurrency (number) {
      return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(number)
    },
    onOpenExternal (obj) {
      const segment = URLBASE_APP +'/crm/detalhes/'
      let pathTab = segment + obj.id
      window.open(pathTab, '_blank');
      window.event.preventDefault();
      return false
    }
  }
}
</script>
<style scoped>
.f-12px {
  font-size: 16px;
}
</style>
