const SET_ACCOUNT = 'setAccount'
const SET_ACCOUNTS = 'setAccounts'

export const mutationTypes = {
  SET_ACCOUNT,
  SET_ACCOUNTS
}

export const mutations = {
  [SET_ACCOUNT] (state, payload) {
    state.account = payload
  },

  [SET_ACCOUNTS] (state, payload) {
    state.accounts = payload
  }
}
