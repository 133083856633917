<template>
  <div class="animated fadeIn">
    <div class="container">
      <div class="row pt-4">
        <div class="col-12 text-center">Bem-vindo <strong> {{AccountName}}</strong>!</div>
        <div class="col-12 text-center pt-4"><h3>Selecione a área desejada!</h3></div>
      </div>
      <div class="row pt-4 pb-3">
        <div class="col-12 text-center">
          <b-card-group deck>
            <b-card class="text-center pb-2 pointer" style="max-width: 20rem;" @click="onGoToCRM" v-if="hasRole(this.roles.MENU_CRM)">
              <i class="fas fa-address-card pt-4 text-third" style="font-size: 100px" />
              <h4 class="pt-3 text-default">CRM</h4>
              <div>Evolução de suas vendas</div>
            </b-card>

            <b-card class="text-center pb-2 pointer" style="max-width: 20rem;" v-if="false">
              <i class="far fa-calendar-alt pt-3 text-third" style="font-size: 100px" />
              <h4 class="pt-4 text-default">Agenda</h4>
              <div>Agenda de atendimentos</div>
            </b-card>

            <b-card class="text-center pb-2 pointer" style="max-width: 20rem;" @click="onGoToContact">
              <i class="fas fa-address-book pt-4 text-third" style="font-size: 100px" />
              <h4 class="pt-3 text-default">Contatos</h4>
              <div>Contatos cadastrados</div>
            </b-card>

            <b-card class="text-center pb-2 pointer" style="max-width: 20rem;" @click="onGoToDashboard" v-if="hasRole(this.roles.MENU_DASHBOARD)">
              <i class="fas fa-chart-pie pt-3 text-third" style="font-size: 100px" />
              <h4 class="pt-4 text-default">Dashboard</h4>
              <div>Indicadores de performance</div>
            </b-card>

            <b-card class="text-center pb-2 pointer" style="max-width: 20rem;" v-if="false">
              <i class="fas fa-chart-bar pt-4 text-third" style="font-size: 100px" />
              <h4 class="pt-3 text-default">Relatórios</h4>
              <div>Relatórios Gerenciais</div>
            </b-card>
          </b-card-group>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-12 text-center">
          <img src="/static/img/bg/doctors_hwty.svg" style="width: 40%; opacity: 0.5">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionsForm from '@/shared/mixins/actionsForm'
import AccountMixin from '@/store/module/account/mixin'
import Tools from '@/components/tools/Tools.js'

export default {
  name: 'StartPage',
  mixins: [ActionsForm, AccountMixin],
  computed: {
    AccountName: function () {
      var result = 'N/D'
      if (this.account && this.account.name) {
        result = this.account.name
      }
      return result
    }
  },
  mounted () {
    Tools.MinimizedSidebar()
  },
  methods: {
    onLoad () {},
    onGoToCRM () {
      this.$router.push({ name: 'Kanban' })
    },
    onGoToDashboard () {
      this.$router.push({ name: 'Dashboard' })
    },
    onGoToContact () {
      this.$router.push({ name: 'Contatos' })
    }
  }
}
</script>
<style scoped>
.card {
  border-radius: 11px;
  box-shadow: 0 0 1em #ccc;
}
h4 {
  font-weight: bold;
}
</style>
