<template>
  <td
    :class="classCss"
    style="font-size: 11px;"
  >
    <a
      v-if="IsText"
      @click="$emit('Edit', obj)"
      href="javascript:void(0)"
      :class="{ 'text-danger':(status === 0) }"
    ><strong
      v-if="IsStrong"
      :style="styles"
    >{{ (value) ? value: 'N/D' }}</strong><span v-else>{{ (value) ? value: 'N/D' }}</span></a>
    <a
      v-if="IsDatetime"
      @click="$emit('Edit', obj)"
      href="javascript:void(0)"
      :class="{ 'text-danger':(status === 0) }"
    >{{ (value) ? moment(new Date(value)).format('DD/MM/YYYY HH:mm') : 'N/D' }}</a>
    <a
      v-if="IsReminder"
      @click="$emit('Edit', obj)"
      href="javascript:void(0)"
      :class="{ 'text-danger':(status === 0) }"
    ><i class="far fa-calendar-alt"></i> {{ (value) ? moment(new Date(value)).format('DD/MM/YYYY') : 'N/D' }} &nbsp;às &nbsp;{{ (value) ? moment(new Date(value)).format('HH:mm') : '' }} <i class="far fa-clock"></i></a>
    <a
      v-if="IsDateOnly"
      @click="$emit('Edit', obj)"
      href="javascript:void(0)"
      :class="{ 'text-danger':(status === 0) }"
    >{{ (value) ? moment(new Date(value)).format('DD/MM/YYYY') : 'N/D' }}</a>
    <div
      v-if="IsStatus"
      @click="$emit('Edit', obj)"
    >
      <span
        v-if="status"
        class="badge badge-success text-toupper"
      >{{ $lang.Common.Status.Active }}</span>
      <span
        v-else
        class="badge badge-danger text-toupper"
      >{{ $lang.Common.Status.Inactive }}</span>
    </div>
    <div v-if="IsCustom" v-on:click="$emit('Edit', obj)" >
      <slot></slot>
    </div>
    <div v-if="IsActionsButtons">
      <i
        v-show="HasChange"
        v-if="status"
        @click="$emit('Change', obj)"
        class="fas fa-times text-danger icone-grid"
        v-b-tooltip.hover
        :title="$lang.Common.Tooltip.ButtonInactive"
      />
      <i
        v-show="HasChange"
        v-else
        @click="$emit('Change', obj)"
        class="fas fa-check text-success icone-grid"
        v-b-tooltip.hover
        :title="$lang.Common.Tooltip.ButtonActive"
      />
      &nbsp;
      <b-dropdown
        v-if="HasDelete"
        variant="link"
        size="sm"
        no-caret
      >
        <template slot="button-content">
          <i class="fas fa-trash-alt text-third icone-grid" /><span class="sr-only">{{ $lang.Common.Buttons.Delete }}</span>
        </template>
        <b-dropdown-header><strong>{{ $lang.Common.ConfirmDelete }}</strong></b-dropdown-header>
        <b-dropdown-item
          href="javascript:void(0);"
          @click="$emit('Delete', obj)"
        >
          <i class="fas fa-check-circle text-danger"></i>
          {{ $lang.Common.Yes }}
        </b-dropdown-item>
        <b-dropdown-item href="javascript:void(0);">
          <i class="fas fa-trash-restore" style="color: #236192;"></i>
          {{ $lang.Common.No }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-if="IsActionsDetails">
      <i
        v-if="HasChange"
        @click="$emit('Details', obj)"
        class="fa fa-plus-square-o"
        v-b-tooltip.hover
        :title="$lang.Common.Tooltip.ButtonDetails"
      />
    </div>
    <div v-if="IsLighthouse">
      <div v-if="value===0">
        <i
          v-if="HasChange"
          class="fa fa-circle icon-background-gray font-xl"
          v-b-tooltip.hover
          title="Não contém hIstórico/semáforo"
        />
      </div>
      <div v-if="value===1">
        <i
          v-if="HasChange"
          class="fa fa-circle icon-background-green font-xl"
        />
      </div>
      <div v-if="value===2">
        <i
          v-if="HasChange"
          class="fa fa-circle icon-background-yellow font-xl"
        />
      </div>
      <div v-if="value===3">
        <i
          v-if="HasChange"
          class="fa fa-circle icon-background-red font-xl"
        />
      </div>
      <div v-if="value===5">
        <i
          v-if="HasChange"
          class="fa fa-circle icon-background-blue font-xl"
          v-b-tooltip.hover
          :title="description"
        />
      </div>
    </div>
    <div v-if="IsSlot">
      <slot/>
    </div>
    <div v-if="IsCustomButtons">
      <slot></slot>
    </div>
  </td>
</template>
<script>
/* eslint-disable vue/prop-name-casing */
export default {
  name: 'TableColumn',
  data () {
    return {
      obj: null
    }
  },
  props: {
    status: {
      type: Number,
      required: false,
      default: 0
    },
    value: {
      type: String,
      required: false,
      default: 'N/D'
    },
    styles: {
      type: String,
      required: false,
      default: ' '
    },
    classCss: {
      type: String,
      required: false,
      default: 'text-center'
    },
    IsStrong: {
      type: Boolean,
      required: false,
      default: false
    },
    IsText: {
      type: Boolean,
      required: false,
      default: false
    },
    IsDatetime: {
      type: Boolean,
      required: false,
      default: false
    },
    IsDateOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    IsReminder: {
      type: Boolean,
      required: false,
      default: false
    },
    IsStatus: {
      type: Boolean,
      required: false,
      default: false
    },
    IsActionsButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    IsActionsDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    IsLighthouse: {
      type: Boolean,
      required: false,
      default: false
    },
    IsSlot: {
      type: Boolean,
      required: false,
      default: false
    },
    IsCustom: {
      type: Boolean,
      required: false,
      default: false
    },
    IsCustomButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    HasChange: {
      type: Boolean,
      required: false,
      default: true
    },
    HasDelete: {
      type: Boolean,
      required: false,
      default: true
    },
    hours: {
      type: String,
      required: false,
      default: '00:00'
    },
    description: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
