import APIClient from '@/shared/http/clients/api'

const resourceName = 'opportunity'
const APIAccess = process.env.VUE_APP_ONBOARDING

const Create = (data = {}, config = {}) => APIClient.post(`${APIAccess}/${resourceName}`, data, config)
const CheckEmail = (data = {}, config = {}) => APIClient.post(`${APIAccess}/${resourceName}/check/email`, data, config)
const CheckPhone = (data = {}, config = {}) => APIClient.post(`${APIAccess}/${resourceName}/check/phone`, data, config)
const UpdateFromCard = (cardID, data = {}, config = {}) => APIClient.put(`${APIAccess}/${resourceName}/card/${cardID}`,data, config)

const OpportunityRepository = {
  Create,
  CheckEmail,
  CheckPhone,
  UpdateFromCard
}

export default OpportunityRepository
