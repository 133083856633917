import APIClient from '@/shared/http/clients/api'

const resourceName = 'boardcolumn'
const APIAccess = process.env.VUE_APP_ONBOARDING

const GetAll = (config = {}) => APIClient.get(`${APIAccess}/${resourceName}`, config)
const GetAllPaging = (limit, index, config = {}) => APIClient.get(`${APIAccess}/${resourceName}?limit=${limit}&index=${index}`, config)

const BoardColumnRepository = {
  GetAll,
  GetAllPaging
}

export default BoardColumnRepository
