export default {
  contactName: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  },
  contactPhone: {
    value: '',
    maxlength: '20',
    required: true,
    state: null
  },
   companyID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  branchID: {
    value: null,
    options: [],
    clearOption: true,
    required: true,
    state: null
  },
  situation: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  leadOriginID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  responsibleID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  responsibleAppraiserID: {
    value: null,
    options: [],
    required: false,
    state: null
  },
  schedulingStart: {
    value: null,
    required: false,
    state: null
  },
  schedulingEnd: {
    value: null,
    required: false,
    state: null
  },
  hideRead: {
    value: true,
    options: [],
    required: true,
    state: null
  }
}
