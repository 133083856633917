<template>
  <div class="animated fadeIn card p-4 mb-1">
    <div class="form-group row">
      <div class="col-md-12 text-left">
        <h6 class="text-third text-bold"><i class="fas fa-chart-line"></i> INFORMAÇÕES</h6>
      </div>
    </div>
    <div class="form-group row pt-2">
      <div class="col-md-12 d-flex">
        <div class="card-leadinfo">
          <div class="card-leadinfo-header">{{lead.daysInColumn}}</div>
          <div class="card-leadinfo-label">Dias</div>
        </div>
        <div class="card-leadinfo">
          <div class="card-leadinfo-header">{{lead.closingPercentage}}%</div>
          <div class="card-leadinfo-label">Fechamento</div>
        </div>
        <div class="card-leadinfo">
          <div class="card-leadinfo-header">{{lead.totalEmail}}</div>
          <div class="card-leadinfo-label">E-mail</div>
        </div>
        <div class="card-leadinfo">
          <div class="card-leadinfo-header">{{lead.totalCallphone}}</div>
          <div class="card-leadinfo-label">Contatos</div>
        </div>
        <div class="card-leadinfo">
          <div class="card-leadinfo-header">{{lead.totalScheduling}}</div>
          <div class="card-leadinfo-label">Agendamentos</div>
        </div>
      </div>
    </div>
    <div class="form-group row pt-3">
      <div class="col-md-4">
        <label>Agendamento:</label>
        <div class="row">
          <div class="col-md-12 pr-0 mr-0">
            <SaluDigitalDatePicker
              :date.sync="form.scheduling.date"
              :initialHour.sync="form.scheduling.hour"
              :endHour.sync="form.endScheduling.hour"
              v-on:save="onSave('scheduling', $event)"
            />
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <label>Regendamento:</label>
        <div class="row">
          <div class="col-md-12 pr-0 mr-0">
            <SaluDigitalDatePicker
              :date.sync="form.reschedule.date"
              :initialHour.sync="form.reschedule.hour"
              :endHour.sync="form.endReschedule.hour"
              v-on:save="onSave('reschedule', $event)"
            />
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <label>Comparecimento:</label>
        <div class="row">
          <div class="col-md-12 pr-0 mr-0">
            <span class="mr-4">
              <SaluDigitalDatePicker 
                ref="accomplishedDatePicker"
                :date.sync="form.accomplished.date"
                :initialHour.sync="form.accomplished.hour"
                :endHour.sync="form.endAccomplished.hour"
                v-on:save="onSave('accomplished', $event)"
                v-on:closed="onClosedModal"
              />
            </span>
            <span class="pt-2">
              <span class="pr-4" @click="form.accomplishedChecked.value = false">Não</span>
              <b-form-checkbox v-model="form.accomplishedChecked.value" name="check-button" switch>
                Sim
              </b-form-checkbox>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group pt-2 row py-0 my-0">
      <div class="col-auto">
        <b-button class="btn btn-sm btn-third" size="sm" @click="onSave"><i class="fas fa-plus-circle"></i>&nbsp; Gravar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ClientInfo from './clientinfo.js'
export default ClientInfo
</script>

<style scoped>
.card-leadinfo {
  padding: 10px;
  border: solid 1px #d6d6d6;
  border-radius: 5px;
  background-color: #ced8dc;
  width: 113px;
  text-align: center;
  margin-right: 10px;
}
.card-leadinfo-header {
  font-weight: bold;
  font-size: 18px;
}
.card-leadinfo-label {
  font-size: 12px;
}
</style>