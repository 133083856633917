export default {
  scheduling: {
    date: null,
    hour: null,
    required: false,
    state: null
  },
  endScheduling: {
    hour: null,
    required: false,
    state: null
  },
  reschedule: {
    date: null,
    hour: null,
    required: false,
    state: null
  },
  endReschedule: {
    hour: null,
    required: false,
    state: null
  },
  accomplished: {
    date: null,
    hour: null,
    required: false,
    state: null
  },
  endAccomplished: {
    hour: null,
    required: false,
    state: null
  },
  accomplishedChecked: {
    value: null,
    required: false,
    state: null
  }
}