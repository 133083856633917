import Guid from '@/shared/utils/guid.js'

export default {
  FormatNumber (valueNumber, max, precision) {
    if (valueNumber !== null && valueNumber !== '') {
      max = (max !== null && max !== '') ? max : 0
      precision = (precision !== null && precision !== '') ? precision : 0

      var tempNumber = valueNumber.toString().replace(',', '.')
      var valorNegativo = parseFloat(tempNumber) < 0
      if (valorNegativo) {
        tempNumber *= -1
      }
      var valorFinal = (parseFloat(tempNumber)).format(Array(max + 1).join('0') + '.' + Array(precision + 1).join('0'))
      valorFinal = this.FormatNumberToShow(valorFinal)
      if (valorNegativo) {
        valorFinal = '-'.concat(valorFinal)
      }
      return valorFinal
    } else {
      return ''
    }
  },
  FormatToShowDecimal (valueNumber) {
    if (valueNumber) {
      if (valueNumber !== null && valueNumber !== '') {
        var value = valueNumber + ''
        if (value.indexOf('.') > -1 || value.indexOf(',') > -1 || this.IsInt(valueNumber) || this.IsFloat(valueNumber)) {
          var newStringNumber = this.FormataDecimais(valueNumber, 2)
          return newStringNumber
        }
      }
    }
    return valueNumber
  },
  FormatToSaveDecimal (valueNumber) {
    if (valueNumber) {
      if (valueNumber !== null && valueNumber !== '') {
        var value = valueNumber + ''
        value = value.replace(',', '.')
        var newNumber = value * 1
        return newNumber
      }
    }
    return valueNumber
  },
  ValidBrazilianNumber (valueNumber) {
    if (valueNumber && valueNumber !== null) {
      var value = valueNumber + ''
      return (/^\d+(,\d{0,2})?$/).test(value + '')
    }
    return false
  },
  ValidAmericanNumber (valueNumber) {
    if (valueNumber && valueNumber !== null) {
      var value = valueNumber + ''
      return (/^\d+(\.\d{0,2})?$/).test(value + '')
    }
    return false
  },
  FormataDecimais (iValor, iDecimais) {
    if (iValor !== '' && iValor !== null) {
      var sValor = String(iValor)
      sValor = sValor.replace('.', ',')
      var iLen = sValor.length - 1
      var iPosPonto = sValor.indexOf(',')
      var iInd = 0
      var iLenDecimais = 0
      if (iPosPonto > 0) {
        iLenDecimais = (iLen - iPosPonto)
        iInd = iLenDecimais
      } else {
        if (iDecimais > 0) {
          sValor = sValor.concat(',')
        }
        iInd = 0
      }
      while (iInd < iDecimais) {
        sValor = sValor.concat('0')
        iInd = iInd + 1
      }
      return sValor
    } else {
      return ''
    }
  },
  Left (str, n) {
    var retorno = ''
    if (n <= 0) {
      retorno = ''
    } else if (n > String(str).length) {
      retorno = str
    } else {
      retorno = String(str).substring(0, n)
    }
    return retorno
  },
  Right (str, n) {
    var retorno = ''
    if (n <= 0) {
      retorno = ''
    } else if (n > String(str).length) {
      retorno = str
    } else {
      var iLen = String(str).length
      retorno = String(str).substring(iLen, iLen - n)
    }
    return retorno
  },
  IsInt (n) {
    return Number(n) === n && n % 1 === 0
  },
  IsFloat (n) {
    return Number(n) === n && n % 1 !== 0
  },
  FormatCode: function (code) {
    var value = code.toString()
    var zero = '0'
    var result = ''
    console.log(value + ' Size: ' + value.length)

    for (var i = value.length; i < 6; i++) {
      result = result + zero
    }
    return result + value
  },
  MinimizedSidebar: function () {
    if (!document.body.classList.contains('sidebar-minimized')) {
      document.body.classList.add('sidebar-minimized')
    }
    document.body.classList.remove('sidebar-mobile-show')
    window.scrollTo(0, 0)
  },
  MinimizedASidebar: function () {
    document.body.classList.remove('aside-menu-fixed')
    if (!document.body.classList.contains('aside-menu-hidden')) {
      document.body.classList.add('aside-menu-hidden')
    }
    window.scrollTo(0, 0)
  },
  ValidateForms: function (forms, data, self, toast) {
    var result = true

    for (var opt in forms) {
      if (forms[opt].hasOwnProperty('required') && forms[opt].required === true) {
        if (forms[opt].value === null || forms[opt].value === '') {
          // self.$refs.alert.ShowAlert('warning', self.$lang.Common.Alerts.RequiredField)
          toast.warning(self.$lang.Common.Alerts.RequiredField)
          forms[opt].state = false // 'invalid'
          // -->
          console.log('Required: ' + opt)
          setTimeout(function (e) {
            forms[opt].state = null
          }, 7000)
          return false
        }// else forms[opt].state = true
      }
    }
    this.SetValueToSave(forms, data)

    return result
  },
  ValidateComplexForms (forms, data, toast, self) {
    let action = (forms, opt) => {
      if (forms[opt].hasOwnProperty('required') && forms[opt].required === true) {
        if (forms[opt].value === null || forms[opt].value === '' || (Array.isArray(forms[opt].value) && forms[opt].value.length === 0)) {
          toast.warning(self.$lang.Common.Alerts.RequiredField)
          forms[opt].state = false

          console.log('Required: ' + opt)
          setTimeout(function (e) {
            forms[opt].state = null
          }, 7000)
          return false
        }
      }
    }

    if (this.onRunForms(forms, action) !== false) {
      this.SetComplexValueToSave(forms, data)
      return true
    } else return false
  },
  ValidateSubForms (forms, toast, self) {
    let action = (forms, opt) => {
      if (forms[opt].hasOwnProperty('required') && forms[opt].required === true) {
        if (forms[opt].value === null || forms[opt].value === '' || (Array.isArray(forms[opt].value) && forms[opt].value.length === 0)) {
          toast.warning(self.$lang.Common.Alerts.RequiredField)
          forms[opt].state = false

          console.log('Required: ' + opt)
          setTimeout(function (e) {
            forms[opt].state = null
          }, 7000)
          return false
        }
      }
    }

    return this.onRunForms(forms, action)
  },
  ClearDataForms: function (forms, data) {
    for (var opt in forms) {
      if (forms[opt].hasOwnProperty('defaultArray')) {
        forms[opt].value = []
      } else {
        forms[opt].value = null
      }

      if (forms[opt].hasOwnProperty('clearOption')) {
        if (forms[opt].clearOption) {
          forms[opt].options = []
        }
      }
    }

    for (var property in data) {
      data[property] = null
    }
  },
  SetValueInForm: function (forms, data, obj) {
    for (var key in data) {
      if (obj[key] !== '00000000-0000-0000-0000-000000000000') {
        if (obj.hasOwnProperty(key)) {
          data[key] = obj[key]
          if (this.ValidBrazilianNumber(obj[key]) || this.ValidAmericanNumber(obj[key])) {
            data[key] = this.FormatToShowDecimal(obj[key])
          }
        }
        if (forms.hasOwnProperty(key)) {
          forms[key].value = obj[key]
          if (this.ValidBrazilianNumber(obj[key]) || this.ValidAmericanNumber(obj[key])) {
            forms[key].value = this.FormatToShowDecimal(obj[key])
          }
        }
      }
    }
  },
  SetValueInSubForm: function (forms, data, obj) {
    for (var key in data) {
      if (obj[key] !== Guid.empty) {
        if (obj.hasOwnProperty(key)) {
          data[key] = obj[key]
        }
        if (forms.hasOwnProperty(key)) {
          forms[key].value = obj[key]
        }
      }
    }
  },
  SetComplexValueInForm: function (forms, data, obj, listObj) {
    let action = (data, key, obj, forms) => {
      if (obj !== null && obj !== undefined) {
        if (obj[key] !== Guid.empty) {
          if (obj.hasOwnProperty(key)) {
            data[key] = Array.isArray(obj[key]) ? JSON.parse(JSON.stringify(obj[key])) : obj[key]
          }
          if (forms.hasOwnProperty(key)) {
            forms[key].value = Array.isArray(obj[key]) ? JSON.parse(JSON.stringify(obj[key])) : obj[key]
            if (forms[key].hasOwnProperty('tab')) {
              forms[key].tab = listObj[obj[key]].type
            }
          }
        }
      } else {
        if (forms !== undefined && forms[key] !== undefined) {
          if (forms[key].hasOwnProperty('required')) {
            forms[key].required = false
          }
          if (forms[key].hasOwnProperty('value')) {
            forms[key].value = null
          }
        }
      }
    }

    this.onRunData(data, action, obj, forms)
  },
  SetFormDefaultValues: function (form) {
    for (var key in form) {
      if (form[key].defaultValue !== null && form[key].defaultValue !== undefined) {
        form[key].value = form[key].defaultValue
      } else {
        form[key].value = null // undefined
      }
    }
  },
  SetValueToSave: function (forms, data) {
    for (var key in forms) {
      if (data.hasOwnProperty(key) && key !== 'mainObj') {
        if (forms[key].hasOwnProperty('defaultArray')) {
          var options = forms[key].options
          data[key] = []
          for (var i in options) {
            data[key].push(options[i].value)
          }
        } else {
          data[key] = forms[key].value
          if (this.ValidBrazilianNumber(forms[key].value) || this.ValidAmericanNumber(forms[key].value)) {
            data[key] = this.FormatToSaveDecimal(forms[key].value)
          }
        }
      }
    }
  },
  SetComplexValueToSave: function (forms, data) {
    let action = (forms, key, subKey, data) => {
      if (data !== undefined) {
        if (data.hasOwnProperty(key) && key !== 'mainObj') {
          if (forms[key].hasOwnProperty('defaultArray')) {
            var options = forms[key].options
            data[key] = []
            for (var i in options) {
              data[key].push(options[i].value)
            }
          } else if (forms[key].hasOwnProperty('boolean')) {
            if (forms[key].value === null) forms[key].value = false
            data[key] = forms[key].value
          } else {
            data[key] = forms[key].value
            if (this.ValidBrazilianNumber(forms[key].value) || this.ValidAmericanNumber(forms[key].value)) {
              data[key] = this.FormatToSaveDecimal(forms[key].value)
            }
          }
        }
      }
    }

    this.onRunForms(forms, action, null, data)
  },
  IsValid: function (obj) {
    var result = false
    if (obj != null && obj !== '' && obj.length > 0) {
      this.result = true
    }
    return result
  },
  removeItemArray: function (array, value) {
    for (var i in array) {
      if (array[i].value === value) {
        array.splice(i, 1)
      }
    }
    return array
  },
  formatMoneyBR (value) {
    let val = (value / 1).toFixed(2).replace('.', ',')
    return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  },
  groupBy (key) {
    return (array) => {
      return array.reduce((acc, obj) => {
        const property = obj[key]
        acc[property] = acc[property] || []
        acc[property].push(obj)
        return acc
      }, {})
    }
  },
  /** @description Percorre o objeto do Formulário */
  onRunForms (forms, action, subKey, data) {
    let result
    for (let opt in forms) {
      if (forms[opt] !== null && typeof (forms[opt]) === 'object') {
        if ((typeof (forms[opt].value) !== 'object' && forms[opt].value !== undefined) || (Array.isArray(forms[opt].value) && !forms[opt].hasOwnProperty('subform')) || forms[opt].value === null) {
          result = action(forms, opt, subKey, data)
        } else {
          if (data !== undefined) {
            result = this.onRunForms(forms[opt], action, opt, data[opt])
          } else {
            result = this.onRunForms(forms[opt], action, opt)
          }
        }
        if (result !== undefined) {
          return result
        }
      }
    }
  },
  /** @description Percorre o objeto Data */
  onRunData (data, action, obj, forms, subKey) {
    let result
    for (let property in data) {
      if (data[property] !== null && typeof (data[property]) === 'object' && !Array.isArray(data[property])) {
        if (obj && forms) {
          result = this.onRunData(data[property], action, obj[property], forms[property], property, subKey)
        } else {
          result = this.onRunData(data[property], action)
        }
      } else {
        result = action(data, property, obj, forms, subKey)
      }

      if (result !== undefined) {
        return result
      }
    }
  },
  /** @description Seta o valor no objeto TAB */
  onSetTab (obj, listObj) {
    for (let key in listObj) {
      let list = listObj[key]
      if (list.type === obj.tab) {
        obj.value = list.value
      }
    }
  },
  /** @description Seta TAB e define o que é Obrigatório ou não */
  onTabChange (form, listObj) {
    this.onSetTab(form.component.type, listObj)
    let action = (forms, key, opt) => {
      let component = form.component.type.value
      if (opt === component) {
        if (forms[key].hasOwnProperty('required')) {
          forms[key].required = true
        }
      } else if (listObj[component] !== undefined && listObj[component].hasOwnProperty('subComponents') && listObj[component].subComponents.hasOwnProperty(opt)) {
        let subComponent = listObj[component].subComponents
        if (forms.hasOwnProperty('mainObj') && forms.mainObj !== component) {
          forms[key].required = false
        } else if (subComponent[opt].required.includes(key)) {
          forms[key].required = true
        } else {
          forms[key].required = false
        }
      } else {
        if (forms[key].hasOwnProperty('required')) {
          forms[key].required = false
        }
      }
    }

    this.onRunForms(form.component, action)
  },
  /** @description Copia o objeto DATA e seta como nulo objetos que não faz parte da lista de objetos */
  onCopyData (data, listObj) {
    let result = JSON.parse(JSON.stringify(data))

    for (let key in listObj) {
      let obj = listObj[key]
      if (obj.value !== result.component.type) {
        result.component[obj.value] = null
      }
    }
    return result
  },
  onClearForms (forms) {
    let action = (forms, opt) => {
      if (forms[opt].hasOwnProperty('defaultArray')) {
        forms[opt].value = []
      } else if (forms[opt].hasOwnProperty('boolean')) {
        forms[opt].value = false
      } else {
        forms[opt].value = null
      }

      if (forms[opt].hasOwnProperty('clearOption')) {
        if (forms[opt].clearOption) {
          forms[opt].options = []
        }
      }
    }

    this.onRunForms(forms, action)
  },
  onClearData (data) {
    let action = function (data, property) {
      data[property] = null
    }

    this.onRunData(data, action)
  },
  setCreateID (data) {
    let action = (data, key) => {
      if (key === 'id' || key.includes('ID')) {
        if (data[key] === null || (data[key] && data[key].includes(Guid.empty))) {
          data[key] = Guid.empty
        }
      } else if (Array.isArray(data[key]) && data[key].length > 0) {
        data[key].map(obj => {
          if (obj.hasOwnProperty('id')) {
            this.setCreateID(obj)
          }
        })
      }
    }

    this.onRunData(data, action)
  },
  setGuid (data) {
    let action = (data, key) => {
      if (key === 'id' || key.includes('ID')) {
        if (data[key] === null) {
          data[key] = `${Guid.empty}_${Guid.new()}`
        }
      }
    }

    this.onRunData(data, action)
  },
  /**
   * Capitalizes first letters of words in string.
   * @param {string} str String to be modified
   * @param {boolean=false} lower Whether all other letters should be lowercased
   * @return {string}
   * @usage
   *   capitalize('fix this string');     // -> 'Fix This String'
   *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
   *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
  */
  capitalize: function (str, lower = false) {
    return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase())
  },
  GetResponse: function (response) {
    var result = null
    if (response && response.data.code >= 0) {
      result = response.data.data
    }
    return result
  },
  sleep (ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  },
  AuthorizeNotification () {
    // https://developer.mozilla.org/en-US/docs/Web/API/Notification
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      // alert('This browser does not support desktop notification')
    } else {
      Notification.requestPermission(function (perm) {
      })
    }
  },
  ShowNotification (title, message) {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      // alert('This browser does not support desktop notification')
    } else {
      if (Notification.permission === 'granted') {
        var notification = new Notification(title, {
          dir: 'auto',
          lang: '',
          body: message,
          tag: 'Saludigital',
          ico: 'https://app.saludigital.com.br/static/img/logotipo/favicon.png'
        })
        notification.onclick = () => {
          window.open(window.location.href)
        }
      } else {
        this.AuthorizeNotification()
      }
    }
  },
}
