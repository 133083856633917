import Tools from '@/components/tools/Tools.js'
import ActionsForm from '@/shared/mixins/actionsForm'
import { toast, loading } from '@/shared/utils/UIHelper'

import BranchRepository from '@/shared/http/repositories/settings/branch'
import GroupRepository from '@/shared/http/repositories/access/group'
import AccountRepository from '@/shared/http/repositories/access/account'
import AccountModel from '@/shared/models/access/accountmodel'
import AccountForms from './account-form'

const lodash = require("lodash"); 

export default {
  name: 'Account',
  mixins: [ActionsForm],
  data () {
    return {
      data: AccountModel,
      form: AccountForms,
      branch: null
    }
  },
  created () {
    this.onClear()
  },
  mounted () {
    this.onLoadAllGroup()
    this.onLoadAllBranch()
  },
  watch: {
    'form.tagsBranch.value': function (value, old) {
      if(value) { 
        this.reloadOptions()
        this.form.tagsBranch.options.forEach(item => {
          if(!value.find(e => e == item.text)) {
            if(!this.form.branchID.options.find(opt => opt.value == item.value))
            this.form.branchID.options.push(item)
          }          
        })            
      }

      if(this.form.branchID.options) {
        let optSorted = lodash.sortBy(this.form.branchID.options, 'text')
        this.form.branchID.options = optSorted
      }
    }
  },
  computed: {
    FieldSizeTip: function () {
      return this.$lang.Common.Tips.MaximumSize.replace('@SIZE@', this.form.description.maxlength)
    }
  },
  methods: {
    onLoad () {
      loading.push()
      AccountRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then((response) => {
          this.onResultGrid(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onEdit (obj) {
      loading.push()
      Tools.SetValueInForm(this.form, this.data, obj)
      this.form.password.value = null
      this.onSetBarnchToEdit(obj.listBranch)
      loading.pop()
    },
    onCreate () {
      loading.push()
      if (this.data.password) {
        this.data.password = this.data.password.toString()
      }
      if(this.form.tagsBranch.value) {
        this.onSetBranchToSave()
      }
      AccountRepository.Create(this.data)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onUpdate () {
      loading.push()
      if (this.data.password) {
        this.data.password = this.data.password.toString()
      } else { this.data.password = '' }

      if(this.form.tagsBranch.value) {
        this.onSetBranchToSave()
      }
      
      AccountRepository.Update(this.data)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onDelete (obj) {
      loading.push()
      AccountRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onLoadAllGroup () {
      loading.push()
      GroupRepository.GetAll()
        .then((response) => {
          this.form.groupID.options = this.onResultDropdown(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onCheckEmail () {
      var email = this.form.email.value
      loading.push()
      AccountRepository.GetByEmail(email)
        .then((response) => {
          if (response) {
            if (response.data.data) {
              var obj = response.data.data
              if (obj) {
                toast.warning(this.$lang.Accounts.AlertEmailExisted)
              }
            }
          }
          loading.pop()
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onLoadAllBranch () {
      this.form.tagsBranch.options = []
      loading.push()
      BranchRepository.GetAll()
        .then((response) => {
          this.form.branchID.options = this.onResultDropdown(response)
          this.form.tagsBranch.options = this.onResultDropdown(response)
          this.branch = this.onResultDropdown(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onChangeBranch(value) {
      if(value) {
        let i = 0
        if(!this.form.tagsBranch.value) {
          this.form.tagsBranch.value = []
        }
        this.form.branchID.options.forEach(element => {
          if (element.value == value) {
            this.form.tagsBranch.value.push(this.form.branchID.options[i].text)
            this.form.branchID.options.splice(i, 1);
            this.form.branchID.value = null
          }
          i++
        })
      }
      this.reloadOptions()
    },
    onSetBranchToSave () {
      this.data.listBranch = []
      this.form.tagsBranch.value.forEach(item => {
        let opt = this.form.tagsBranch.options.find(e => e.text == item)
        if(opt) {
          this.data.listBranch.push(opt)
        }          
      })
    },
    onSetBarnchToEdit(listBranch) {      
      this.form.tagsBranch.value = []
      this.form.branchID.value = null
      this.form.branchID.options = this.form.tagsBranch.options
      
      if (listBranch && listBranch.length > 0) {
        listBranch.forEach(item => {
          this.form.tagsBranch.value.push(item.text)
          let i = 0
          this.form.branchID.options.forEach(opt => {
            if(opt.value === item.value) {
              this.form.branchID.options.splice(i, 1);
            }
            i++
          })
        });
      }
      this.reloadOptions()
    },
    reloadOptions () {
      this.form.tagsBranch.options = []
      if(this.branch){
        this.branch.forEach(item => {
          this.form.tagsBranch.options.push(item)
        })
      }
    }
  }
}
