import Vue from 'vue'
import Vuex from 'vuex'
import AppModules from './module'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: AppModules
})

export default store
