<template>
  <div class="animated fadeIn kanban-main-container">   
    <b-navbar toggleable="lg" type="light" variant="light" class="fidex-bar">
      <b-navbar-brand href="javascript:void(0)" class="pl-3"><span class="text-third"><i class="fas fa-chart-line"></i>&nbsp; Dashboard</span></b-navbar-brand>
      <b-navbar-nav class="ml-auto mr-5">
        <b-nav-form>
          <label class="pr-2">Período</label>
        </b-nav-form>
        <b-nav-form>
          <b-form-datepicker v-model="form.start.value" 
                             locale="pt-BR" 
                             size="sm"
                             :placeholder="$lang.Common.DatePlaceholder"
                             :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" 
                             dropleft
                             calendar-width="auto"
                             :hideHeader="true"
          ></b-form-datepicker>
        </b-nav-form>
        <b-nav-form>
          <label class="pl-2 pr-2 text-center"> à </label>
        </b-nav-form>
        <b-nav-form>
          <b-form-datepicker v-model="form.end.value" 
                             locale="pt-BR" 
                             size="sm"
                             :max="maxDate"
                             :placeholder="$lang.Common.DatePlaceholder"
                             :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                             dropleft
                             calendar-width="auto"
                             :hideHeader="true"
          ></b-form-datepicker>
        </b-nav-form>
        <b-nav-form class="pl-3 pr-2">
          <a href="javascript:void(0)" @click="onSearch"><i class="fas fa-filter f-18px" v-b-tooltip.hover title="Aplicar"></i></a> &nbsp;&nbsp;&nbsp;
          <a href="javascript:void(0)" @click="onShowSearch" class="pr-3"><i class="fas fa-search-plus f-20px" v-b-tooltip.hover title="Pesquisa Avançada" v-if="true"></i></a>
        </b-nav-form>
      </b-navbar-nav>
    </b-navbar>

    <div class="container-fluid pt-5">
      <div class="row pt-3">
        <div class="col-12 text-center">
          <b-card-group deck>
            <b-card class="text-center pb-2" style="max-width: 20%;" >
              <span style="font-size: 68px">{{totalLead}}</span>
              <h4 class="text-default"><i class="fas fa-users text-third"/> Leads</h4>
              <div>Total de novos leads</div>
            </b-card>

            <b-card class="text-center pb-2" style="max-width: 20%">
              <span style="font-size: 68px">{{totalScheduled}}</span>
              <h4 class="text-default"><i class="fas fa-user-clock text-third"/> Agendados</h4>
              <div>Total de leads agendado</div>
            </b-card>

            <b-card class="text-center pb-2" style="max-width: 20%">
              <span style="font-size: 69px">{{totalAccomplished}}</span>
              <h4 class="text-default"><i class="fas fa-house-user text-third"/> Compareceu</h4>
              <div>Total de comparecimento</div>
            </b-card>

            <b-card class="text-center pb-2" style="max-width: 20%">
              <span class="text-success" style="font-size: 68px">{{totalLeadGain}}</span>
              <h4 class="text-default"><i class="fas fa-user-check text-third"/> Ganhou</h4>
              <div>Total de clientes convertidos</div>
            </b-card>

            <b-card class="text-center pb-2" style="max-width: 20%">
              <span class="text-third" style="font-size: 30px;margin-top: 28px;display: block;"><strong>{{formatValue(totalRevenues + totalBudgetRevenues)}}</strong></span>
              <h4 class="text-default pt-4"><i class="fas fa-coins text-third"/> Receita</h4>
              <div>Total de receita no período</div>
            </b-card>
          </b-card-group>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-12 text-center">
          <b-card-group deck>
            <b-card class="text-left pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-default"><i class="fas fa-chart-bar text-third"></i> Listagem de  Clientes</h5>
                <div>
                  <b-button v-if="false"
                            class="btn mr-1"
                            variant="outline-primary"
                            style="border-radius: 4px;"
                            :class="totalNewLeadDaysGroup === 'byType' ? 'btn-third' : ''"
                            @click="totalNewLeadDaysGroup = 'byType'"
                  >
                    <i class="fas fa-business-time"></i> Diário
                  </b-button>
                  <b-button v-if="false"
                            class="btn ml-1"
                            variant="outline-primary"
                            style="border-radius: 4px;"
                            :class="totalNewLeadDaysGroup === 'byTotal' ? 'btn-third' : ''"
                            @click="totalNewLeadDaysGroup = 'byTotal'"
                  >
                    <i class="fab fa-buffer"></i> Acumulado
                  </b-button>
                </div>
              </div>
              <div class="pt-3">
                <ChatLine
                  ref="ChatLine"
                  :groupBy="totalNewLeadDaysGroup"
                />
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-12 text-center">
          <b-card-group deck>
            <b-card class="text-left pb-2">
              <h5 class="text-default"><i class="fas fa-chart-bar text-third"></i> Agendamentos por Origem</h5>
              <div class="pt-3 text-center">
                <ChatDoughnutShedulesOrigin ref="ChatDoughnutShedulesOrigin" v-if="visible"/>
                <b-spinner variant="info" style="width: 15rem; height: 15rem; margin-top: 50px;" v-else />
              </div>
            </b-card>
            <b-card class="text-left pb-2">
              <h5 class="text-default"><i class="fas fa-chart-bar text-third"></i> Ganhos por Origem</h5>
              <div class="pt-3 text-center">
                <ChatDoughnutGainOrigin ref="ChatDoughnutGainOrigin" v-if="visible"/>
                <b-spinner variant="info" style="width: 15rem; height: 15rem; margin-top: 50px;" v-else />
              </div>
            </b-card>
            <b-card class="text-left pb-2">
              <h5 class="text-default"><i class="fas fa-chart-bar text-third"></i> Motivo de Perdas</h5>
              <div class="pt-3 text-center">
                <ChatDoughnutReasonLoss ref="ChatDoughnutReasonLoss" v-if="visible" />
                <b-spinner variant="info" style="width: 15rem; height: 15rem; margin-top: 50px;" v-else />
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
      <div class="row pt-3 d-none">
        <div class="col-12 text-center">
          <b-card-group deck>
            <b-card class="text-left pb-2">
              <h5 class="text-default"><i class="fas fa-chart-bar text-third"></i> Fluxo de Clientes por Produto</h5>
              <div class="pt-3">
                <ChatBar />
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
      <div class="row pt-3 pb-3">
        <div class="col-12 text-center">
          <b-card-group deck>
            <b-card class="text-left pb-2">
              <h5 class="text-default"><i class="fas fa-th-list text-third"></i> Listagem de Clientes por Situação</h5>
              <b-tabs class="pt-3" v-model="tabIndex">
                <b-tab :title="'Ganhos'" @click="onLoadGain">
                  <template slot="title">
                    <div><i class="fas fa-user-check text-third"></i><span class="d-md-down-none pl-1 text-third"> Ganhos</span></div>
                  </template>
                  <GainList ref="GainList" />
                </b-tab>
                <b-tab :title="'Perdidos'" @click="onLoadLost">
                  <template slot="title">
                    <i class="fas fa-user-slash text-third"></i><span class="d-md-down-none pl-1 text-third"> Perdidos</span>
                  </template>
                  <LostList ref="LostList"/>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { loading, toast } from '@/shared/utils/UIHelper'
import Tools from '@/components/tools/Tools'
import DashboardForm from '@/views/private/dashboard/search/dashboardsearch.vue'
import DashboardRepository from '@/shared/http/repositories/report/dashboard.js'
import GainList from './gain/gainlist.vue'
import LostList from './lost/lostlist.vue'
import ChatLine from './chart/lineLeadsNewAndReactivated.vue'
import ChatBar from './chart/bar.vue'
import ChatDoughnutShedulesOrigin from './chart/doughnutShedulesOrigin.vue'
import ChatDoughnutGainOrigin from './chart/doughnutGainOrigin.vue'
import ChatDoughnutReasonLoss from './chart/doughnutReasonLoss.vue'

export default {
  name: 'CRMReport',
  data() {
    return {
      tabIndex: 0,
      visible: true,
      filters: null,
      leadCreated: [],
      leadScheduled: [],
      leadAccomplished: [],
      leadGain: [],
      revenues: [],
      budgetRevenues: [],
      totalNewLeadDaysGroup: 'byType',
      startDate: this.moment().format('YYYY-MM-') + '01T00:00:00',
      endDate: this.moment().format('YYYY-MM-DDThh:mm:ss'),
      maxDate: this.moment().format('YYYY-MM-DDThh:mm:ss'),
      dateTime: {
        date: null,
        hour: null
      },
      form: {
        start: {
          value: null
        },
        end: {
          value: null
        },
        leadOriginID: {
          value: null,
          options: [],
          required: true,
          state: null
        }
      }
    }
  },
  components: {
    GainList,
    LostList,
    ChatLine,
    ChatBar,
    ChatDoughnutShedulesOrigin,
    ChatDoughnutGainOrigin,
    ChatDoughnutReasonLoss
  },
  beforeDestroy () {
    this.$eventHub.off('Filter')
    Tools.MinimizedASidebar()
  },
  computed: {
    totalLead: function () {
      var result = 0
      if (this.leadCreated != null && this.leadCreated.length > 0) {
        for (var i in this.leadCreated) {
          result += this.leadCreated[i].total
        }
      }
      return result
    },
    totalLeadGain: function () {
      var result = 0
      if (this.leadGain != null && this.leadGain.length > 0) {
        for (var i in this.leadGain) {
          result += this.leadGain[i].total
        }
      }
      return result
    },
    totalScheduled: function () {
      var result = 0
      if (this.leadScheduled != null && this.leadScheduled.length > 0) {
        for (var i in this.leadScheduled) {
          result += this.leadScheduled[i].total
        }
      }
      return result
    },
    totalAccomplished: function () {
      var result = 0
      if (this.leadAccomplished != null && this.leadAccomplished.length > 0) {
        for (var i in this.leadAccomplished) {
          result += this.leadAccomplished[i].total
        }
      }
      return result
    },
    totalRevenues: function () {
      var result = 0.00
      this.revenues.forEach(element => {
        result += element.total
      })
      return result
    },
    totalBudgetRevenues: function () {
      var result = 0.00
      this.budgetRevenues.forEach(element => {
        result += element.total
      })
      return result
    }
  },
  mounted () {
    this.onSetLoad()
    this.onLoadDashboard()
    this.$eventHub.on('Filter', ({ filters }) => {
      this.filters = filters
      this.$nextTick(() => {
        this.onSearch()
      })
    })
  },
  methods: {
    onLoad () {
      let bci = document.getElementsByClassName("b-calendar-inner")
      if (bci) {
        bci.style.width = 'auto'
      }
    },
    onShowSearch () {
      let aside = {
        title: 'Pesquisar',
        self: this,
        component: DashboardForm
      }
      this.$eventHub.emit('Search', aside)
    },
    toCurrency (number) {
      return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(number)
    },
    onReturnBoard () {
      this.$router.push({ name: 'Kanban' })
    },
    onSearch () {
      this.startDate = this.moment(this.form.start.value).format('YYYY-MM-DD') + 'T00:00:00'
      this.endDate = this.moment(this.form.end.value).format('YYYY-MM-DD') + 'T23:59:59'
      // Force Destroy to new RenderChar()
      this.visible = false
      setTimeout(() => {
        this.visible = true
      }, 100);
      setTimeout(() => {
        this.onLoadDashboard()
      }, 300);
    },
    onSetLoad () {
      this.form.start.value = this.startDate
      this.form.end.value = this.endDate
    },
    onGetFilter () {
      let search = {
        dateStart: this.startDate,
        dateEnd: this.endDate,
        companyID: this.filters ? this.filters.companyID : null,
        branchID: this.filters ? this.filters.branchID : null,
        leadOriginID: this.filters ? this.filters.leadOriginID : null,
        managerID: this.filters ? this.filters.managerID : null,
        responsibleAppraiserID: this.filters ? this.filters.responsibleAppraiserID : null,
      }
      return search
    },
    onLoadDashboard () {
      let search = this.onGetFilter()
      this.onLoadTotalLeadCreated()
      this.onLoadTotalScheduled()
      this.onLoadTotalAccomplished()
      this.onLoadTotalLeadGain()
      this.onLoadTotalRevenues()
      this.onLoadTotalBudgetRevenues()
      this.$refs.ChatLine.onLoadListLeads(search)
      this.$refs.ChatDoughnutShedulesOrigin.onLoadTotalSchedulingOrigin(search)
      this.$refs.ChatDoughnutGainOrigin.onLoadTotalGainOrigin(search)
      this.$refs.ChatDoughnutReasonLoss.onLoadTotalReasonLoss(search)
      this.onLoadGain()
      this.onLoadLost()
    },
    async onLoadTotalLeadCreated () {
      loading.push()
      let search = this.onGetFilter()
      await DashboardRepository.GetTotalLeadCreated(search)
        .then(res => {
          this.leadCreated = res.data.data
          loading.pop()
        })
        .catch(er => {
          console.log(er)
          loading.pop()
        })
    },
    async onLoadTotalLeadGain () {
      loading.push()
      let search = this.onGetFilter()
      await DashboardRepository.GetTotalLeadGain(search)
        .then(res => {
          this.leadGain = res.data.data
          loading.pop()
        })
        .catch(er => {
          console.log(er)
          loading.pop()
        })
    },
    async onLoadTotalAccomplished () {
      loading.push()
      let search = this.onGetFilter()
      await DashboardRepository.GetTotalAccomplished(search)
        .then(res => {
          this.leadAccomplished = res.data.data
          loading.pop()
        })
        .catch(er => {
          console.log(er)
          loading.pop()
        })
    },
    async onLoadTotalScheduled () {
      loading.push()
      let search = this.onGetFilter()
      await DashboardRepository.GetTotalScheduled(search)
        .then(res => {
          this.leadScheduled = res.data.data
          loading.pop()
        })
        .catch(er => {
          console.log(er)
          loading.pop()
        })
    },
    async onLoadTotalRevenues () {
      loading.push()
      let search = this.onGetFilter()
      await DashboardRepository.GetTotalRevenues(search)
        .then(res => {
          this.revenues = res.data.data
          loading.pop()
        })
        .catch(er => {
          console.log(er)
          loading.pop()
        })
    },
    async onLoadTotalBudgetRevenues () {
      loading.push()
      let search = this.onGetFilter()
      await DashboardRepository.GetTotalBudgetRevenue(search)
        .then(res => {
          this.budgetRevenues = res.data.data
          loading.pop()
        })
        .catch(er => {
          console.log(er)
          loading.pop()
        })
    },
    onLoadGain () {
      let search = this.onGetFilter()
      this.$refs.GainList.onLoadLeadGain(search)
    },
    onLoadLost () {
      let search = this.onGetFilter()
      this.$refs.LostList.onLoadLeadLost(search)
    },
    formatValue(value) {
      return Tools.formatMoneyBR(value)
    },
    onSelectDate (value, target) {
      /*
      if (target === 'startDate') {
        value.setHours(0, 0, 0, 0)
        this.form[target].value = value
      } else if (target === 'endDate') {
        value.setHours(23, 59, 59, 59)
        this.form[target].value = value
      }
      // if (this.form.startDate.value && this.form.endDate.value) this.onChangeDates()
      */
    }
  }
}
</script>
<style scoped>
.card {
  border-radius: 11px;
  box-shadow: 0 0 1em #ccc;
}
h4 {
  font-weight: bold;
}
.f-18px {
  font-size: 18px;
}
.f-20px {
  font-size: 20px;
}
.filter-bar {
  min-height: 70px;
  width: 100%;
  background-color: #fff;
}
.fidex-bar { 
  position: fixed;
  width: 100%;
  z-index: 99999;
  border-bottom: solid 1px #e9e9e9;
}

.custom-select {
  font-size: 14px;
}
</style>
