<script>
import { loading } from '@/shared/utils/UIHelper'
import DashboardRepository from '@/shared/http/repositories/report/dashboard.js'
import { Doughnut } from 'vue-chartjs'
const colors = [
  '#03045e',
  '#023e8a',
  '#0077b6',
  '#0096c7',
  '#00b4d8',
  '#48cae4',
  '#90e0ef',
  '#90e0ef',
  '#ade8f4',
  '#caf0f8',
  '#033270',
  '#1368aa',
  '#4091c9',
  '#9dcee2',
  '#fedfd4',
  '#f29479',
  '#f26a4f',
  '#ef3c2d',
  '#cb1b16',
  '#65010c'
]

export default Doughnut.extend({
  data() {
    return {
      labels: [],
      dataTotal: [],
      backgroundColor: [],
      chartOptions: {
        responsive: true
      },
      filter: null,
    }
  },
  updated  () {
    // this.onRenderChat ()
    this.onLoadTotalReasonLoss(this.filter)
  },
  methods: {
    async onLoadTotalReasonLoss (search) {
      loading.push()
      this.labels = []
      this.dataTotal = []   
      this.filter = search   
      await DashboardRepository.GetTotalReasonLoss(search)
        .then(res => {
          let reasonLoss = res.data.data
          if (res.data.code === 200 && reasonLoss.length > 0) {    
            // --> Soma reason igual dos período
            const reasonLossTotal = Array.from(reasonLoss.reduce(
              (m, {reason, total}) => m.set(reason, (m.get(reason) || 0) + total), new Map
            ), ([reason, total]) => ({reason, total}))

            let i = 0
            reasonLossTotal.forEach(element => {
              this.labels.push(element.reason)
              this.dataTotal.push(element.total)
              this.backgroundColor.push(colors[i])
              i++
            })     

          } else {
            this.labels.push('Não ha dados no período')
            this.dataTotal.push(1)
            this.backgroundColor.push(colors[0])
          }
          this.onRenderChat()
          loading.pop()
        })
        .catch(er => {
          console.log(er)
          loading.pop()
        })
    },
    onRenderChat () {
      this.renderChart({
        labels: this.labels,
        datasets: [
          {
            backgroundColor: this.backgroundColor,
            data: this.dataTotal
          }
        ]
      }, {responsive: true, maintainAspectRatio: false})
    }
  }
})
</script>
