<template>
  <div class="animated fadeIn">
    <div class="mt-2 card p-4 mb-2">
      <div class="form-group row">
        <div class="col-md-12 text-left">
          <h6 class="text-third text-bold"><i class="fas fa-user-tie"></i> RESPONSÁVEL PELO LEAD</h6>
          <div class="input-group pt-2">
            <b-form-select
              v-model="form.managerID.value"
              :options="form.managerID.options"
              :required="form.managerID.required"
              :state="form.managerID.state"
              class="mb-3"
              @change="onSave"
            >
              <template slot="first">
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-12 text-left">
          <h6 class="text-third text-bold"><i class="fas fa-user-check"></i> AVALIADOR RESPONSÁVEL</h6>
          <div class="input-group pt-2">
            <b-form-select
              v-model="form.responsibleAppraiserID.value"
              :options="form.responsibleAppraiserID.options"
              :required="form.responsibleAppraiserID.required"
              :state="form.responsibleAppraiserID.state"
              class="mb-3"
              @change="onSave"
            >
              <template slot="first">
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ActionForms from '@/shared/mixins/actionsForm.js'
import Tools from '@/components/tools/Tools.js'
import { toast, loading } from '@/shared/utils/UIHelper'
import LeadOwnerForm from './leadowner-form.js'

import AccountRepository from '@/shared/http/repositories/access/account.js'
import CardDetailRepository from '@/shared/http/repositories/onboarding/carddetail.js'

export default {
  name: 'LeadOwner',
  props: {
    cardID: {
      type: String,
      required: true
    }
  },
  mixins: [
    ActionForms
  ],
  data () {
    return {
      data: null,
      form: LeadOwnerForm,
      details: null
    }
  },
  watch: {
    'details': function () {
      this.onSetForm()
    }
  },
  computed: {
    getDetails () {
      let result = null
      if (this.details && this.details.cardDetail) {
        result = this.details.cardDetail
      }
      return result
    }
  },
  beforeMount() {
    this.onSetForm()
  },
  methods: {
    onLoad() {
      this.onLoadAllAccount()
    },
    onSetForm () {
      this.onClear()
      if (this.getDetails) {
        this.data = this.getDetails
        this.form.managerID.value = this.getDetails.managerID
        this.form.responsibleAppraiserID.value = this.getDetails.responsibleAppraiserID
      }
    },
    onLoadAllAccount () {
      AccountRepository.GetAll()
        .then(res => {
          let { code, data } = res.data
          if(code === 200) {
            this.form.managerID.options = this.onResultDropdown(res)
            this.form.responsibleAppraiserID.options = this.onResultDropdown(res)
          }
        })
        .catch(this.onException)
    },
    async onUpdate () {
      loading.push()
      CardDetailRepository.UpdateManager(this.cardID, this.data)
        .then(res => {
          let { code } = res.data
          loading.close()
          if (code === 200) {
            this.$emit('ReloadTimeline')
            toast.success(this.$lang.Common.Alerts.Success)
          } else {
            toast.error(this.$lang.Common.Alerts.Error)
          }
        })
        .catch(this.onException)
    },
  }
}
</script>