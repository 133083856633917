import APIClient from '@/shared/http/clients/api'

const resourceName = 'groupprofile'
const APIAccess = process.env.VUE_APP_Core

const GetAllByGroupId = (groupID, data = {}, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/group/${groupID}`, data, config)
const Create = (data = {}, config = {}) => APIClient.post(`${APIAccess}/${resourceName}`, data, config)
const Update = (data = {}, config = {}) => APIClient.put(`${APIAccess}/${resourceName}`, data, config)
const Delete = (id, config = {}) => APIClient.delete(`${APIAccess}/${resourceName}/${id}`, config)

const GroupProfileRepository = {

  GetAllByGroupId,
  Create,
  Update,
  Delete
}

export default GroupProfileRepository
