const roles = {
  /** @description MENU ACESSOS */
  MENU_ACCESS: 'MENU_ACCESS',
  /** @description MENU CONFIGURAÇÕES */
  MENU_CONFIGURATION: 'MENU_CONFIGURATION',

  MENU_CRM: 'MENU_CRM',

  MENU_AGENDA: 'MENU_AGENDA',

  MENU_DASHBOARD: 'MENU_DASHBOARD',

  /** @description MENU CONFIGURAÇÕES */
  MENU_SETTINGS: 'MENU_SETTINGS',


  /** @description ADM - CONTAS (LEITURA) */
  RD_ADM_ACCOUNTS: 'RD_ADM_ACCOUNTS',
  /** @description ADM - GRUPOS (LEITURA) */
  RD_ADM_GROUPS: 'RD_ADM_GROUPS',
  /** @description ADM - PERMISSÕES (LEITURA) */
  RD_ADM_PERMISSIONS: 'RD_ADM_PERMISSIONS',
  /** @description ADM - CONTAS (ESCRITA) */
  RW_ADM_ACCOUNTS: 'RW_ADM_ACCOUNTS',
  /** @description ADM - GRUPOS (ESCRITA) */
  RW_ADM_GROUPS: 'RW_ADM_GROUPS',
  /** @description ADM - PERMISSÕES (ESCRITA) */
  RW_ADM_PERMISSIONS: 'RW_ADM_PERMISSIONS',

  /** @description CFG - EMPRESA (LEITURA) */
  RD_CFG_COMPANY: 'RD_CFG_COMPANY',
  /** @description CFG - EMPRESA (ESCRITA) */
  RW_CFG_COMPANY: 'RW_CFG_COMPANY',

  /** @description CFG - FILIAL (LEITURA) */
  RD_CFG_BRANCH: 'RD_CFG_BRANCH',
  /** @description CFG - FILIAL (ESCRITA) */
  RW_CFG_BRANCH: 'RW_CFG_BRANCH',

  /** @description CFG - SEGMENTO (LEITURA) */
  RD_CFG_BUSINEESSTYPE: 'RD_CFG_BUSINEESSTYPE',
  /** @description CFG - SEGMENTO (ESCRITA) */
  RW_CFG_BUSINEESSTYPE: 'RW_CFG_BUSINEESSTYPE',

  /** @description CFG - CATEGORIA (LEITURA) */
  RD_CFG_CATEGORY: 'RD_CFG_CATEGORY',
  /** @description CFG - CATEGORIA (ESCRITA) */
  RW_CFG_CATEGORY: 'RW_CFG_CATEGORY',

  /** @description CFG - PRODUTO (LEITURA) */
  RD_CFG_PRODUCT: 'RD_CFG_PRODUCT',
  /** @description CFG - PRODUTO (ESCRITA) */
  RW_CFG_PRODUCT: 'RW_CFG_PRODUCT',

  /** @description CFG - FORMA DE CONTATO (ESCRITA) */
  RW_CFG_CONTACTTYPE: 'RW_CFG_CONTACTTYPE',

  /** @description CFG - ORIGEM DO LEA (ESCRITA) */
  RW_CFG_LEADORIGIN: 'RW_CFG_LEADORIGIN',

  /** @description CFG - MOTIVO DE PERDA (ESCRITA) */
  RW_CFG_REASONLOSS: 'RW_CFG_REASONLOSS',

  /** @description CRM - CONFIGURAÇÕES BOARD (ESCRITA) */
  RW_CRM_BOARDSETTINGS: 'RW_CRM_BOARDSETTINGS',

  /** @description CRM - ADICIONAR LEADS (ESCRITA) */
  RW_CRM_ADDEDLEAD: 'RW_CRM_ADDEDLEAD',

  /** @description CRM - ADICIONAR PRODUTO E SERVIÇOS (ESCRITA) */
  RW_CRM_ADDEDPRODUCT: 'RW_CRM_ADDEDPRODUCT',

  /** @description CRM - ADICIONAR FOLLOWUPS (ESCRITA) */
  RW_CRM_ADDEDFOLLOWUP: 'RW_CRM_ADDEDFOLLOWUP',

  /** @description CRM - ADICIONAR RESPONSÁVEL (ESCRITA) */
  RW_CRM_ADDEDACCOUNTMANAGER: 'RW_CRM_ADDEDACCOUNTMANAGER'
}
export default roles
