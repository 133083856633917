<script>
import { Line } from 'vue-chartjs'
import { loading } from '@/shared/utils/UIHelper'
import DashboardRepository from '@/shared/http/repositories/report/dashboard.js'

export default Line.extend({
  props: {
    groupBy: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      newLeads: [],
      reactivated: [],
      days: []
    }
  },
  watch: {
    groupBy: function () {
      this.onRenderChart()
    }
  },
  computed: {
    total: function () {
      const reactivated = this.reactivated
      const newLeads = this.newLeads
      const result = []
      
      newLeads.forEach((obj, index) => {
        const value = reactivated[index] + newLeads[index]
        result.push(value)
      })

      return result
    }
  },
  mounted () {
    // this.onLoadListLeads()
  },
  methods: {
    async onLoadListLeads (search) {
      this.onSetRangeDate(search.dateStart, search.dateEnd)
      await this.onGetAllTotalNewLeadsDays(search)
      await this.onGetAllTotalReactivatedLeadsDays(search)
      this.onRenderChart()      
    },
    onSetRangeDate (startDate, endDate){
      this.days = []
      let start = this.moment(startDate).format("YYYY-MM-DD")
      let end = this.moment(endDate).format("YYYY-MM-DD")

      while(this.moment(start, "YYYY-MM-DD") <= this.moment(end, "YYYY-MM-DD")) {
        let day = this.moment(start).format("DD/MMM")
        this.days.push(day)              
        start = this.moment(start, "YYYY-MM-DD").add(1, 'days')
      }
    },
    async onGetAllTotalNewLeadsDays(search) {
      this.newLeads = []
      let start = this.moment(search.dateStart).format("YYYY-MM-DD")
      let end = this.moment(search.dateEnd).format("YYYY-MM-DD")
      await DashboardRepository.GetAllTotalNewLeadsDays(search)
        .then(res => {
          let newLeads = res.data.data
          if (res.data.code === 200 && newLeads.length > 0) {  
            while(this.moment(start, "YYYY-MM-DD") <= this.moment(end, "YYYY-MM-DD")) {
              let value = 0
              let currentDate = this.moment(start).format('YYYY-MM-DD')
              let leads = newLeads.find(element => this.moment(element.createDate).format('YYYY-MM-DD') == currentDate)

              if (leads) {
                value = leads.total
              }

              this.newLeads.push(value)             
              start = this.moment(start, "YYYY-MM-DD").add(1, 'days')
            }
          }
        })
        .catch(er => {
          console.log(er)
          loading.pop()
        })
    },
    async onGetAllTotalReactivatedLeadsDays(search) {
      this.reactivated = []
      let start = this.moment(search.dateStart).format("YYYY-MM-DD")
      let end = this.moment(search.dateEnd).format("YYYY-MM-DD")
      await DashboardRepository.GetAllTotalReactivatedLeadsDays(search)
        .then(res => {
          let reactivatedLeads = res.data.data
          if (res.data.code === 200 && reactivatedLeads.length > 0) {
            while(this.moment(start, "YYYY-MM-DD") <= this.moment(end, "YYYY-MM-DD")) {
              let value = 0
              let currentDate = this.moment(start).format('YYYY-MM-DD')
              let leads = reactivatedLeads.find(element => this.moment(element.reactivatedDate).format('YYYY-MM-DD') == currentDate)

              if (leads) {
                value = leads.total
              }

              this.reactivated.push(value)             
              start = this.moment(start, "YYYY-MM-DD").add(1, 'days')
            }
          }
        })
        .catch(er => {
          console.log(er)
          loading.pop()
        })  
    },
    onRenderChart () {
      
      if (this.groupBy === 'byType') {
        this.renderChart({
          labels: this.days,
          datasets: [ 
            {
              label: 'Reativados',
              backgroundColor: '#00BFB2',
              borderColor: '#00AB9F',
              fill: true,
              borderWidth: 2,
              data: this.reactivated
            },
            {
              label: 'Novos',
              backgroundColor: '#236192',
              borderColor: '#1D438A',
              fill: true,
              borderWidth: 2,
              data: this.newLeads
            },
          ]
        }, {responsive: true, maintainAspectRatio: false})
      } else {
        this.renderChart({
          labels: this.days,
          datasets: [ 
            {
              label: 'Total',
              backgroundColor: '#0b873a',
              borderColor: '#087532',
              fill: true,
              borderWidth: 2,
              data: this.total
            }
          ]
        }, {responsive: true, maintainAspectRatio: false})
      }
    }
  }
})
</script>
