import ActionForms from '@/shared/mixins/actionsForm.js'
import Tools from '@/components/tools/Tools.js'
import { toast, loading } from '@/shared/utils/UIHelper'

import OpportunityForm from './opportunity-form.js'
import OpportunityModel from '@/shared/models/onboarding/carddetails/opportunity.js'

import CardRepository from '@/shared/http/repositories/onboarding/card.js'
import OpportunityRepository from '@/shared/http/repositories/onboarding/opportunity.js'
import LeadOriginRepository from '@/shared/http/repositories/settings/originlead.js'
import OriginLead from '@/views/private/settings/originlead/originlead.vue'

import Roles from '@/shared/utils/roles.js'
import PermissionMixin from '@/store/module/permission/mixin'

export default {
  name: 'ClienteDetails',
  mixins: [
    ActionForms,
    PermissionMixin
  ],
  props: {
    cardID: {
      type: String,
      required: true
    },
    lead: {
      type: Object,
      required: true
    }
  },
  components: {
    OriginLead
  },
  data () {
    return {
      card: null,
      roles: Roles,
      contact: null,
      opportunityForm: OpportunityForm,
      opportunityModel: OpportunityModel,
    }
  },
  watch: {
    'lead': function () {
      this.onSetForm
    },
    'contact': function (value) {
      let result = null
      if (value !== null) {
        this.opportunityForm.contactName.value = value.name
        this.opportunityForm.contactPhone.value = value.phone
        this.opportunityForm.contactCellPhone.value = value.cellPhone
      }
      return result
    }
  },
  beforeMount() {
    this.onSetForm()
  },
  methods: {
    onClearForms() {
      Tools.ClearDataForms(this.opportunityForm, this.opportunityModel)
    },
    onSetForm() {
      this.onClearForms()
      Tools.SetValueInForm(this.opportunityForm, this.opportunityModel, this.lead.opportunity)
      if (this.lead && this.lead.opportunity) {
        this.opportunityForm.contactName.value = this.lead.opportunity.contactName
        this.opportunityForm.contactPhone.value = this.lead.opportunity.contactPhone
        this.opportunityForm.contactCellPhone.value = this.lead.opportunity.contactCellPhone
      }
      if (this.lead && this.lead.opportunity && this.lead.opportunity.contact) {
        this.contact = this.lead.opportunity.contact
      }
    },
    async onLoad() {
      this.onClear()
      this.onLoadAllLeadOrigin()
    },
    onLoadAllLeadOrigin () {
      LeadOriginRepository.GetAll()
        .then(res => {
          let { code, data } = res.data

          if(code === 200) {
            this.opportunityForm.leadOriginID.options = data.map(b => {
              return {
                text: b.name,
                value: b.id
              }
            })
          }
        })
        .catch(this.onException)
    },
    async onSaveOpportunity () {
      let self = this
      if (Tools.ValidateForms(this.opportunityForm, this.opportunityModel, self, toast)) {
        if (this.opportunityModel) {
          loading.push()
          await OpportunityRepository.UpdateFromCard(this.cardID, this.opportunityModel)
            .then(res => {
              let { code } = res.data

              if (code === 200) {
                this.$emit('UpdateDetails', res.data.data)
                toast.success(this.$lang.Common.Alerts.Success)
              } else {
                toast.error(this.$lang.Common.Alerts.Error)
              }
            })
            .catch(this.onException)
          loading.pop()
        } else {
          toast.error(this.$lang.Common.NoRecordInformed)
        }
      }
    },
    showOriginLeadModal () {
      this.$bvModal.show('originLeadModal')
    },
    onUpdateOriginLead () {
      this.onLoadAllLeadOrigin()
    },
    onOpenWhatsapp (value) {
      let cellPhone = value // this.opportunityForm.cellPhone.value
      let clientName = this.opportunityForm.name.value
      if (cellPhone) {
        cellPhone = cellPhone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '')
        cellPhone = cellPhone.trim()
        let a= document.createElement('a')
        a.target= '_blank'
        a.href= 'https://wa.me/55' + cellPhone + '?text=Olá, ' + clientName + ' tudo bem?'
        a.click()
      } else {
        toast.warning('Informe o número do celular para iniciar a conversa!', 'CONVERSAR VIA WHATSAPP')
      }
    },
    onCallCellPhone (value) {
      let cellPhone = value // this.opportunityForm.cellPhone.value
      let clientName = this.opportunityForm.name.value
      if (cellPhone) {
        cellPhone = cellPhone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '')
        cellPhone = cellPhone.trim()
        let a= document.createElement('a')
        a.target= '_blank'
        a.href= 'tel:' + cellPhone
        a.click()
      } else {
        toast.warning('Informe o número do celular para iniciar a ligação!', 'LIGAÇÃO')
      }
    },
    onCallPhone () {
      let phone = this.opportunityForm.phone.value
      let clientName = this.opportunityForm.name.value
      if (phone) {
        phone = phone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '')
        phone = phone.trim()
        let a= document.createElement('a')
        a.target= '_blank'
        a.href= 'tel:' + phone
        a.click()
      } else {
        toast.warning('Preencha o número do telefone para iniciar a ligação!', 'LIGAÇÃO')
      }
    }
  }
}