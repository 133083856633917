<script>
import { Bar } from 'vue-chartjs'

const datasets = [
  {
    label: 'Odotologia!',
    backgroundColor: '#99e2a4',
    borderColor: 'transparent',
    data: [ 78, 81, 80, 45, 34, 12, 40, 75, 34, 89, 32, 68 ]
  },
  {
    label: 'Estética',
    backgroundColor: '#FFC0CB',
    borderColor: 'transparent',
    data: [ 70, 61, 82, 45, 44, 52, 70, 73, 64, 79, 35, 67 ]
  }
]

export default Bar.extend({
  props: ['height'],
  mounted () {
    this.renderChart({
      labels: ['Mai-19', 'Jun-19', 'Jul-19', 'Ago-19', 'Set-19', 'Out-19', 'Nov-19', 'Dez-19', 'Jan-20', 'Fev-20', 'Mar-20', 'Abr-20'],
      datasets: datasets
    }, {
      maintainAspectRatio: false,
      legend: {
        display: true
      },
      scales: {
        xAxes: [{
          display: true,
          categoryPercentage: 1,
          barPercentage: 0.5
        }],
        yAxes: [{
          display: true
        }]
      }
    })
  }
})
</script>
