<template>
  <div class="animated fadeIn">
    <!--size="xl"-->
    <b-modal id="modal-new-lead" ref="modal-new-lead" title="Cadastrar Lead" v-if="showAddLead">
      <template #modal-header>
        <div class="w-100">
          <h5 class="text-bold"><i class="fas fa-user-plus"></i> NOVA OPORTUNIDADE</h5>
        </div>
      </template>
      <b-container fluid>
        <div class="form-group row pb-1" v-if="form.companyID.options && form.companyID.options.length > 0">
          <div class="col-md-6">
            <label for="txtGroup"><small class="text-danger" v-if="form.companyID.required">*</small> Empresa:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-briefcase"></i></span>
              <b-form-select v-model="form.companyID.value" :options="form.companyID.options">
                <template slot="first">
                  <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="col-md-6">
            <label for="txtGroup"><small class="text-danger" v-if="form.branchID.required">*</small> Filial <small>(Unidade)</small>:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-building"></i></span>
              <b-form-select v-model="form.branchID.value" :options="form.branchID.options">
                <template slot="first">
                  <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="form.companyID.options && form.companyID.options.length > 0">
          <div class="col-md-12"> 
            <div style="border-bottom: dotted 1px #ccc; padding-top: 10px;"></div>   
          </div>
        </div>
        <div class="form-group row pt-1">
          <div class="col-md-12">
            <label for="txtName"><small class="text-danger">*</small> Cliente:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-user"></i></span>
              <b-form-input type="text"
                            id="txtName"
                            name="txtName"
                            :placeholder="'Digite o nome do Clente (Paciente)'"
                            v-model="form.name.value"
                            :size="form.name.maxlength"
                            :required="form.name.required"
                            :state="form.name.state"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="form-group row pt-2">
          <div class="col-md-6">
            <label for="txtPhone"> Telefone:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-phone-alt"></i></span>
              <b-form-input type="text"
                            id="txtPhone"
                            name="txtPhone"
                            :placeholder="'(99) 9999-9999'"
                            v-model="form.phone.value"
                            :size="form.phone.maxlength"
                            :required="form.phone.required"
                            :state="form.phone.state"
                            v-mask="['(##) ####-####']"
                            v-on:blur="onCheckPhone(1)"
              ></b-form-input>
            </div>
          </div>
          <div class="col-md-6">
            <label for="txtCellPhone"> Celular:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-mobile-alt"></i></span>
              <b-form-input type="text"
                            id="txtCellPhone"
                            name="txtCellPhone"
                            :placeholder="'(99) 9 9999-9999'"
                            v-model="form.cellPhone.value"
                            :size="form.cellPhone.maxlength"
                            :required="form.cellPhone.required"
                            :state="form.cellPhone.state"
                            v-mask="['(##) # ####-####']"
                            v-on:blur="onCheckPhone(2)"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="form-group row pt-2">
          <div class="col-md-12">
            <label for="txtEmail"> Email:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-envelope"></i></span>
              <b-form-input type="text"
                            id="txtEmail"
                            name="txtEmail"
                            :placeholder="'Digite o E-mail'"
                            v-model="form.email.value"
                            :size="form.email.maxlength"
                            :required="form.email.required"
                            :state="form.email.state"
                            v-on:blur="onCheckEmail"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="form-group row pt-2">
          <div class="col-md-12"> 
            <div style="border-bottom: dotted 1px #ccc; padding-top: 10px;"></div>   
          </div>
        </div>
        <div class="form-group row pt-2">
          <div class="col-md-12">
            <label> Responsável:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-user-friends"></i></span>
              <b-form-input type="text"
                            :placeholder="'Digite o nome do Responsável (Pai, Mãe, etc.)'"
                            v-model="form.contactName.value"
                            :size="form.contactName.maxlength"
                            :required="form.contactName.required"
                            :state="form.contactName.state"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="form-group row pt-2">
          <div class="col-md-6">
            <label> Telefone: <small class="text-muted">(Responsável)</small></label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-phone-alt"></i></span>
              <b-form-input type="text"
                            :placeholder="'(99) 9999-9999'"
                            v-model="form.contactPhone.value"
                            :size="form.contactPhone.maxlength"
                            :required="form.contactPhone.required"
                            :state="form.contactPhone.state"
                            v-mask="['(##) ####-####']"
              ></b-form-input>
            </div>
          </div>
          <div class="col-md-6">
            <label> Celular: <small class="text-muted">(Responsável)</small></label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-mobile-alt"></i></span>
              <b-form-input type="text"
                            :placeholder="'(99) 9 9999-9999'"
                            v-model="form.contactCellPhone.value"
                            :size="form.contactCellPhone.maxlength"
                            :required="form.contactCellPhone.required"
                            :state="form.contactCellPhone.state"
                            v-mask="['(##) # ####-####']"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="form-group row pt-2">
          <div class="col-md-12"> 
            <div style="border-bottom: dotted 1px #ccc; padding-top: 10px;"></div>   
          </div>
        </div>
        <div class="form-group row pt-1">
          <div class="col-md-12">
            <label for="txtGroup"><small class="text-danger" v-if="form.leadOriginID.required">*</small> Origem:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-bullhorn"></i></span>
              <b-form-select v-model="form.leadOriginID.value" :options="form.leadOriginID.options">
                <template slot="first">
                  <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </b-container>
      <template #modal-footer>
        <div class="w-100 text-right">
          <button
            type="button"
            class="btn btn-third btn-sm"
            @click="onSaveForm"     
            :disabled="disabledSave"   
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonSave"
          >
            <i class="fa fa-check-circle" /> {{ $lang.Common.Buttons.Save }}
          </button>&nbsp;
          <button
            type="button"
            class="btn btn-default btn-sm text-secundary"
            @click="hide"
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonClose"
          >
            <i class="fas fa-times-circle"></i> {{ $lang.Common.Buttons.Close }}
          </button>
        </div>
      </template>
    </b-modal>
    <b-modal id="modal-existing-lead" ref="modal-new-lead" title="Cadastrar Lead" v-else>
      <template #modal-header>
        <div class="w-100">
          <h5 class="text-bold"><i class="fas fa-user-plus"></i> OPORTUNIDADE JÁ CADASTRADA!</h5>
        </div>
      </template>
      <b-container fluid>
        <div class="form-group row pt-3 pb-3">
          <div class="col-md-12 text-center pt-3">
            <h4>Deseja visualizar o cadastro?</h4>
          </div>
          <div class="col-md-12 text-center pt-2 pb-3">
            <button
              type="button"
              class="btn btn-danger btn-sm text-default"
              @click="onResetLead"
              v-b-tooltip.hover
              :title="$lang.Common.No"
            >
              <i class="fas fa-undo"></i> {{ $lang.Common.No }}&nbsp;
            </button>&nbsp;
            <button
              type="button"
              class="btn btn-success btn-sm text-default"
              @click="onOpenLead"
              v-b-tooltip.hover
              :title="$lang.Common.Yes"
            >
              <i class="fas fa-thumbs-up"></i> {{ $lang.Common.Yes }}&nbsp;
            </button>
          </div>
        </div>
      </b-container>
      <template #modal-footer>
        <div class="w-100 text-right">
          <button
            type="button"
            class="btn btn-default btn-sm text-secundary"
            @click="onCloseModal"
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonClose"
          >
            <i class="fas fa-times-circle"></i> {{ $lang.Common.Buttons.Close }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { loading, toast } from '@/shared/utils/UIHelper'
import ActionForms from '@/shared/mixins/actionsForm.js'

import OpportunityRepository from '@/shared/http/repositories/onboarding/opportunity.js'
import LeadOriginRepository from '@/shared/http/repositories/settings/originlead.js'
import CompanyRepository from '@/shared/http/repositories/settings/company'
import BranchRepository from '@/shared/http/repositories/settings/branch'

import NewOportunityModel from '@/shared/models/onboarding/newOportunity'
import LeadAddForms from './add-form'
import Company from '../../../settings/company/company.vue';

export default {
  name: 'LeadAdd',
  mixins: [
    ActionForms
  ],
  props: {
    boardColumnID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      data: NewOportunityModel,
      form: LeadAddForms,
      showAddLead: true,
      lead: null,
      disabledSave: false
    }
  },
  mounted () {
    this.onLoadAllCompany()
    this.onLoadAllLeadOrigin()
  },
  watch: {
    'form.companyID.value': function (value) {
      if (value !== null) {
        this.onLoadAllBranch(value)
      } else {
        this.form.companyID.value = null
      }
    }
  },
  methods: {
    show () {
      this.$refs['modal-new-lead'].show()
      this.onClear()
      if(this.form.companyID.options && this.form.companyID.options.length === 1) {
        this.form.companyID.value = this.form.companyID.options[0].value
      }
    },
    hide () {
      this.$refs['modal-new-lead'].hide()
    },  
    onSaveForm () {
      this.disabledSave = true     
      setTimeout(() => {
        this.onSave()
      }, 300);
      setTimeout(() => {
        this.disabledSave = false
      }, 3000);
      return false
      
    },
    onCreate () {
      loading.push()
      let data = this.data
      data.boardColumnID = this.boardColumnID

      OpportunityRepository.Create(data)
        .then(res => {
          let { code, data } = res.data

          if (code === 200 && data) {
            toast.success('Oportunidade cadastrada com sucesso.')
            this.$emit('Created')
            this.hide()
          }
        })
        .catch(er => {
          console.log(er)
        })
      
      loading.pop()
    },
    onCheckEmail () {
      if(this.form.email.value) {
        loading.push()
        let data = {
          'email': this.form.email.value,
          'phone': null
        }
        OpportunityRepository.CheckEmail(data)
          .then(res => {
            let { code, data } = res.data
            if (code === 200 && data) {
              this.form.email.value = ''
              this.lead = data
              //toast.warning('Oportunidade já cadastrada!', 'NOVA OPORTUNIDADE')
              this.showLeadExisting()
            }
            loading.pop()
          })
          .catch(er => {
            console.log(er)
            loading.pop()
          })
      }
    },
    onCheckPhone (type) {     
      if(this.form.phone.value || this.form.cellPhone.value) {
        let formPhone = this.form.phone
        if(type == 2) {
          formPhone = this.form.cellPhone
        }
        
        loading.push()
        let data = {
          'email': null,
          'phone': formPhone.value
        }
        OpportunityRepository.CheckPhone(data)
          .then(res => {
            let { code, data } = res.data
            if (code === 200 && data) {
              formPhone.value = ''
              this.lead = data
              // toast.warning('Oportunidade já cadastrada!', 'NOVA OPORTUNIDADE')
              this.showLeadExisting()
            }
            loading.pop()
          })
          .catch(er => {
            console.log(er)
            loading.pop()
          })
        
      }
    },
    onLoadAllLeadOrigin () {
      LeadOriginRepository.GetAll()
        .then((response) => {
          this.form.leadOriginID.options = this.onResultDropdown(response)
        })
        .catch(this.onException)
    },
    onLoadAllCompany () {
      loading.push()
      CompanyRepository.GetAll()
        .then((response) => {
          this.form.companyID.options = this.onResultDropdown(response)
        })
        .catch(this.onException)
    },
    onLoadAllBranch (companyID) {
      loading.push()
      BranchRepository.GetAllByCompanyID(companyID)
        .then((response) => {
          this.form.branchID.options = this.onResultDropdown(response)

          if(this.form.branchID.options && this.form.branchID.options.length === 1) {
            this.form.branchID.value = this.form.branchID.options[0].value
          }
        })
        .catch(this.onException)
    },
    onResetLead () {
      this.showAddLead = true
      this.onClear()
    },
    showLeadExisting () {
      this.showAddLead = false
    },
    onOpenLead () {
      if (this.lead) {
        let cardID = this.lead.referenceCard
        this.$router.push({ name: 'QuadroDetalhes', params: { cardID } })
        this.hide()
      }
    },
    onCloseModal () {
      this.showAddLead = true
      this.onClear()
      this.hide()
    }
  }
}
</script>