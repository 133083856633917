import APIClient from '@/shared/http/clients/api'

const resourceName = 'action'
const APIAccess = process.env.VUE_APP_ONBOARDING

const GetHistory = (personID, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/person/${personID}`, config)

const ActionRepository = {
  GetHistory
}

export default ActionRepository
