import APIClient from '@/shared/http/clients/api'

const resourceName = 'columnevent'
const APIAccess = process.env.VUE_APP_ONBOARDING

const GetFields = (config = {}) => APIClient.get(`${APIAccess}/${resourceName}/fields`, config)
const GetEvents = (boardColumnID, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/column/${boardColumnID}`, config)
const Save = (data = {}, config = {}) => APIClient.put(`${APIAccess}/${resourceName}`,data, config)

const ColumnEventRepository = {
  GetFields,
  GetEvents,
  Save
}

export default ColumnEventRepository
