import { mapGetters, mapActions } from 'vuex'
import SignInModule, { MODULE_NAME } from './index'

const SignInMixin = {
  namespaced: SignInModule.namespaced,
  computed: { ...mapGetters(MODULE_NAME, Object.keys(SignInModule.getters)) },
  methods: { ...mapActions(MODULE_NAME, Object.keys(SignInModule.actions)) }
}

export default SignInMixin
