import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'

import ReasonLossRepository from '@/shared/http/repositories/settings/reasonloss.js'
import ReasonLosspModel from '@/shared/models/settings/reasonlossmodel.js'
import ReasonLossForms from './reasonloss-form'

export default {
  name: 'ReasonLoss',
  mixins: [ActionsForm],
  data () {
    return {
      data: ReasonLosspModel,
      form: ReasonLossForms
    }
  },
  methods: {
    onLoad() {
      if (!this.isModal) {
        loading.push()
        ReasonLossRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
          .then((response) => {
            this.onResultGrid(response)
          })
          .catch((err) => {
            this.onException(err)
          })
      }
    },
    onCreate () {
      loading.push()
      ReasonLossRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      ReasonLossRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      ReasonLossRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}
