import { toast } from '@/shared/utils/UIHelper'
import AuthRepository from '@/shared/http/repositories/access/auth.js'

export default {
  name: 'ChangePassword',
  data () {
    return {
      version: process.env.VUE_APP_VERSION,
      password: null,
      confirmation: null
    }
  },
  computed: {
    Token: function () {
      return this.$route.query.e
    },
    Signature: function () {
      return this.$route.query.s
    }
  },
  mounted () {
    // this.token = this.$route.params.token
    console.log('Assinatura', this.Signature)
    console.log('Token', this.Token)
  },
  methods: {
    onResetPassword () {
      if (this.password && this.confirmation) {
        if (this.password === this.confirmation) {
          if (this.Signature && this.Token) {
            let data = {
              token: this.Token,
              signature: this.Signature,
              newPassword: this.password
            }
             AuthRepository.ResetPasswordValidate(data)
              .then((response) => {
                toast.success('<strong>Senha alterada com sucesso!</strong><br>')
                this.password = null
                this.confirmation = null
                var self = this
                setTimeout(() => self.$router.push({ name: 'SignIn' }), 1000)
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            toast.error('ATENÇÃO! Não foi possível atualizar sua senha!')
            var self = this
            setTimeout(() => self.$router.push({ name: 'SignIn' }), 1000)
          }
        } else {
          this.password = null
          this.confirmation = null
          toast.error('ATENÇÃO! A senha e a confirmação não são iguais')
        }
      } else {
        toast.error('ATENÇÃO! Informe a senha e a confirmação')
      }
    }
  },
}
