<template>
  <div v-if="isLoaded" class="kanban-main-container">
    <div class="w-100 px-3 kanban-nav" >
      <h5 class="pt-2 pl-4 text-third text-bold">
        <span v-if="data.leadStatus === 1"><i class="fas fa-user-check"></i> &nbsp; {{data.opportunity.name}} | <strong class="text-success">GANHOU <i class="fas fa-check"></i></strong></span>
        <span v-else-if="data.leadStatus === 2"><i class="fas fa-heart-broken"></i> &nbsp; {{data.opportunity.name}} | <strong class="text-danger">PERDIDO <i class="fas fa-times"></i></strong></span>
        <span v-else>
          <i class="fas fa-user-edit"></i>&nbsp; {{data.opportunity.name}}
        </span>
      </h5>
      <div class="pr-4" style="display: block;right: 10px;position: absolute;">
        <b-button class="btn btn-sm btn-third rounded-4" size="sm" @click="onBack" style="border-radius: 5px;"><i class="fas fa-long-arrow-alt-left"></i>&nbsp; VOLTAR</b-button>
        <b-button v-if="data.leadStatus === 0" class="btn btn-sm btn-success rounded-4 ml-2" size="sm" @click="onGain"><i class="fas fa-user-check"></i>&nbsp; GANHO</b-button>
        <b-button v-if="data.leadStatus === 0" class="btn btn-sm btn-danger rounded-4 ml-2" size="sm" @click="$refs['modal-lose'].show()"><i class="fas fa-user-times"></i>&nbsp; PERDIDO</b-button>
        <b-button v-if="data.leadStatus === 2" class="btn btn-sm btn-warning rounded-4 ml-2" size="sm" @click="onReactive"><i class="fas fa-sync-alt"></i>&nbsp; REATIVAR</b-button>
        <b-button v-if="data.leadStatus === 0" class="btn btn-sm rounded-4 ml-2" style="background-color: #C5C5C5;" size="sm" @click="onConfirmDelete"><i class="fas fa-user-minus"></i>&nbsp; EXCLUIR</b-button>
        <span class="mr-5">&nbsp;</span>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: 47px;">
      <div class="form-group row">
        <div class="col-md-5">
          <CardOrganization :lead.sync="data" :cardID.sync="cardID"/>
          <ClientDetails :lead.sync="data" :cardID.sync="cardID" v-on:UpdateDetails="onUpdateDetails" @onUpdateProduct="onUpdateTimeline" />
          <Budget :lead.sync="data" :cardID.sync="cardID" v-on:UpdateTimeline="onUpdateTimeline" />
          <LeadOwner ref="LeadOwner" :cardID.sync="cardID" v-on:ReloadTimeline="onUpdateTimeline"/>
        </div>
        <div class="col-md-7">
          <div class="row h-100">
            <div class="col-md-12 h-100 d-flex flex-column">
              <ClientInformation ref="ClientInformation" style="z-index: 2;" :lead.sync="data" :cardID.sync="cardID" @onUpdateDate="onUpdateTimeline" />
              <Followup
                style="z-index: 2;"
                :cardID.sync="cardID"
                @onAddFollowUp="onUpdateTimeline"
              />
              <Timeline
                style="z-index: 1;"
                ref="timeline"
                :personID.sync="data.opportunityID"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-delete-lead" ref="modal-delete-lead" size="md" centered>
      <template #modal-header>
        <div class="w-100">
          <h5 class="text-bold"><i class="fas fa-exclamation-triangle"></i> Atenção</h5>
        </div>
      </template>
      <h6>Tem certeza que deseja excluir o Lead?</h6>
      <template #modal-footer>
        <div class="w-100 text-right">
          <button
            type="button"
            class="btn btn-third btn-sm"
            @click="onDelete"
          >
            <i class="fa fa-check-circle" /> Confirmar
          </button>&nbsp;
          <button
            type="button"
            class="btn btn-default btn-sm text-secundary"
            @click="$refs['modal-delete-lead'].hide()"
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonClose"
          >
            <i class="fas fa-times-circle"></i> {{ $lang.Common.Buttons.Close }}
          </button>
        </div>
      </template>
    </b-modal>
    <Lose
      :cardID.sync="cardID"
      ref="modal-lose"
      @Save="onLose"
    />
  </div>
</template>
<script>
import { toast, loading } from '@/shared/utils/UIHelper'
import CardRepository from '@/shared/http/repositories/onboarding/card.js'
import CardOrganization from './organization/organization.vue'
import ClientDetails from './clientdetails/clientdetails.vue'
import ClientInformation from './info/clientinfo.vue'
import Budget from './budget/budget.vue'
import LeadOwner from './leadowner/leadowner.vue'
import Followup from './followup/followup.vue'
import Timeline from './timeline/timeline.vue'
import Lose from './lose/lose.vue'

export default {
  name: 'KanbanDetails',
  data () {
    return {
      isLoaded: false,
      name: null,
      data: null,
      zIndexes: []
    }
  },
  components: {
    CardOrganization,
    ClientDetails,
    ClientInformation,
    Budget,
    LeadOwner,
    Followup,
    Timeline,
    Lose
  },
  beforeMount () {
    this.onLoadLead()
  },
  computed: {
    cardID: function () {
      return this.$route.params.cardID
    }
  },
  methods: {
    async onLoadLead() {
      await CardRepository.Get(this.cardID)
        .then(res => {
          let { code, data } = res.data
          if (code === 200) {
            this.name = data.name
            this.data = data
            this.isLoaded = true
            this.$nextTick(() => {
              this.$refs.LeadOwner.details = this.data
              this.$refs.ClientInformation.onLoad()
            })
            
          }
        })
        .catch(er => {
          console.log(er)
        })
    },
    onBack () {
      if (this.$route.params.back) {
        this.$router.push({ name: this.$route.params.back })
      } else {
        this.$router.push({ name: 'Kanban' })
      }
    },
    async onGain () {
      loading.push()
      let data = {
        description: ''
      }
      if(!this.data.cardDetail || !this.data.cardDetail.accomplished) {
        toast.warning('Informe a Data de Comparecimento do Paciente!', 'GANHO LEAD')
        loading.pop()
        setTimeout(() => {
          this.$refs.ClientInformation.$refs.accomplishedDatePicker.show()
        }, 1000);
        return false
      }
      /*
      if(!this.data.productID) {
        toast.warning('Produto não informado!', 'GANHO LEAD')
        loading.pop()
        return false
      }
      
      if(!this.data.opportunityValue || parseInt(this.data.opportunityValue) === 0){
        toast.warning('Valor da oportunidade não informado!', 'GANHO LEAD')
        loading.pop()
        return false
      }
      */
      
      await CardRepository.UpdateStatus(this.cardID, 1, data)
        .then(res => {
          let { code } = res.data

          if (code === 200) {
            toast.success(this.$lang.Common.Alerts.Success)
          } else {
            toast.error(this.$lang.Common.Alerts.Error)
          }
        })
        .catch(er => {
          console.log(er)
        })

      loading.pop()

      this.onBack()
    },
    async onReactive () {
      loading.push()
      let data = {
        description: ''
      }

      await CardRepository.UpdateStatus(this.cardID, 0, data)
        .then(res => {
          let { code } = res.data

          if (code === 200) {
            toast.success(this.$lang.Common.Alerts.Success)
          } else {
            toast.error(this.$lang.Common.Alerts.Error)
          }
        })
        .catch(er => {
          console.log(er)
        })

      loading.pop()

      this.onBack()
    },
    onUpdateDetails (opportunity) {
      this.data.opportunity = opportunity
      this.$refs.timeline.onLoadHistory()
    },
    onConfirmDelete () {
      this.$refs['modal-delete-lead'].show()
    },
    async onDelete () {
      await CardRepository.Delete(this.cardID)
        .then(res => {
          const { code } = res.data
          if (code === 200) {
            this.$router.push({ name: 'Kanban' })
          } else {
            toast.error(this.$lang.Common.Alerts.Error)
          }
        })
        .catch(er => {
          console.log(er)
        })
    },
    async onLose () {
      // loading.push()

      // await CardRepository.UpdateStatus(this.cardID, 1)
      //   .then(res => {
      //     let { code } = res.data

      //     if (code === 200) {
      //       toast.success(this.$lang.Common.Alerts.Success)
      //     } else {
      //       toast.error(this.$lang.Common.Alerts.Error)
      //     }
      //   })
      //   .catch(er => {
      //     console.log(er)
      //   })

      // loading.pop()
      this.onBack()
    },
    onUpdateTimeline () {
      this.onLoadLead()
      this.$refs.timeline.onLoadHistory()
    },
    datePickerOpen () {
      this.zIndexes.push(0)
    },
    datePickerClosed () {
      this.zIndexes.splice(0,1)
    }
  },
}
</script>
<style scoped>
  .main .container-fluid {
    padding: 10px 30px;
  }
</style>
