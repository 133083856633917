import GroupRepository from '@/shared/http/repositories/access/group'
import { mutationTypes as mutation } from './mutations'

const GET_GROUP_BY_ID = 'getGroupById'
const GET_ALL_GROUPS = 'getAllGroups'

export const actionTypes = {
  GET_GROUP_BY_ID,
  GET_ALL_GROUPS
}

export const actions = {
  [GET_GROUP_BY_ID] ({ commit }, payload) {
    commit(mutation.SET_GROUP, payload)
  },

  async [GET_ALL_GROUPS] ({ commit }, payload) {
    GroupRepository.GetAll(payload)
      .then((res) => {
        commit(mutation.SET_GROUPS, res.data)
      })
  }
}
