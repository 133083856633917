<template>
  <div class="row">
    <div
      class="col-12 pb-4"
      v-show="showMessage"
    >
      <div
        class="required-field-bottom"
        :class="MessageType"
      >
        <small class="pl-2">
          <span v-html="MessageDisplay" />
        </small>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RequiredFieldBlock',
  props: {
    showMessage: {
      type: Boolean,
      required: false,
      default: true
    },
    message: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'danger'
    }
  },
  computed: {
    MessageDisplay: function () {
      var msg = this.message
      if (this.showMessage) {
        if (!this.message) {
          msg = '(*) ' + this.$lang.Common.RequiredField
        }
      }
      return msg
    },
    MessageType: function () {
      var msgType = 'text-danger'
      if (this.showMessage) {
        if (this.type) {
          msgType = 'text-' + this.type
        }
      }
      return msgType
    }
  }
}
</script>
