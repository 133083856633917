import ActionsForm from '@/shared/mixins/actionsForm'
import Tools from '@/components/tools/Tools.js'
import { loading } from '@/shared/utils/UIHelper'

// import Roles from '@/shared/utils/roles.js'
import PermissionMixin from '@/store/module/permission/mixin'
import BudgetRepository from '@/shared/http/repositories/onboarding/budget.js'

export default {
  name: 'BudgetList',
  mixins: [
    ActionsForm,
    PermissionMixin
  ],
  data () {
    return {
      data: null,
      form: null,
      cardID: null
    }
  },
  watch: {
    'cardID': function (value) {
      if (value) {
        this.onLoadAllBudget(value)
      }
    }
  },
  computed: {
    getCardID () {
      let result = null
      if (this.cardID) {
        result = this.cardID
      }
      return result
    },
    totalValue () {
      let result = null
      if (this.objList && this.objList.length > 0) {
        this.objList.forEach(element => {
          result += element.value
        });
      }
      return result
    }
  },
  methods: {
    onLoad () {
      if(this.getCardID) {
        this.onReload()
      }
    },
    onLoadAllBudget (cardId) {
      if (cardId) {
        loading.push()
        BudgetRepository.GetAllByCard(cardId, this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
          .then((response) => {
            this.onResultGrid(response)
          })
          .catch((err) => {
            this.onException(err)
          })
      }
    },
    onDelete (obj) {
      loading.push()
      BudgetRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
          this.$emit('Reload')
        })
        .catch(this.onException)
    },
    onReload() {
      this.onLoadAllBudget(this.cardID)
    },
    onNewProduct() {
      this.$emit('New')
    },
    onEdit(obj) {
      this.$nextTick(() => {
        this.$emit('Edit', obj)
      }) 
    },
    formatValue(value) {
      return Tools.formatMoneyBR(value)
    }
  },
}