<template>
  <div
    class="app flex-row align-items-center bg-img"
    @keyup.enter="validateAndSignIn"
  >
    <div class="login-block-logo">
      <img
        src="/static/img/logotipo/SaluDigital02.png"
        style="width: 240px;"
      >
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5">
          <div class="card-group mb-0">
            <div class="card p-4">
              <div class="card-block">
                <h1>{{ $lang.Login.TitleForm }}</h1>
                <p class="text-muted">
                  {{ $lang.Login.TitleFormDescription }}
                </p>
                <div class="input-group mb-3">
                  <span class="input-group-addon"><i class="icon-user" /></span>
                  <b-form-input
                    type="text"
                    id="email"
                    name="email"
                    :placeholder="errors.has('email') ? errors.first('email') : $lang.Login.Email"
                    v-model="credentials.email"
                    v-validate="'required|email'"
                    aria-describedby="emailErrorFeedback"
                    :state="errors.has('email') ? false : getValidationState('email')"
                    @blur.native="getValidationState('email')"
                  />
                </div>
                <div class="input-group mb-4">
                  <span class="input-group-addon"><i class="icon-lock" /></span>
                  <b-form-input
                    type="password"
                    id="password"
                    name="password"
                    :placeholder="errors.has('password') ? errors.first('password') : $lang.Login.Password"
                    v-model="credentials.password"
                    data-vv-as="senha"
                    v-validate="'required'"
                    aria-describedby="passwordErrorFeedback"
                    :state="errors.has('password') ? false : getValidationState('password')"
                    @blur.native="getValidationState('password')"
                  />
                </div>
                <div class="row">
                  <div class="col-6 text-left">
                    <router-link
                      :to="{ name: 'LostPassword' }"
                      class="btn btn-link px-0"
                    >
                      {{ $lang.Login.LostPassowrd }}
                    </router-link>
                  </div>
                  <div class="col-6 text-right">
                    <button
                      type="button"
                      class="btn btn-third px-4"
                      @click="validateAndSignIn"
                    >
                      <i class="fas fa-sign-in-alt" /> {{ $lang.Login.ButtonEnter }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center pt-3 login-version">
        <a
          href="https://saludigital.com.br"
          target="_blank"
          style="color: #fff;"
        >Powered by Saludigital &nbsp;-&nbsp; {{ $lang.Login.LabelVersion }} {{ version }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Authentication from './Authentication.js'

export default Authentication

</script>
