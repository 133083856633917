import AuthRepository from '@/shared/http/repositories/access/auth'
import APIClient from '@/shared/http/clients/api'
import { JWTService } from '@/shared/services/jwt'

/**
 * @function handleSignInAttempt
 * @description Trata uma tentativa de sign in.
 * @param {Object} result O resultado da tentativa de sign in.
 * @returns {Object} Resultado do sign in.
 */
const handleSignInAttempt = (result) => {
  const signInResult = {
    success: false,
    data: undefined
  }

  if (result.code === 200) {
    signInResult.success = true
    signInResult.data = result.data.data
    JWTService.SaveToken(result.data.token)
    APIClient.defaults.headers.common.Authorization = `Bearer ${JWTService.GetToken()}`
    return signInResult
  }

  JWTService.DestroyToken()

  if (result.data) {
    signInResult.data = result.data.data
  }

  // IAPClient.defaults.headers.common.Authorization = null;

  return signInResult
}

/* eslint prefer-promise-reject-errors: "error" */
/**
 * @function signIn
 * @description Realiza o sign in de um usuário.
 * @param {Object} credentials As credenciais do usuário.
 * @returns {Boolean} Se o sign in teve sucesso ou não.
 */
const signIn = async (credentials) => {
  if (!credentials) {
    // return Promise.reject({ success: false })
    return Promise.reject(new Error('Not authorized'))
  }

  return AuthRepository.SignIn(credentials)
    .then(res => Promise.resolve(handleSignInAttempt(res.data)))
    .catch(err => Promise.reject(handleSignInAttempt(err)))
}

/**
 * @function signOut
 * @description Desloga o usuário.
 * @returns {void}
 */
const signOut = () => {
  JWTService.DestroyToken()
}

/**
 * @class AuthService
 * @description Serviço para gerenciar o estado de autenticação do sistema.
 */
export default {
  signIn,
  signOut
}
