import Tools from '@/components/tools/Tools.js'
import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'


import BusinessTypeRepository from '@/shared/http/repositories/settings/businesstype.js'
import CategoryRepository from '@/shared/http/repositories/settings/category.js'
import ProductRepository from '@/shared/http/repositories/settings/product.js'
import ProductpModel from '@/shared/models/settings/productmodel.js'
import ProductForms from './product-form'

export default {
  name: 'Product',
  mixins: [ActionsForm],
  data () {
    return {
      data: ProductpModel,
      form: ProductForms
    }
  },
  watch: {
    'form.businessTypeID.value': function (businessTypeID) {
      if (businessTypeID) {
        if (this.form.categoryID.options.length > 0) {
          this.form.categoryID.options = []
          this.form.categoryID.value = null
        }
        this.onChangeBusinessType(businessTypeID)
      } else {
        this.form.categoryID.options = []
        this.form.categoryID.value = null
      }
    }
  },
  created() {
    this.onLoadAllBusinessType()
  },
  methods: {
    onEdit (obj) {
      loading.push()
      Tools.SetValueInForm(this.form, this.data, obj)
      if (obj.category && obj.category.businessType) {
        this.form.businessTypeID.value = obj.category.businessTypeID
      }
      loading.pop()
    },
    onLoad() {
      if (!this.isModal) {
        loading.push()
        ProductRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
          .then((response) => {
            this.onResultGrid(response)
          })
          .catch((err) => {
            this.onException(err)
          })
      }
    },
    onCreate () {
      loading.push()
      ProductRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      ProductRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      ProductRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onLoadAllBusinessType () {
      loading.push()
      BusinessTypeRepository.GetAll()
        .then((response) => {
          this.form.businessTypeID.options = this.onResultDropdown(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onChangeBusinessType(id) {
      loading.push()
      CategoryRepository.GetAllByBusinessType(id)
        .then((response) => {
          this.form.categoryID.options = this.onResultDropdown(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}
