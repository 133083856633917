<template>
  <div class="sidebar">
    <nav class="sidebar-nav">
      <div>&nbsp;</div>
      <ul class="nav" v-if="isMenuDefault">
        <li class="divider" />
        <li class="nav-item">
          <router-link :to="{ name: 'StartPage' }" class="nav-link"><i class="fas fa-home"></i> Página inicial </router-link>
        </li>
        <li class="divider" />

        <li class="nav-title" v-if="hasRole(this.roles.MENU_AGENDA) || hasRole(this.roles.MENU_CRM)">
          OPERACIONAL
        </li>
        <li class="nav-item" v-if="hasRole(this.roles.MENU_AGENDA)">
          <a class="nav-link"><i class="far fa-calendar-alt"></i> Agenda <b-badge variant="warning">Breve</b-badge></a>
        </li>
        <li class="nav-item" v-if="false"> 
          <a class="nav-link"><i class="fas fa-phone-volume"></i> Ligações</a>
        </li>   
        <li class="nav-item" v-if="hasRole(this.roles.MENU_CRM)">
          <router-link :to="{ name: 'Kanban' }" class="nav-link"><i class="fas fa-address-card"></i> CRM</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Contatos' }" class="nav-link"><i class="fas fa-address-book"></i> Contatos</router-link>
        </li>    
        <li class="nav-item" v-if="false">
          <a class="nav-link"><i class="fas fa-mail-bulk"></i> E-mail Marketing</a>
        </li>
        <li class="nav-item" v-if="false">
          <a class="nav-link"><i class="fas fa-credit-card"></i> Pagamentos</a>
        </li>
        <li class="nav-item" v-if="false">
          <a class="nav-link"><i class="fas fa-cloud"></i> Meu Website</a>
        </li>
        <li class="divider" />

        <li class="nav-title" v-if="hasRole(this.roles.MENU_DASHBOARD)">
          GERENCIAL
        </li>
        <li class="nav-item" v-if="hasRole(this.roles.MENU_DASHBOARD)">
          <router-link :to="{ name: 'Dashboard' }" class="nav-link"><i class="fas fa-chart-pie"></i> Dashboard</router-link>
        </li>
        <li class="nav-item" v-if="false">
          <a class="nav-link"><i class="fas fa-chart-bar"></i> Relatórios</a>
        </li>
        <li class="divider" />
        <li class="pt-5">&nbsp;</li>
      </ul>
      <SidebarSettings v-show="isMenuSetting" v-on:Action="showMenuDefault"/>
    </nav>
  </div>
</template>
<script>
import Roles from '@/shared/utils/roles.js'
import PermissionMixin from '@/store/module/permission/mixin'
import AccountMixin from '@/store/module/account/mixin'
import { actionTypes as AccountActions } from '@/store/module/account/actions'
import SidebarSettings from '@/containers/layout/sidebars/SidebarSettings.vue'

export default {
  name: 'SidebarApp',
  mixins: [ AccountMixin, PermissionMixin ],
  data () {
    return {
      isMenuDefault: true,
      isMenuReport: false,
      isMenuSetting: false,
      roles: Roles
    }
  },
  components: {
    SidebarSettings
  },
  mounted () {
    this[AccountActions.GET_LOGGED]()
      .then((response) => {
      })
  },
  methods: {
    handleClick (e) {
      e.preventDefault()
      e.target.parentElement.classList.toggle('open')
    },
    showMenuReport () {
      this.RemoveMinimizedSidebar()
      this.isMenuDefault = false
      this.isMenuReport = true
      this.isMenuSetting = false
    },
    showMenuDefault () {
      this.RemoveMinimizedSidebar()
      this.isMenuDefault = true
      this.isMenuReport = false
      this.isMenuSetting = false
    },
    showMenuSetting () {
      this.RemoveMinimizedSidebar()
      this.isMenuDefault = false
      this.isMenuReport = false
      this.isMenuSetting = true
    },
    RemoveMinimizedSidebar: function () {
      document.body.classList.remove('sidebar-minimized')
      window.scrollTo(0, 0)
    },
    hasRole (role) {
      var result = false
      if (this.permissions) {
        for (var i in this.permissions) {
          if (this.permissions[i].role === role) {
            result = true
          }
        }
      }
      return result
    }
  }
}
</script>

<style lang="css">
  .nav-link {
    cursor:pointer;
  }
</style>
