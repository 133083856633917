import { mapGetters, mapActions } from 'vuex'
import NotificationModule, { MODULE_NAME } from './index'

const NotificationMixin = {
  namespaced: NotificationModule.namespaced,
  computed: { ...mapGetters(MODULE_NAME, Object.keys(NotificationModule.getters)) },
  methods: { ...mapActions(MODULE_NAME, Object.keys(NotificationModule.actions)) }
}

export default NotificationMixin
