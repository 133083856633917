import APIClient from '@/shared/http/clients/api'

const resourceName = 'country'
const APICore = process.env.VUE_APP_Core

const GetAll = (data = {}, config = {}) => APIClient.get(`${APICore}/${resourceName}`, data, config)
const GetAllPaging = (limit, index, config = {}) => APIClient.get(`${APICore}/${resourceName}?limit=${limit}&index=${index}`, config)
const GetById = (id, config = {}) => APIClient.get(`${APICore}/${resourceName}/${id}`, config)

const CountryRepository = {
  GetAll,
  GetAllPaging,
  GetById
}

export default CountryRepository
