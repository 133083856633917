import ActionsForm from '@/shared/mixins/actionsForm'
import Tools from '@/components/tools/Tools'
import { loading, toast } from '@/shared/utils/UIHelper'

import CompanyRepository from '@/shared/http/repositories/settings/company'
import BranchRepository from '@/shared/http/repositories/settings/branch'
import LeadOriginRepository from '@/shared/http/repositories/settings/originlead.js'

import DasbhoardSearchForms from './dashboardsearch-form.js'

const DashboardSearchModel = {
  companyID: null,
  branchID: null,
  boardColumnID: null,
  leadStatus: null,
  leadOriginID: null,
  managerID: null,
  responsibleAppraiserID: null,
  createDateStart: null,
  createDateEnd: null
}

export default {
  mixins: [ActionsForm],
  name: 'DasbhoardSearch',
  data () {
    return {
      data: DashboardSearchModel,
      form: DasbhoardSearchForms,
      dateTimeStart: {
        date: null,
        hour: null
      },
      dateTimeEnd: {
        date: null,
        hour: null
      }
    }
  },
  props: {
    parentComponent: {
      type: Object
    }
  },
  created () {
    this.onLoadAllCompany()
    this.onLoadAllLeadOrigin()
  },
  watch: {
    'form.companyID.value': function (value) {
      if (value !== null) {
        this.onLoadAllBranch(value)
      } else {
        this.form.companyID.value = null
      }
    }
  },
  methods: {
    onLoad() {
    },
    onValidateFilters() { 
      let result = true
      Tools.SetValueToSave(this.form, this.data)

      if (this.dateTimeStart.date) {
        this.data.createDateStart = this.moment(`${this.dateTimeStart.date} 00:00:00`).format('YYYY-MM-DD HH:mm')
      
        if (this.dateTimeEnd.date) {
          this.data.createDateEnd = this.moment(`${this.dateTimeEnd.date} 23:59:59`).format('YYYY-MM-DD HH:mm')
        } else {
          toast.warning('Você precisa informar a <strong>Data Final</strong> para realizar a pesquisa!', 'PESQUISA DE CONTATO')
          return false
        }
      }
      this.data.leadStatus = null
      this.data.boardColumnID =  null
      /*
      if (this.form.situation.value) {
        if (this.form.situation.value == 'G') {
          this.data.leadStatus = 1
        }
        else if (this.form.situation.value == 'P') {
          this.data.leadStatus = 2
        }
        else this.data.boardColumnID =  `${this.form.situation.value}`
      }
      */
      return result
    },
    onFilter() { 
      if (this.onValidateFilters()) {
        this.$eventHub.emit('Filter', { filters: this.data })
      }
    },
    onClearFilters() {
      Tools.SetFormDefaultValues(this.form)
      this.dateTimeStart.date = null
      this.dateTimeEnd.date = null
      this.parentComponent.filters = null
      this.parentComponent.onLoadDashboard()
    },
    onLoadAllLeadOrigin () {
      loading.push()
      LeadOriginRepository.GetAll()
        .then((response) => {
          this.form.leadOriginID.options = this.onResultDropdown(response)
          // this.form.leadOriginID.options.sort(function(a, b){return a-b})
          loading.close()
        })
        .catch(this.onException)
    },
    onLoadAllCompany () {
      loading.push()
      CompanyRepository.GetAll()
        .then((response) => {
          this.form.companyID.options = this.onResultDropdown(response)
          loading.close()
        })
        .catch(this.onException)
    },
    onLoadAllBranch (companyID) {
      loading.push()
      BranchRepository.GetAllByCompanyID(companyID)
        .then((response) => {
          this.form.branchID.options = this.onResultDropdown(response)

          if(this.form.branchID.options && this.form.branchID.options.length === 1) {
            this.form.branchID.value = this.form.branchID.options[0].value
          }
          loading.pop()
        })
        .catch(this.onException)
    }
  }
}
