import toastr from 'toastr'
import { mutationTypes as mutation } from './mutations'
// import { MODULE_NAME } from './index';

const SHOW_LOADING = 'showLoading'
const SET_LOADING = 'setLoading'
const TOAST = 'toast'

export const actionTypes = {
  SHOW_LOADING,
  SET_LOADING,
  TOAST
}

export const globalActions = {
  SHOW_LOADING: `common/${SHOW_LOADING}`,
  SET_LOADING: `common/${SET_LOADING}`,
  TOAST: `common/${TOAST}`
}

export const actions = {
  [SHOW_LOADING] ({ commit }, payload) {
    commit(mutation.SHOW_LOADING, payload)
  },

  [SET_LOADING] ({ commit }, payload) {
    commit(mutation.SET_LOADING, payload)
  },

  /* eslint-disable-next-line */
  [TOAST] (data = {}, { type, msg, title }) {
    switch (type) {
      case 'success':
        toastr.success(msg, title)
        break
      case 'error':
        toastr.error(msg, title)
        break
      case 'info':
        toastr.info(msg, title)
        break
      case 'warning':
        toastr.warning(msg, title)
        break
      default:
        break
    }
  }
}
