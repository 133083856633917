import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'

import BusinessTypeRepository from '@/shared/http/repositories/settings/businesstype.js'
import CategoryRepository from '@/shared/http/repositories/settings/category.js'
import CategorypModel from '@/shared/models/settings/categorymodel.js'
import CategoryForms from './category-form'

export default {
  name: 'category',
  mixins: [ActionsForm],
  data () {
    return {
      data: CategorypModel,
      form: CategoryForms
    }
  },
  created() {
    this.onLoadAllBusinessType()
  },
  methods: {
    onLoad () {
      if (!this.isModal) {
        loading.push()
        CategoryRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
          .then((response) => {
            this.onResultGrid(response)
          })
          .catch(this.onException)
      }
    },
    onCreate () {
      loading.push()
      CategoryRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      CategoryRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      CategoryRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch(this.onException)
    },
    onLoadAllBusinessType () {
      loading.push()
      BusinessTypeRepository.GetAll()
        .then((response) => {
          this.form.businessTypeID.options = this.onResultDropdown(response)
        })
        .catch(this.onException)
    },
  }
}
