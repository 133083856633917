<template>
  <div id="app">
    <div v-if="!onLine" class="offline"></div>
    <div v-if="!onLine" class="offlineText">
      <h2><i class="fas fa-wifi"></i> Você esta offline! <br>Verifique sua internet, por favor.</h2>
    </div>
    <transition name="fade">
      <loading v-show="isLoading" />
    </transition>
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
import CommonMixin from '@/store/module/common/mixin'
import Loading from '@/components/UI/Loading'

export default {
  name: 'App',
  mixins: [CommonMixin],
  data () {
    return {
      onLine: navigator.onLine,
    }
  },
  watch: {
    $route () {
      this.RemoveMinimizedSidebar()
      this.RemoveMaximizedItens()
    }
  },
  components: {
    [Loading.name]: Loading
  },
  mounted() {
    window.addEventListener('online', ()=>{this.onLine=true})
    window.addEventListener('offline', ()=>{this.onLine=false})
  },
  methods: {
    RemoveMinimizedSidebar() {
      if (!document.body.classList.contains('sidebar-minimized')) {
        document.body.classList.add('sidebar-minimized')
      }

      document.body.classList.remove('sidebar-mobile-show')
      window.scrollTo(0, 0)
    },
    RemoveMaximizedItens () {
      let elements = document.getElementsByClassName('nav-dropdown open')

      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.toggle('open')
      }
    },
    RemoveAsidebar() {
      document.body.classList.remove('aside-menu-fixed')
      if (!document.body.classList.contains('aside-menu-hidden')) {
        document.body.classList.add('aside-menu-hidden')
      }
    }
  }
}
</script>

<style lang="scss">
  /* purgecss start ignore */
  @import "../node_modules/@hokify/vuejs-datepicker/dist/vuejs-datepicker.css";
  @import "../scss/style.scss";
  /* purgecss end ignore */
  .offline {
    height: 300%;
    width: 100%;
    opacity: 90%;
    text-align: center;
    background: #efefef;
    display: block;
    position: absolute;
    z-index: 9999;
    overflow: hidden;
  }
  .offlineText h2{
    height: 200%;
    width: 100%;
    text-align: center;
    margin-top: 20%;
    display: block;
    position: absolute;
    z-index: 99999;
  }
  .offlineText h2 i  {
    color: #ff0000
  }
</style>
