export default {
  name: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  },
  description: {
    value: '',
    maxlength: '500',
    required: false,
    rows: '3',
    noResize: true
  }
}
