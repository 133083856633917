import APIClient from '@/shared/http/clients/api'

const resourceName = 'authorize'
const APIAccess = process.env.VUE_APP_Core

const SignIn = (data = {}, config = {}) => APIClient.post(`${APIAccess}/${resourceName}`, data, config)
const SignOut = (data = {}, config = {}) => APIClient.post(`${APIAccess}/${resourceName}/signOut`, data, config)
const ResetPassword = (data = {}, config = {}) => APIClient.post(`${APIAccess}/password/reset`, data, config)
const ResetPasswordValidate = (data = {}, config = {}) => APIClient.post(`${APIAccess}/password/reset/validate`, data, config)

const AuthRepository = {
  SignIn,
  SignOut,
  ResetPassword,
  ResetPasswordValidate
}

export default AuthRepository
