import ActionsForm from '@/shared/mixins/actionsForm'
import Tools from '@/components/tools/Tools'
import { loading, toast } from '@/shared/utils/UIHelper'

import BoardColumnRepository from '@/shared/http/repositories/onboarding/boardColumn.js'
import LeadOriginRepository from '@/shared/http/repositories/settings/originlead.js'
import AccountRepository from '@/shared/http/repositories/access/account.js'
import CompanyRepository from '@/shared/http/repositories/settings/company'
import BranchRepository from '@/shared/http/repositories/settings/branch'

import NotificationSearchForms from './notificationsearch-form.js'

const NotificationSearchModel = {  
  hideRead: null,
  companyID: null,
  branchID: null,
  contactName: null,
  contactPhone: null,
  boardColumnID: null,
  leadStatus: null,
  leadOriginID: null,
  responsibleID: null,
  responsibleAppraiserID: null,
  schedulingStart: null,
  schedulingEnd: null
}

export default {
  mixins: [ActionsForm],
  name: 'NotificationSearch',
  data () {
    return {
      data: NotificationSearchModel,
      form: NotificationSearchForms,
      dateTimeStart: {
        date: null,
        hour: null
      },
      dateTimeEnd: {
        date: null,
        hour: null
      }
    }
  },
  props: {
    parentComponent: {
      type: Object
    }
  },
  created() {
    this.onLoadAllCompany()
    this.onLoadAllBoardColumn()
    this.onLoadAllLeadOrigin()
    this.onLoadAllAccount()
  },
  watch: {
    'form.companyID.value': function (value) {
      if (value !== null) {
        this.onLoadAllBranch(value)
      } else {
        this.form.companyID.value = null
      }
    }
  },
  methods: {
    onLoad() {
    },
    onValidateFilters() { 
      let result = true
      Tools.SetValueToSave(this.form, this.data)

      if (this.dateTimeStart.date) {
        this.data.schedulingStart = this.moment(`${this.dateTimeStart.date} 00:00:00`).format('YYYY-MM-DD HH:mm')
      
        if (this.dateTimeEnd.date) {
          this.data.schedulingEnd = this.moment(`${this.dateTimeEnd.date} 23:59:59`).format('YYYY-MM-DD HH:mm')
        } else {
          toast.warning('Você precisa informar a <strong>Data Final</strong> para realizar a pesquisa!', 'PESQUISA DE CONTATO')
          return false
        }
      }
      this.data.leadStatus = null
      this.data.boardColumnID =  null
      if (this.form.situation.value) {
        if (this.form.situation.value == 'G') {
          this.data.leadStatus = 1
        }
        else if (this.form.situation.value == 'P') {
          this.data.leadStatus = 2
        }
        else this.data.boardColumnID =  `${this.form.situation.value}`
      }

      if(!this.hideRead) {
        this.data.hideRead = false
      }
      return result
    },
    onFilter() { 
      if (this.onValidateFilters()) {
        this.$eventHub.emit('Filter', { filters: this.data })
      }
    },
    onClearFilters() {
      Tools.SetFormDefaultValues(this.form)
      this.dateTimeStart.date = null
      this.dateTimeEnd.date = null
      this.parentComponent.filters = null
      this.parentComponent.onLoad()
    },
    onLoadAllBoardColumn() {
      loading.push()
      BoardColumnRepository.GetAll()
        .then((response) => {
          this.form.situation.options = this.onResultDropdown(response)
          loading.close()
        })
        .catch(this.onException)
    },
    onLoadAllLeadOrigin () {
      loading.push()
      LeadOriginRepository.GetAll()
        .then((response) => {
          this.form.leadOriginID.options = this.onResultDropdown(response)
          // this.form.leadOriginID.options.sort(function(a, b){return a-b})
          loading.close()
        })
        .catch(this.onException)
    },
    onLoadAllAccount () {
      loading.push()
      AccountRepository.GetAll()
        .then(res => {
          let { code, data } = res.data

          if(code === 200) {
            this.form.responsibleID.options = data.map(b => {
              return {
                text: b.name.toUpperCase(),
                value: b.id
              }
            })
            this.form.responsibleAppraiserID.options = data.map(b => {
              return {
                text: b.name.toUpperCase(),
                value: b.id
              }
            })
          }
          loading.close()
        })
        .catch(this.onException)
    },
    onLoadAllCompany () {
      loading.push()
      CompanyRepository.GetAll()
        .then((response) => {
          this.form.companyID.options = this.onResultDropdown(response)
          loading.close()
        })
        .catch(this.onException)
    },
    onLoadAllBranch (companyID) {
      loading.push()
      BranchRepository.GetAllByCompanyID(companyID)
        .then((response) => {
          this.form.branchID.options = this.onResultDropdown(response)

          if(this.form.branchID.options && this.form.branchID.options.length === 1) {
            this.form.branchID.value = this.form.branchID.options[0].value
          }
          loading.pop()
        })
        .catch(this.onException)
    }
  }
}