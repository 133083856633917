import ActionForms from '@/shared/mixins/actionsForm.js'
import Tools from '@/components/tools/Tools.js'
import { toast, loading } from '@/shared/utils/UIHelper'

import SaluDigitalDatePicker from '@/components/superdental/DatePicker.vue'

import InformationForm from './clientinfo-form.js'
import InformationModel from '@/shared/models/onboarding/carddetails/information.js'

import CardDetailRepository from '@/shared/http/repositories/onboarding/carddetail.js'

export default {
  name: 'ClientInfo',
  mixins: [
    ActionForms
  ],
  components: {
    SaluDigitalDatePicker
  },
  props: {
    cardID: {
      type: String,
      required: true
    },
    lead: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      data: InformationModel,
      form: InformationForm
    }
  },
  watch: {
    'form.accomplishedChecked.value': function (value) {
      if (value) {
        if (!this.form.accomplished.date) {
          this.$refs.accomplishedDatePicker.show()
        } else {
          // this.form.accomplishedChecked.value = false
        }
      } else {
        if (!this.form.accomplished.hour) {
          this.form.accomplished.date = ''
          this.form.accomplished.hour = ''
        }
      }
    },
    'form.accomplished.date': function (value) {
      if (value) {
        this.form.accomplishedChecked.value = true
      } else {
        this.form.accomplishedChecked.value = false
      }
    }
  },
  methods: {
    onLoad () {
      this.onSetForm()
    },
    onSetFormClear() {
      this.form.scheduling.date = ''
      this.form.scheduling.hour = ''
      this.form.reschedule.date = ''
      this.form.reschedule.hour = ''
      this.form.accomplished.date = ''
      this.form.accomplished.hour = ''
    },
    onSetForm() {
      this.onSetFormClear()
      Tools.SetValueInForm(this.form, this.data, this.lead.cardDetail)
      if (this.lead.cardDetail.scheduling && this.lead.cardDetail.endScheduling) {
        this.form.scheduling.date = this.moment(this.lead.cardDetail.scheduling).format('YYYY-MM-DD')
        this.form.scheduling.hour = this.moment(this.lead.cardDetail.scheduling).format('HH:mm')
        this.form.endScheduling.hour = this.moment(this.lead.cardDetail.endScheduling).format('HH:mm')
      }

      if (this.lead.cardDetail.reschedule && this.lead.cardDetail.endReschedule) {
        this.form.reschedule.date = this.moment(this.lead.cardDetail.reschedule).format('YYYY-MM-DD')
        this.form.reschedule.hour = this.moment(this.lead.cardDetail.reschedule).format('HH:mm')
        this.form.endReschedule.hour = this.moment(this.lead.cardDetail.endReschedule).format('HH:mm')
      }

      if (this.lead.cardDetail.accomplished && this.lead.cardDetail.endAccomplished) {
        this.form.accomplished.date = this.moment(this.lead.cardDetail.accomplished).format('YYYY-MM-DD')
        this.form.accomplished.hour = this.moment(this.lead.cardDetail.accomplished).format('HH:mm')
        this.form.endAccomplished.hour = this.moment(this.lead.cardDetail.endAccomplished).format('HH:mm')
      }
    },
    async onSave (dateProperty, event) {
      if (dateProperty && event.date && event.initialHour && this.moment(`${event.date} ${event.initialHour}`).isValid()) {
        await this.UpdateDate(dateProperty, this.moment(`${event.date} ${event.initialHour}`).format('MM-DD-YYYY HH:mm'), this.moment(`${event.date} ${event.endHour}`).format('MM-DD-YYYY HH:mm'))
      }
    },
    async UpdateDate (property, initialDate, endDate) {
      if (initialDate !== undefined && endDate !== undefined) {
        loading.push()

        try {
          let date = this.moment(initialDate)

          if (date.isValid()) {
            date = date.format('MM-DD-YYYY HH:mm')
          } else {
            date = null
          }

          await CardDetailRepository.UpdateDate(this.cardID, property, initialDate, endDate)
            .then(res => {
              let { code } = res.data

              if (code === 200) {
                toast.success(this.$lang.Common.Alerts.Success)
                this.$emit('onUpdateDate')
              } else {
                toast.error(this.$lang.Common.Alerts.Error)
              }
            })
            .catch(this.onException)
        } catch (ex) {
          this.onException(ex)
        }

        loading.pop()
      }
    },
    ClearDate (property, formProperty) {
      formProperty.value = null
      this.UpdateDate(property, [formProperty.value])
    },
    onClosedModal(data) {
      if (!data) {
        this.form.accomplishedChecked.value = false
      }
    }
  }
}