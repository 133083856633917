<template>
  <div class="animated fadeIn" @keyup.enter="onFilter">
    <div class="form-group row">
      <div class="col-md-12">
        <label> Empresa:</label>
        <div class="input-group mb-3">
          <b-form-select
            v-model="form.companyID.value"
            :options="form.companyID.options"
            :required="form.companyID.required"
            :state="form.companyID.state"
          >
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Filial <small>(Unidade)</small>:</label>
        <div class="input-group mb-3">
          <b-form-select
            v-model="form.branchID.value"
            :options="form.branchID.options"
            :required="form.branchID.required"
            :state="form.branchID.state"
          >
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Contato:</label>
        <div class="input-group mb-3">
          <b-form-input type="text"
                        :placeholder="'Digite o nome do Contato'"
                        v-model="form.contactName.value"
                        :state="form.contactName.state"
                        autofocus
          />
        </div>
      </div>
      <div class="col-md-12">
        <label>Telefone:</label>
        <div class="input-group mb-3">
          <b-form-input 
            v-model="form.contactPhone.value"
            style="min-width: 200px;"
            id="dropdown-search"
            size="sm"
            placeholder="Digite o Telefone do Lead"
          />
        </div>
      </div>
      <div class="col-md-12">
        <label> Situação:</label>
        <div class="input-group mb-3">
          <b-form-select v-model="form.situation.value">
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              <optgroup label="DECISORES">
                <option value="P">PERDIDO</option>
                <option value="G">GANHOU</option>
              </optgroup>
              <optgroup label="QUADRO">
                <option v-for="(item, index) in form.situation.options" :key="index" :value="item.value">{{item.text.toUpperCase()}}</option>
              </optgroup>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Origem:</label>
        <div class="input-group mb-3">
          <b-form-select v-model="form.leadOriginID.value" :options="form.leadOriginID.options">
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Responsável:</label>
        <div class="input-group mb-3">
          <b-form-select
            v-model="form.responsibleID.value"
            :options="form.responsibleID.options"
            :required="form.responsibleID.required"
            :state="form.responsibleID.state"
          >
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Avaliador:</label>
        <div class="input-group mb-3">
          <b-form-select
            v-model="form.responsibleAppraiserID.value"
            :options="form.responsibleAppraiserID.options"
            :required="form.responsibleAppraiserID.required"
            :state="form.responsibleAppraiserID.state"
          >
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Entrada:</label>
        <div class="mb-3">
          <b-form-datepicker
            v-model="dateTimeStart.date"
            placeholder="Data Inicial"
            class="mb-2"
            style="z-index: 9999;"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
          <b-form-datepicker
            v-model="dateTimeEnd.date"
            placeholder="Data Final"
            class="mb-2"
            style="z-index: 9999;"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </div>
      </div>
      <div class="col-md-12">
        <label> Exibir lidos:</label>
        <div class="input-group mb-3">
          <b-form-select v-model="form.hideRead.value" :options="form.hideRead.options">
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              <option :value="false">{{$lang.Common.Yes}}</option>
              <option :value="true">{{$lang.Common.No}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12 pt-2">
        <button type="button" class="btn btn-third w-100" v-on:click="onFilter">Filtrar</button>
      </div>

      <div class="col-md-12 pt-2 pb-5 mb-5">
        <button type="button" class="btn btn-default w-100" v-on:click="onClearFilters">Limpar Filtros</button>
      </div>
    </div>
    <div class="form-group row" style="margin-top: 50px;">&nbsp;</div>
  </div>
</template>
<script>
import NotificationSearch from './notificationsearch.js'
export default NotificationSearch
</script>
