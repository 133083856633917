<template>
  <div @keyup.enter="onFilter" style="overflow: auto; height: 1200px;">
    <div class="form-group row">
      <div class="col-md-12">
        <label> Empresa:</label>
        <div class="input-group mb-3">
          <b-form-select
            v-model="form.companyID.value"
            :options="form.companyID.options"
            :required="form.companyID.required"
            :state="form.companyID.state"
          >
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Filial <small>(Unidade)</small>:</label>
        <div class="input-group mb-3">
          <b-form-select
            v-model="form.branchID.value"
            :options="form.branchID.options"
            :required="form.branchID.required"
            :state="form.branchID.state"
          >
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label>Nome:</label>
        <div class="input-group mb-3">
          <b-form-input 
            v-model="form.leadName.value"
            style="min-width: 200px;"
            id="dropdown-search"
            size="sm"
            placeholder="Digite o nome do Lead"
            autofocus
          />
        </div>
      </div>
      <div class="col-md-12">
        <label>Telefone:</label>
        <div class="input-group mb-3">
          <b-form-input 
            v-model="form.leadPhone.value"
            style="min-width: 200px;"
            id="dropdown-search"
            size="sm"
            placeholder="Digite o Telefone do Lead"
          />
        </div>
      </div>
      <div class="col-md-12" v-if="false">
        <label> Situação:</label>
        <div class="input-group mb-3">
          <b-form-select v-model="form.situation.value">
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              <optgroup label="DECISORES">
                <option value="P">PERDIDO</option>
                <option value="G">GANHOU</option>
              </optgroup>
              <optgroup label="QUADRO">
                <option v-for="(item, index) in form.situation.options" :key="index" :value="item.value">{{item.text.toUpperCase()}}</option>
              </optgroup>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Origem:</label>
        <div class="input-group mb-3">
          <b-form-select v-model="form.leadOriginID.value" :options="form.leadOriginID.options">
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Motivo de perda:</label>
        <div class="input-group mb-3">
          <b-form-select v-model="form.reasonLossID.value" :options="form.reasonLossID.options">
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Responsável:</label>
        <div class="input-group mb-3">
          <b-form-select
            v-model="form.managerID.value"
            :options="form.managerID.options"
            :required="form.managerID.required"
            :state="form.managerID.state"
          >
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Avaliador:</label>
        <div class="input-group mb-3">
          <b-form-select
            v-model="form.responsibleAppraiserID.value"
            :options="form.responsibleAppraiserID.options"
            :required="form.responsibleAppraiserID.required"
            :state="form.responsibleAppraiserID.state"
          >
            <template slot="first">
              <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-12">
        <label> Entrada:</label>
        <div class="mb-3">
          <b-form-datepicker 
            calendar-width="w-95"
            menu-class="w-95"
            v-model="dateTimeStart.date"
            placeholder="Data Inicial"
            class="mb-2"
            style="z-index: 9999;"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
          <b-form-datepicker 
            calendar-width="w-95"
            menu-class="w-95"
            v-model="dateTimeEnd.date"
            placeholder="Data Final"
            class="mb-2"
            style="z-index: 9999;"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </div>
      </div>
      <div class="col-md-12 pt-2">
        <button type="button" class="btn btn-third w-100" v-on:click="onFilter">Filtrar</button>
      </div>

      <div class="col-md-12 pt-2">
        <button type="button" class="btn btn-default w-100" v-on:click="onClearFilters">Limpar Filtros</button>
      </div>
      <div class="form-group row" style="margin-top: 600px;">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import ActionsForm from '@/shared/mixins/actionsForm'
import Tools from '@/components/tools/Tools'
import { loading, toast } from '@/shared/utils/UIHelper'
import CompanyRepository from '@/shared/http/repositories/settings/company'
import BranchRepository from '@/shared/http/repositories/settings/branch'
import AccountRepository from '@/shared/http/repositories/access/account.js'
import BoardColumnRepository from '@/shared/http/repositories/onboarding/boardColumn.js'
import LeadOriginRepository from '@/shared/http/repositories/settings/originlead.js'
import ReasonLossRepository from '@/shared/http/repositories/settings/reasonloss.js'

const SearchModel = {
  leadName: null,
  leadPhone: null,
  companyID: null,
  branchID: null,
  boardColumnID: null,
  leadStatus: null,
  leadOriginID: null,
  reasonLossID: null,
  managerID: null,
  responsibleAppraiserID: null,
  createDateStart: null,
  createDateEnd: null
}

import SearchForms from './search-form.js'
export default {
  name: 'Search',
  mixins: [ActionsForm],
  props: {
    parentComponent: {
      type: Object
    }
  },
  data () {
    return {
      data: SearchModel,
      form: SearchForms,
      dateTimeStart: {
        date: null,
        hour: null
      },
      dateTimeEnd: {
        date: null,
        hour: null
      }
    }
  },
  mounted() {
    this.onLoadAllCompany()
    this.onLoadAllAccount()
    this.onLoadAllBoardColumn()
    this.onLoadAllLeadOrigin()
    this.onLoadAllReasonLoss()
  },
  watch: {
    'form.companyID.value': function (value) {
      if (value !== null) {
        this.onLoadAllBranch(value)
      } else {
        this.form.companyID.value = null
      }
    }
  },
  methods: {
    onLoad() {},
    onValidateFilters() { 
      let result = true
      Tools.SetValueToSave(this.form, this.data)
      if (this.dateTimeStart.date) {
        this.data.createDateStart = this.moment(`${this.dateTimeStart.date} 00:00:00`).format('YYYY-MM-DD HH:mm')
      
        if (this.dateTimeEnd.date) {
          this.data.createDateEnd = this.moment(`${this.dateTimeEnd.date} 23:59:59`).format('YYYY-MM-DD HH:mm')
        } else {
          toast.warning('Você precisa informar a <strong>Data Final</strong> para realizar a pesquisa!', 'PESQUISA DE CONTATO')
          return false
        }
      }
      this.data.leadStatus = null
      this.data.boardColumnID =  null
      if (this.form.situation.value) {
        if (this.form.situation.value == 'G') {
          this.data.leadStatus = 1
        }
        else if (this.form.situation.value == 'P') {
          this.data.leadStatus = 2
        }
        else this.data.boardColumnID =  `${this.form.situation.value}`
      }
      return result
    },
    onFilter() { 
      if (this.onValidateFilters()) {
        this.$eventHub.emit('Filter', { filters: this.data })
      }
    },
    onClearFilters() {
      Tools.SetFormDefaultValues(this.form)
      this.dateTimeStart.date = null
      this.dateTimeEnd.date = null
      this.parentComponent.filters = null
      this.parentComponent.onClearBoard()
      this.parentComponent.onLoadBoard()
    },
    onLoadAllCompany () {
      loading.push()
      CompanyRepository.GetAll()
        .then((response) => {
          this.form.companyID.options = this.onResultDropdown(response)
        })
        .catch(this.onException)
    },
    onLoadAllBranch (companyID) {
      loading.push()
      BranchRepository.GetAllByCompanyID(companyID)
        .then((response) => {
          this.form.branchID.options = this.onResultDropdown(response)

          if(this.form.branchID.options && this.form.branchID.options.length === 1) {
            this.form.branchID.value = this.form.branchID.options[0].value
          }
          loading.pop()
        })
        .catch(this.onException)
    },
    onLoadAllBoardColumn() {
      loading.push()
      BoardColumnRepository.GetAll()
        .then((response) => {
          this.form.situation.options = this.onResultDropdown(response)
          loading.close()
        })
        .catch(this.onException)
    },
    onLoadAllLeadOrigin () {
      loading.push()
      LeadOriginRepository.GetAll()
        .then((response) => {
          this.form.leadOriginID.options = this.onResultDropdown(response)
          // this.form.leadOriginID.options.sort(function(a, b){return a-b})
          loading.close()
        })
        .catch(this.onException)
    },
    onLoadAllReasonLoss () {
      loading.push()
      ReasonLossRepository.GetAll()
        .then((response) => {
          this.form.reasonLossID.options = this.onResultDropdown(response)
          loading.close()
        })
        .catch(this.onException)
    },
    onLoadAllAccount () {
      loading.push()
      AccountRepository.GetAll()
        .then(res => {
          let { code, data } = res.data

          if(code === 200) {
            this.form.managerID.options = data.map(b => {
              return {
                text: b.name.toUpperCase(),
                value: b.id
              }
            })
            this.form.responsibleAppraiserID.options = data.map(b => {
              return {
                text: b.name.toUpperCase(),
                value: b.id
              }
            })
            loading.pop()
          }
        })
        .catch(this.onException)
    }
  }
}
</script>
