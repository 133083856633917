export default {
  id: null,
  companyID: null,
  cityID: null,
  name: null,
  cnpj: null,
  description: null,
  status: null,
  dateUpdate: null,
  accountIDUpdate: null
}
