<template>
  <div class="animated fadeIn">
    <div v-if="board && board.isLoaded" class="kanban-main-container">
      <div class="w-100 px-3 kanban-nav">
        <h5 class="pt-2"><i class="fas fa-address-card"></i> {{board.name}} &nbsp;&nbsp;|&nbsp;&nbsp; <b-button class="btn btn-sm btn-third rounded-4" size="sm" @click="showModalNewLead" v-if="hasRole(this.roles.RW_CRM_ADDEDLEAD)"><i class="fas fa-user-plus"></i>&nbsp; Nova Oportunidade</b-button></h5> 
        <div class="pt-2">
          <a href="javascript:void(0)" class="pl-4" v-if="false" @click="onReloadBoard"><i class="fas fa-sync-alt f-18px text-third" v-b-tooltip.hover title="Recarregar"></i></a>
          <!-- <b-dropdown size="lg" variant="link" menu-class="mt-4 bg-third" toggle-class="text-decoration-none p-0" class="pl-4 pr-2" style="top: -3px" no-caret>
            <template #button-content>
              <i class="fas fa-search f-18px text-third" v-b-tooltip.hover title="Pesquisar" />
            </template>
            <b-dropdown-form form-class="m-0 px-2 py-2">
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="search"
                  style="min-width: 200px;"
                  id="dropdown-search"
                  size="sm"
                  placeholder="Pesquisar.."
                  autofocus
                ></b-form-input>
              </div>
            </b-dropdown-form>
          </b-dropdown> -->
          <a href="javascript:void(0)" class="pl-4" @click="showSearch"><i class="fas fa-search f-18px" v-b-tooltip.hover title="Pesquisa"></i></a>
          <span class="pl-4" style="height: 92px;border-right: solid 1px #efefef;" v-if="hasRole(this.roles.MENU_DASHBOARD)"></span>   
          <a href="javascript:void(0)" class="pl-4" @click="showReport" v-if="hasRole(this.roles.MENU_DASHBOARD)"><i class="fas fa-chart-pie f-18px" v-b-tooltip.hover title="Dashboard"></i></a>
          <span class="pl-4" style="height: 92px;border-right: solid 1px #efefef;"></span>
          <a href="javascript:void(0)" class="pl-4" @click="showModalConfig" v-if="hasRole(this.roles.RW_CRM_BOARDSETTINGS)"><i class="fas fa-cogs f-18px" v-b-tooltip.hover title="Configurações"></i></a>
          <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret class="pl-2" style="top: -3px" disabled>
            <template #button-content>
              <i class="fas fa-ellipsis-v f-18px text-third" v-b-tooltip.hover title="Opções"></i>
            </template>
            <b-dropdown-item href="#" disabled>Novo Quadro</b-dropdown-item>
            <b-dropdown-item href="#" disabled>Exluir Quadro</b-dropdown-item>
          </b-dropdown>
          
          <a href="javascript:void(0)" class="pl-4 pr-4 d-none"><i class="fas fa-ellipsis-v f-18px" v-b-tooltip.hover title="Opções"></i></a>
        </div>
      </div>
      <div class="kanban-container">
        <div class="kanban-board pl-3">       
          <div
            v-for="column in board.boardColumns"
            :key="column.name"
            class="kanban-column"
          >
            <div class="kanban-column-title d-flex justify-content-between" style="padding-bottom: 5px;">
              <span>{{ column.name }} - <strong>{{ column.totalLeads ? column.totalLeads : '0' }}</strong></span>
              <small>
                <i class="fas fa-bolt text-warning" v-if="column.mnemonic && column.mnemonic === 'SCHEDULING'" style="font-size: 14px; padding-top: 3px; padding-right: 5px" v-b-tooltip.hover title="Data de Agendamento será solicitada!"></i> 
                <i class="fas fa-bolt text-warning" v-else-if="column.mnemonic && column.mnemonic === 'RESCHEDULE'" style="font-size: 14px; padding-top: 3px; padding-right: 5px" v-b-tooltip.hover title="Data de Reagendamento será solicitada!"></i> 
                <i class="fas fa-bolt text-warning" v-else-if="column.mnemonic && column.mnemonic === 'ACCOMPLISHED'" style="font-size: 14px; padding-top: 3px; padding-right: 5px" v-b-tooltip.hover title="Data de Comparecimento será solicitado!"></i> 
                <i class="fas fa-funnel-dollar"></i> 
                {{toCurrency(column.totalValue)}}
              </small>
            </div>
            <div class="autoscroll">
              <draggable
                :list="column.cards ? column.cards : column.cards = []"
                v-on:change="onMove($event, column)"
                :animation="200"
                ghost-class="ghost-card"
                group="tasks"
                class="draggable-area"
              >
                <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                <task-card
                  v-if="column.cards && column.cards.length > 0"
                  v-for="(card, index) in column.cards"
                  :key="card.id"
                  :task="card"
                  :color="column.color"
                  :boardColumnID="column.id"
                  :boardPosition="column.position"
                  :index="index"
                  v-on:Open="showLead(card.id)"
                ></task-card>
              </draggable>
            </div>
          </div>
        </div>
        <div class="board-reload">
          <b-button class="btn btn-sm btn-third rounded-4" size="sm" @click="onLoadBoardPaging" style="border-radius: 5px;"><i class="fas fa-angle-double-down"></i>&nbsp; <strong>EXIBIR MAIS</strong>&nbsp;</b-button>
        </div>
      </div>
    </div>
    <div v-else class="kanban-main-container">
      <div style="padding-top: 15%; width: 100%; text-align: center;">
        <h1>Aguarde! Carregando...</h1>
      </div>
    </div>
    <ModalLeadAdd
      ref="modal-new-lead"
      :boardColumnID="board.boardColumns[0].id"
      v-on:Created="onAddOpportunity"
    />
    <ModalConfiguration
      ref="modal-configuration"
      :board="board"
      :columns="board.boardColumns"
      v-on:save="onSaveConfig"
    />
    <ModalDynamicForm
      :columns="board.boardColumns"
      ref="modal-dynamic-form"
      @saved="onChangeBoard"
      @reload="onReloadBoard()"
    />
  </div>
</template>

<script>
import Tools from '@/components/tools/Tools.js'
import { loading, toast } from '@/shared/utils/UIHelper'
import BoardRepository from '@/shared/http/repositories/onboarding/board.js'

import Draggable from 'vuedraggable'
import TaskCard from '../../../components/superdental/TaskCard.vue'
import ModalLeadAdd from './modal/add/add.vue'
import ModalConfiguration from './modal/configuration/configuration.vue'
import ModalDynamicForm from './modal/form/form.vue'

import Roles from '@/shared/utils/roles.js'
import PermissionMixin from '@/store/module/permission/mixin'

import SearchScreen from './search/search.vue'

const DEFAULT_PAGING = {
  pageIndex: 1,
  pageLimit: 7,
}

export default {
  name: 'Kanban',
  mixins: [ PermissionMixin ],
  components: {
    TaskCard,
    Draggable,
    ModalLeadAdd,
    ModalConfiguration,
    ModalDynamicForm
  },
  data() {
    return {
      roles: Roles,
      filters: null,
      board: {
        isLoaded: false,
        isUpdating: false
      },
      pageIndex: DEFAULT_PAGING.pageIndex,
      pageLimit: DEFAULT_PAGING.pageLimit,
      lastScroll: 0,
      countScroll: 0
    }
  },
  mounted () {
    this.onLoad()
  },
  beforeDestroy () {
    this.$eventHub.off('Filter')
    Tools.MinimizedASidebar()
  },
  methods: {
    onLoad () {
      loading.push()
      this.onLoadBoard()
      this.$eventHub.on('Filter', ({ filters }) => {
        this.filters = filters
        loading.push()
        this.$nextTick(() => {
          this.pageIndex = DEFAULT_PAGING.pageIndex,
          this.pageLimit = DEFAULT_PAGING.pageLimit,
          this.onClearBoard()
          this.onLoadBoard()
        })
        loading.pop()
      })
    },
    onClearBoard () {      
      if(this.board != null && this.board.boardColumns) {           
        this.board.boardColumns.forEach((element, index) => {
          this.board.boardColumns[index].cards = null
        })
        this.board = null
      }
    },
    async onReloadBoard () {
      this.pageIndex = DEFAULT_PAGING.pageIndex,
      this.pageLimit = DEFAULT_PAGING.pageLimit,
      this.board = null
      await this.onLoadBoard()
    },
    onCheckParseUrl () {
      let result = false
      let currentUrl = window.location.pathname;
      let option = currentUrl.split('/')
      if (option.length == 2) {
        result = true
      }
      return result
    },
    onLoadBoardPaging () {
      this.pageIndex++
      this.onLoadBoard()
    },
    async onLoadBoard () {
      loading.push()
      if (this.filters) {
        await BoardRepository.SearchFavoritePaging(this.pageLimit, this.pageIndex, this.filters)
          .then(res => {
            let { code, data } = res.data
            // console.log('search', data)
            if (code === 200 && data && data.id) {
              if(this.board != null && this.board.boardColumns) {   
                this.board.boardColumns.forEach((element, index) => {
                  if (data && data.boardColumns) {
                    let newBoardColumns = data.boardColumns.find(x => x.id == element.id)
                    if (newBoardColumns && newBoardColumns.cards && newBoardColumns.cards.length > 0) {
                      let a = this.board.boardColumns[index].cards.concat(newBoardColumns.cards)
                      this.board.boardColumns[index].cards = a
                    }
                  }
                }); 
                this.board = {
                  isLoaded: true,
                  ...this.board
                }           
              } else {         
                this.board = {
                  isLoaded: true,
                  ...data
                }
              }          
            }
            else {
              this.board = {
                isLoaded: false
              }
            }
            loading.pop()
          })
          .catch(this.onException)
      } else {
        await BoardRepository.GetFavoritePaging(this.pageLimit, this.pageIndex)
          .then(res => {
            let { code, data } = res.data

            if (code === 200 && data && data.id) {
              if(this.board != null && this.board.boardColumns) {   
                this.board.boardColumns.forEach((element, index) => {
                  if (data && data.boardColumns) {
                    let newBoardColumns = data.boardColumns.find(x => x.id == element.id)
                    if (newBoardColumns && newBoardColumns.cards && newBoardColumns.cards.length > 0) {
                      let a = this.board.boardColumns[index].cards.concat(newBoardColumns.cards)
                      this.board.boardColumns[index].cards = a
                    }
                  }
                }); 
                this.board = {
                  isLoaded: true,
                  ...this.board
                }           
              } else {         
                this.board = {
                  isLoaded: true,
                  ...data
                }
              }          
            }
            else {
              this.board = {
                isLoaded: false
              }
            }
            loading.pop()
          })
          .catch(this.onException)
      }
    },
    handleScroll () {
      let scroll = window.scrollY;
      if(scroll > this.lastScroll) {
        if (this.countScroll > 30) {
          this.pageIndex++
          this.onLoadBoard()
          this.countScroll = 0
        }
        this.lastScroll = scroll
        this.countScroll++
      } else {
        this.countScroll--
      }
      // console.log(scroll + ' ' + this.countScroll)
    },
    onGetTotalColumn (column) {

    },
    onSaveConfig () {
      this.onReloadBoard()
    },
    showModalConfig () {
      this.$refs['modal-configuration'].show()
    },
    showModalNewLead () {
      this.$refs['modal-new-lead'].show()
    },
    showLead (cardID) {
      this.$router.push({ name: 'QuadroDetalhes', params: { cardID} })
    },
    showReport () {
      this.$router.push({ name: 'Dashboard' })
    },
    onAddOpportunity () {
      // this.onLoadBoard()
      this.onReloadBoard()
    },
    toCurrency (number) {
      return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(number)
    },
    onChangeBoard () {
      this.$nextTick(() => {
        let cards = this.board.boardColumns.map(bc => bc.cards.map(c => {
          return {
            id: c.id,
            boardColumnID: c.boardColumnID,
            position: c.position
          }
        })).flat()

        let data = {
          id: this.board.id,
          cards
        }
        BoardRepository.MoveCard(data)
          .then(res => {
            setTimeout(() => {
              this.onReloadBoard()
            }, 300);           
          })
          .catch(er => {
            console.log(er)
          })
      })
    },
    async onRemove (event, column) {

    },
    async onMove (event, column) {
      if (Object.keys(event).includes("added")) {
        let columnIndex = this.board.boardColumns.findIndex(c => c.id === column.id)
        if (columnIndex >= 0) {
          let cardIndex = this.board.boardColumns[columnIndex].cards.findIndex(c => c.id === event.added.element.id)
          if (cardIndex >= 0) {
            if(column && column.mnemonic) {              
              switch(column.mnemonic.toUpperCase()) {
              case 'SCHEDULING':
                this.$refs['modal-dynamic-form'].show('Data de Agendamento', columnIndex, cardIndex, 'scheduling')
                break
              case 'RESCHEDULE':
                this.$refs['modal-dynamic-form'].show('Data de Reagendamento', columnIndex, cardIndex, 'reschedule')
                break
              case 'ACCOMPLISHED':
                this.$refs['modal-dynamic-form'].show('Data de Comparecimento', columnIndex, cardIndex, 'accomplished')
                break
              default:
                await this.onChangeBoard()
                break
              }
            } else {
              await this.onChangeBoard()
            }
          }
        }
      }
    },
    showSearch () {
      let aside = {
        title: 'Pesquisa',
        self: this,
        component: SearchScreen
      }
      this.$eventHub.emit('Search', aside)
    },
    hasRole (role) {
      var result = false
      if (this.permissions) {
        for (var i in this.permissions) {
          if (this.permissions[i].role === role) {
            result = true
          }
        }
      }
      return result
    }
  }
}
</script>

<style scoped>
.column-width {
  min-width: 320px;
  width: 315px;
}
/* Unfortunately @apply cannot be setup in codesandbox, 
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}
.f-18px {
  font-size: 18px;
}/*
.text-vertical {
  transform: rotate(270deg);
  top: 50%;
  position: relative;
  display: block;
}
*/
.board-reload {
  display: block;
  position: fixed;
  bottom: 5px;
  height: 35px;
  width: 40%;
  left: 46.3%;
  font-size: 20px;
}

.board-result {
  display: block;
  position: fixed;
  bottom: 0px;
  border: solid 1px #ccc;
  background-color: #ccc;
  height: 35px;
  width: 40%;
  left: 30%;
  font-size: 20px;
  /*
  left: 99%;
  position: absolute;
  transform: translateX(-50%);
  border: solid 1px #ccc;
  background-color: #ccc;
  height: 100%;
  width: 3%;
  font-size: 18px;
  */
}
.board-result-sucess {
  border: solid 1px #28a745;
  background-color: #28a745;
  color: #fff;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  height: 100%;
  /*
  width: 100%;
  border: solid 1px #28a745;
  background-color: #28a745;
  height: 50%;
  color: #fff;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  */
}
.board-result-lost {
  width: 50%;
  height: 100%;
  border: solid 1px #ff0000;
  background-color: #ff0000;
  color: #fff;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  float: left;
  /*
  width: 100%;
  border: solid 1px #ff0000;
  background-color: #ff0000;
  height: 50%;
  color: #fff;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  */
}

.card-empty {
  text-align: center;
  border: dotted 1px #c1c1c1;
  padding: 40px;
  border-radius: 7px;
  margin-top: 14px;
}

.autoscroll {
  height: 78vh;
  overflow: hidden;
}
.autoscroll:hover {
  overflow: auto;
  padding-right: 1px;
}
</style>
