<template>
  <div class="animated fadeIn">
    <!--size="xl"-->
    <b-modal ref="modal-configuration" id="modal-configuration" title="Configuração">
      <template #modal-header>
        <div class="w-100">
          <h5 class="text-bold"><i class="fas fa-cogs"></i> Configuração</h5>
        </div>
      </template>

      <div class="row">
        <div class="col-md-12">
          <h5 class="text-third"><i class="fas fa-font" /> Título</h5>
        </div>

        <div id="board-details" class="col-md-12 mb-1">
          <b-form-input
            v-model="localBoard.name"
          />
        </div>

        <div class="col-md-12">
          <hr/>
          <h5 class="text-third m-0"><i class="fas fa-columns" /> Colunas</h5>
        </div>

        <div class="col-md-12 m-0 d-flex justify-content-between align-items-center">
          <p class="p-0 m-0 detail">Arraste para alterar as posições</p>
          <button type="button" class="btn btn-third btn-sm" @click="onAdd"><i class="fas fa-plus-circle" /> Adicionar</button>
        </div>

        <div class="col-md-12">
          <draggable :list="localColumns" handle=".handle" v-on:update="onMoveColumns" :animation="200" ghost-class="ghost-card" class="draggable-area">
            <div
              v-for="(column, index) in localColumns" :key="index"
              class="draggable-column column-configuration pointer"
            >
              <div class="handle mx-1"><i class="fas fa-grip-vertical"/></div>
              <div
                v-if="column.id !== editing.columnID"
                class="editable-area"
              >
                <span v-if="column.value" class="text-danger text-bold">{{column.value}}%</span> {{column.name}}
              </div>
              <div v-else class="w-100 d-flex align-items-center">
                <b-form-input
                  autofocus
                  id="editing-title"
                  v-model="column.name"
                  class="editing-hidden"
                />
                <b-form-input
                  id="editing-value"
                  type="number"
                  placeholder="%"
                  v-model="column.value"
                  style="width: 50px;"
                  class="editing-hidden ml-2"
                />
                <color-picker
                  v-model="column.color"
                  :position="{ top: '25px', left: '5px' }"
                />
              </div>

              <b-dropdown variant="link" size="sm" no-caret class="mx-2" v-if="column.id">
                <template slot="button-content" v-if="column.mnemonic">
                  <i class="fas fa-bolt text-warning icone-grid" v-b-tooltip.hover title="Clique para editar a ação" style="padding-top: 3px;" /><span class="sr-only">Clique para adicionar uma ação</span>                  
                </template>
                <template slot="button-content" v-else>
                  <i class="fas fa-bolt icone-grid" v-b-tooltip.hover title="Clique para adicionar uma ação" style="padding-top: 3px; color: #ccc" /><span class="sr-only">Clique para adicionar uma ação</span>
                </template>
                <b-dropdown-header><i class="fas fa-bolt"/>&nbsp;&nbsp;&nbsp;<strong>Selecione uma Ação</strong></b-dropdown-header>                
                <b-dropdown-item href="javascript:void(0);" @click="onSetActioMmnemonic(column, index, 'SCHEDULING')">
                  <i class="fas fa-calendar-alt text-primary"></i>
                  Solicitar Agendamento <i class="fas fa-check pl-3 text-success" v-if="column.mnemonic === 'SCHEDULING'"></i> 
                </b-dropdown-item>
                <b-dropdown-item href="javascript:void(0);" @click="onSetActioMmnemonic(column, index, 'RESCHEDULE')">
                  <i class="fas fa-calendar-times text-warning"></i>
                  Solicitar Reagendamento <i class="fas fa-check pl-3 text-success" v-if="column.mnemonic === 'RESCHEDULE'"></i>
                </b-dropdown-item>
                <b-dropdown-item href="javascript:void(0);" @click="onSetActioMmnemonic(column, index, 'ACCOMPLISHED')">
                  <i class="fas fa-calendar-check text-success"></i>
                  Solicitar Comparecimento <i class="fas fa-check pl-3 text-success" v-if="column.mnemonic === 'ACCOMPLISHED'"></i>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item href="javascript:void(0);" @click="onSetActioMmnemonic(column, index, null)">
                  <i class="fas fa-trash-alt text-danger"></i>
                  Nenhuma ação <i class="fas fa-check pl-3 text-success" v-if="!column.mnemonic"></i>
                </b-dropdown-item>
              </b-dropdown>
              
              <i v-if="column.id !== editing.columnID" class="fas fa-edit text-third mx-2" @click.stop.prevent="onEdit(column)" v-b-tooltip.hover title="Clique para Editar a Coluna"/>
              <i v-else class="fas fa-check text-success mx-1" @click="onSave(column)" v-b-tooltip.hover title="Clique para Salvar Edição"/>

              <i v-if="column.id !== editing.columnID" class="fas fa-minus-circle text-danger mx-2" @click="onRemove(column)" v-b-tooltip.hover title="Clique para Remover a Coluna" />
              <i v-else class="fas fa-times-circle text-danger mx-1" @click="onCancel(column)" v-b-tooltip.hover title="Clique para Cancelar Edição"/>

              <!-- <i v-if="column.id !== editing.columnID" class="fas fa-bolt text-warning mx-2" @click.stop.prevent="onShowEvents(column)"/> -->
            </div>
          </draggable>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100 text-right">
          <button
            type="button"
            class="btn btn-third btn-sm"        
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonSave"
            @click="onSaveBoard"
          >
            <i class="fa fa-check-circle" /> {{ $lang.Common.Buttons.Save }}
          </button>&nbsp;
          <button
            type="button"
            class="btn btn-default btn-sm text-secundary"
            @click="hide"
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonClose"
          >
            <i class="fas fa-times-circle"></i> {{ $lang.Common.Buttons.Close }}
          </button>
        </div>
      </template>
      <EventsModal ref="modal-events"/>
    </b-modal>
  </div>
</template>
<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

import Guid from '@/shared/utils/guid.js'
import { toast } from '@/shared/utils/UIHelper'

import Draggable from 'vuedraggable'
import EventsModal from './column/event.vue'

import BoardRepository from '@/shared/http/repositories/onboarding/board.js'

export default {
  name: 'Configuration',
  mixins: [
    ActionForms
  ],
  components: {
    Draggable,
    EventsModal
  },
  props: {
    board: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      localColumns: null,
      localBoard: null,
      editing: {
        columnID: null,
        oldTitle: null,
        oldValue: null,
        oldColor: null
      }
    }
  },
  beforeMount () {
    this.localColumns = JSON.parse(JSON.stringify(this.columns))
    this.localBoard = JSON.parse(JSON.stringify(this.board))
    this.editing.columnID = null
  },
  methods: {
    show () {
      this.$refs['modal-configuration'].show()
      this.localColumns = JSON.parse(JSON.stringify(this.columns))
      this.localBoard = JSON.parse(JSON.stringify(this.board))
      this.editing.columnID = null
    },
    hide () {
      this.$refs['modal-configuration'].hide()
    },
    onMoveColumns () {
      let localColumns = []

      this.localColumns.map((column, index) => {
        column.position = index
        localColumns.push(column)
      })

      this.localColumns = localColumns
    },
    onSaveBoard () {
      if (this.editing.columnID === null) {
        let data = {
          id: this.localBoard.id,
          name: this.localBoard.name,
          boardColumns: this.localColumns
        }

        BoardRepository.SaveConfiguration(data)
          .then(res => {
            let { code, data } = res.data

            if (code === 200 && data && data.id){
              toast.success('Configurações salvas com sucesso.')
              this.$emit('save')
              this.hide()
            }
          })
          .catch(this.onException)
      } else {
        toast.warning('Salve ou cancele a edição da coluna para gravar as alterações!')
      }
    },
    onSave () {
      this.editing.columnID = null
    },
    onAdd () {
      let id = Guid.new()
      this.localColumns.unshift({
        id,
        name: 'Nova Coluna',
        value: null,
        color: 'blue',
        mnemonic: null
      })
    },
    onEdit (column) {
      this.editing.columnID = column.id
      this.editing.oldTitle = column.title
      this.editing.oldValue = column.value
      this.editing.oldColor = column.color
    },
    onCancel (column) {
      column.title = this.editing.oldTitle
      column.value = this.editing.oldValue
      column.color = this.editing.oldColor
      
      this.$nextTick(() => {
        this.editing.columnID = null
        this.editing.oldTitle = null
        this.editing.oldValue = null
        this.editing.oldColor = null
      })
    },
    onRemove (column) {
      let index = this.localColumns.findIndex(c => c.id === column.id)
      this.localColumns.splice(index, 1)
    },
    onShowEvents (column) {
      this.$refs['modal-events'].show(column.id)
    },
    onSetActioMmnemonic(column, index, mnemonic) {      
      if (column && column.id) {
        this.localColumns[index].mnemonic = mnemonic       
      }
    }
  }
}
</script>