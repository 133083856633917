<template>
  <div class="animated fadeIn">
    <h3 class="pt-2 pb-2"><i class="fas fa-bell"></i> Notificações</h3>
    <Tables ref="tableGrid"
            :columns="['Lembrar em',
                       'Lido',
                       'Contato',
                       'Mensagem',
                       'Forma de contato',
                       $lang.Common.DataTable.Status,
                       $lang.Common.DataTable.ActionDate,
                       $lang.Common.DataTable.AccountAction,
                       $lang.Common.DataTable.Actions]"
            :showReload="true"
            :showTipFooter="false"
            :showSearch="true"
            v-on:Load="onLoad"
            v-on:Reload="onLoad"
            v-on:Search="onSearch"
    >
      <template v-slot:table-header-buttons>
        <a href="javascript:void(0);" class="d-md-down-none" v-b-tooltip.hover :title="'Exibir lembretes já lidos!'" @click="onHideReminderRead" v-if="hideReminderRead">
          <i class="fas fa-bell text-third" style="font-size: 15px"></i>
        </a>
        <a href="javascript:void(0);" class="d-md-down-none" v-b-tooltip.hover :title="'Ocultar lembretes já lidos!'" @click="onHideReminderRead" v-else>
          <i class="fas fa-bell-slash text-third" style="font-size: 15px"></i>
        </a>
      </template>
      <tr v-for="obj in this.objList" :key="obj.id">
        <TableColumn :IsReminder="true" v-on:Edit="onEdit(obj)" :status="(obj.lateReminder ? 0 : obj.status)" :value="obj.reminder" :style="[obj.reminderRead ? { 'opacity': '0.4' } : {}]"/>
        <TableColumn :IsCustom="true" :status="obj.status" :style="[obj.reminderRead ? { 'opacity': '0.4' } : {}]">
          <span v-if="obj.reminderRead"><a ref="javascript:void(0);" style="color: #03983f; cursor: pointer" v-on:Edit="onEdit(obj)"><strong>{{$lang.Common.Yes}}</strong></a></span>
          <span v-else-if="obj.lateReminder"><a ref="javascript:void(0);" class="text-danger" style="cursor: pointer" v-on:Edit="onEdit(obj)"><strong>{{$lang.Common.No}}</strong></a></span>
          <span v-else><a ref="javascript:void(0);" style="color: #3f729b; cursor: pointer" v-on:Edit="onEdit(obj)"><strong>{{$lang.Common.No}}</strong></a></span>
        </TableColumn>
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="(obj.lateReminder ? 0 : obj.status)" :value="obj.card && obj.card.opportunity ? obj.card.opportunity.name : ''" :style="[obj.reminderRead ? { 'opacity': '0.4' } : {}]"/>
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="(obj.lateReminder ? 0 : obj.status)" :value="obj.description" classCss="textMessagem" :style="[obj.reminderRead ? { 'opacity': '0.4' } : {}]"/>
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="(obj.lateReminder ? 0 : obj.status)" :value="obj.contactType ? obj.contactType.name.toUpperCase() : ''" :style="[obj.reminderRead ? { 'opacity': '0.4' } : {}]"/>
        <TableColumn :IsStatus="true" v-on:Edit="onEdit(obj)" :status="obj.status" :style="[obj.reminderRead ? { 'opacity': '0.4' } : {}]"/>
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="(obj.lateReminder ? 0 : obj.status)" :value="obj.dateAction" :style="[obj.reminderRead ? { 'opacity': '0.4' } : {}]"/>
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="(obj.lateReminder ? 0 : obj.status)" :value="obj.accountUserNameAction" :style="[obj.reminderRead ? { 'opacity': '0.4' } : {}]"/>
        <TableColumn :IsCustom="true" :status="obj.status" :style="[obj.reminderRead ? { 'opacity': '0.4' } : {}]">
          <span v-if="obj.reminderRead"><i class="fas fa-bell-slash" style="opacity: 0.4;"></i></span>
          <span v-else-if="obj.lateReminder" v-b-tooltip.hover :title="'Lembre atrasado! Clique para marcar o lembrete como lido!'" @click="onReminderReader(obj)"><a href="javascript:void(0);" class="text-danger"><i class="fas fa-exclamation-triangle"></i></a></span>
          <span v-else v-b-tooltip.hover :title="'Clique para marcar o lembrete como lido!'" @click="onReminderReader(obj)"><a href="javascript:void(0);"><i class="fas fa-bell"></i></a></span>
        </TableColumn>
      </tr>
    </Tables>
  </div>
</template>
<script>
import Tools from '@/components/tools/Tools.js'
import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'
import FollowUpRepository from '@/shared/http/repositories/onboarding/followup.js'
import SearchScreen from './search/notificationsearch.vue'

export default {
  name: 'StartPage',
  mixins: [ActionsForm],
  data () {
    return {
      data: null,
      form: null,
      hideReminderRead: true
    }
  },
  beforeDestroy () {
    this.$eventHub.off('Filter')
    Tools.MinimizedASidebar()
  },
  mounted () {
    this.$eventHub.on('Filter', ({ filters }) => {
      this.filters = filters
      this.$refs.tableGrid.Clear()
      this.$nextTick(() => {
        this.onLoadAll()
      })
    })
  },
  methods:{
    onLoad () {
      this.onLoadAll()
    },
    onLoadAll () {
      loading.push()
      if (this.filters) {
        FollowUpRepository.Search(this.filters, this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
          .then((response) => {
            this.onResultGrid(response)
          })
          .catch(this.onException)
      } else {
        FollowUpRepository.GetAllReminderPaging(this.hideReminderRead, this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
          .then((response) => {
            this.onResultGrid(response)
          })
          .catch(this.onException)
      }
    },
    onHideReminderRead () {
      this.hideReminderRead = this.hideReminderRead ? false : true
      this.onLoad()
    },
    onEdit (obj) {
      if (obj) {
        this.$router.push({ name: 'QuadroDetalhes', params: { cardID: obj.cardID, back: 'Notificacao' } })
      }
    },
    onReminderReader (obj) {
      loading.push()
      FollowUpRepository.SetReminderReader(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch(this.onException)
    },
    onSearch () {
      let aside = {
        title: 'Pesquisar',
        self: this,
        component: SearchScreen
      }
      this.$eventHub.emit('Search', aside)
    }
  }
}
</script>
<style scoped>
.textMessagem {
  text-align: justify;
}
</style>
