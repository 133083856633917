import APIClient from '@/shared/http/clients/api'

const resourceName = 'card'
const APIAccess = process.env.VUE_APP_ONBOARDING

const Get = (cardID, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/${cardID}`, config)
const GetAll = (data = {}, config = {}) => APIClient.get(`${APIAccess}/${resourceName}`, data, config)
const GetAllPaging = (limit, index, config = {}) => APIClient.get(`${APIAccess}/${resourceName}?limit=${limit}&index=${index}`, config)
const Update = (data = {}, config = {}) => APIClient.put(`${APIAccess}/${resourceName}`,data, config)
const UpdateProduct = (cardID, data = {}, config = {}) => APIClient.patch(`${APIAccess}/${resourceName}/${cardID}/product`, data, config)
const UpdateStatus = (cardID, status, data, config = {}) => APIClient.patch(`${APIAccess}/${resourceName}/${cardID}/status/${status}`, data, config)
const UpdateCompanyBranch = (cardID, data = {}, config = {}) => APIClient.put(`${APIAccess}/${resourceName}/${cardID}/organization`, data, config)
const Delete = (cardID, data, config = {}) => APIClient.delete(`${APIAccess}/${resourceName}/${cardID}`, data, config)
const Search = (limit, index, filters, config = {}) => APIClient.post(`${APIAccess}/${resourceName}/search?limit=${limit}&index=${index}`, filters, config)

const CardRepository = {
  Get,
  GetAll,
  GetAllPaging,
  Update,
  UpdateProduct,
  UpdateStatus,
  UpdateCompanyBranch,
  Delete,
  Search
}

export default CardRepository
