<template>
  <navbar>
    <button
      class="navbar-toggler mobile-sidebar-toggler d-lg-none"
      type="button"
      @click="mobileSidebarToggle"
    >
      &#9776;
    </button>
    <router-link
      :to="{ name: 'StartPage' }"
      class="navbar-brand"
    >
      <small :class="display" />
    </router-link>
    <ul class="nav navbar-nav d-md-down-none">
      <li class="nav-item">
        <a
          class="nav-link navbar-toggler sidebar-toggler"
          href="#"
          @click="sidebarMinimize"
        >&#9776;</a>
      </li>
      <li class="nav-item px-3">
        <a
          class="nav-link"
          href="javascript:void(0)"
          @click="toggleFullScreen"
        ><i
          class="fas fa-expand-arrows-alt"
          v-b-tooltip.hover
          :title="$lang.Common.Buttons.Fullscreen"
        /></a>
      </li>
    </ul>
    <ul class="nav navbar-nav ml-auto">
      <li class="divider-vertical d-md-down-none" />
      <li class="nav-item d-sm-down-none">
        <a class="nav-link" href="#" v-b-tooltip.hover title="Dicas da Saludigital"><i class="fas fa-flag" /></a>
      </li>
      <li class="nav-item d-sm-down-none">
        <a v-if="NotificationsCount" class="nav-link" href="javascript:void(0);" v-b-tooltip.hover title="Lembretes" @click="onGotToNotification"><i class="fas fa-bell"></i><span class="badge badge-pill badge-danger">{{NotificationsCount}}</span></a>
        <a v-else class="nav-link" href="javascript:void(0);" v-b-tooltip.hover title="Lembretes" @click="onGotToNotification"><i class="fas fa-bell"></i><span class="badge badge-pill badge-default">0</span></a>
      </li>
      <li v-if="false" class="divider-vertical d-md-down-none" />
      <li v-if="false" class="nav-item d-sm-down-none">
        <a class="nav-link" href="javascript:void(0);" @click="asideToggle"><i class="fas fa-comments" /></a>
      </li>
      <li v-if="false" class="nav-item d-md-down-none">
        <a class="nav-link" href="javascript:void(0);"><i class="fas fa-envelope" /><span v-if="false" class="badge badge-pill badge-default">0</span></a>
      </li>
      <li class="divider-vertical d-md-down-none mr-3" />

      <b-dropdown
        variant="link"
        no-caret
      >
        <template slot="button-content">
          <b-avatar class="mr-2" />
          <span
            class="d-md-down-none"
            style="min-width: 190px; color: #fff;"
          >{{ AccountName }}&nbsp;</span>
          <i
            class="fa fa-caret-down"
            style="color:#fff;"
          />
        </template>
        <b-dropdown-item href="javascript:void(0)" @click="onMenuMyAccount"><i class="fa fa-user text-third"></i> Minha Conta</b-dropdown-item>
        <b-dropdown-item v-if="false" href="javascript:void(0)"><i class="fas fas fa-credit-card text-third"></i> Minha Assinatura</b-dropdown-item>
        <div v-if="hasRole(this.roles.MENU_SETTINGS)"><b-dropdown-item href="javascript:void(0)" @click="onMenuSettings"><i class="fas fa-cogs text-third"></i> Configurações</b-dropdown-item></div> 
        <b-dropdown-item href="javascript:void(0)"><i class="fas fa-life-ring text-third"></i> Suporte <i class="fas fa-external-link-alt" style="padding-left: 110px;"></i></b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item
          href="javascript:void(0)"
          @click="onSignOut"
        >
          <i class="fas fa-sign-out-alt" /> Sair
        </b-dropdown-item>
      </b-dropdown>

      <li class="pr5 d-md-down-none">
&nbsp;
      </li>
      <!--
      <li class="nav-item d-md-down-none">
        <a class="nav-link navbar-toggler aside-menu-toggler" href="#" @click="asideToggle">&#9776;</a>
      </li>
      -->
    </ul>
  </navbar>
</template>
<script>
import { toast } from '@/shared/utils/UIHelper'
import AccountMixin from '@/store/module/account/mixin'
import Roles from '@/shared/utils/roles.js'
import { JWTService } from '@/shared/services/jwt'
import router from '@/router'
import navbar from './Navbar'
import PermissionMixin from '@/store/module/permission/mixin'
import NotificationMixin from '@/store/module/notification/mixin'
import { actionTypes as NotificationActions } from '@/store/module/notification/actions.js'
import Tools from '@/components/tools/Tools.js'

export default {
  name: 'HeaderApp',
  mixins: [AccountMixin, PermissionMixin, NotificationMixin],
  data () {
    return {
      display: '',
      imgAvatar: process.env.BaseURL + 'static/img/avatars/02.png',
      roles: Roles,
      reminderInterval: null,
      totalNotifications: 0
    }
  },
  components: {
    navbar
  },
  created () {
    this[NotificationActions.GET_ALL_NOTIFICATIONS](true)
      .then((response) => {
      })
    this.checkReminder()
  },
  beforeDestroy () {
    clearInterval(this.reminderInterval)
  },
  computed: {
    AccountName: function () {
      var result = 'N/D'
      if (this.account && this.account.name) {
        result = this.account.name
      }
      return result
    },
    NotificationsCount: function () {
      var result = 0
      if (this.notifications) {
        result = this.notifications.length
        if (result > this.totalNotifications) {
          let countReminder = (result - this.totalNotifications)
          Tools.ShowNotification('Saludigital', 'Você possui ' + countReminder + ' lembretes!')
          toast.info('Você possui ' + countReminder + ' lembretes!', 'LEMBRETES')         
        }
      }
      return result
    }
  },
  methods: {     
    onLoadReminderStore () {
      this[NotificationActions.GET_ALL_NOTIFICATIONS](true)
        .then((response) => {
        })
    },   
    checkReminder () {
      this.reminderInterval = setInterval(function() {
        this.onLoadReminderStore()
        if (this.notifications) {
          let total = this.notifications.length
          if (total > this.totalNotifications) {
            let countReminder = (total - this.totalNotifications)
            this.totalNotifications = total
            Tools.ShowNotification('Saludigital', 'Você possui ' + countReminder + ' novos lembretes!')
            toast.info('Você possui ' + countReminder + ' novos lembretes!', 'LEMBRETES')
          }
        }                
      }.bind(this), 900000); // 900000 = 15 minutos | 30 Minutos = 1800000 Milissegundos
    },
    onSignOut () {
      JWTService.DestroyToken()
      router.replace('/login')
    },
    sidebarToggle (e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-hidden')
    },
    sidebarMinimize (e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-minimized')
    },
    mobileSidebarToggle (e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-mobile-show')
      // this.display = 'none'
    },
    asideToggle (e) {
      e.preventDefault()
      document.body.classList.toggle('aside-menu-hidden')
    },
    toggleFullScreen () {
      if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        // topMenuData.showSmall = true
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen()
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        }
      } else {
        //  this.topMenuData.showSmall = false
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        }
      }
    },
    onMenuSettings () {
      this.$emit('OpenSettings')
    },
    onMenuMyAccount () {
      this.$router.push({ name: 'MinhaConta' })
    },
    onGotToNotification () {
      this.$router.push({ name: 'Notificacao' })
    },
    hasRole (role) {
      var result = false
      if (this.permissions) {
        for (var i in this.permissions) {
          if (this.permissions[i].role === role) {
            result = true
          }
        }
      }
      return result
    }
  }
}
</script>
<style scoped>
.dropdown-item {
  color: #696a6d;
}
.dropdown-item i {
  color: #236192;
}

</style>
