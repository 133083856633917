import APIClient from '@/shared/http/clients/api'

const resourceName = 'followup'
const APIBOnboarding = process.env.VUE_APP_ONBOARDING

const GetAll = (data = {}, config = {}) => APIClient.get(`${APIBOnboarding}/${resourceName}`, data, config)
const GetAllPaging = (limit, index, config = {}) => APIClient.get(`${APIBOnboarding}/${resourceName}?limit=${limit}&index=${index}`, config)
const GetAllReminder = (hideRead, config = {}) => APIClient.get(`${APIBOnboarding}/${resourceName}/reminder/${hideRead}`, config)
const GetAllReminderPaging = (hideRead, limit, index, config = {}) => APIClient.get(`${APIBOnboarding}/${resourceName}/reminder/${hideRead}?limit=${limit}&index=${index}`, config)
const GetAllInCard = (cardID, config = {}) => APIClient.get(`${APIBOnboarding}/${resourceName}/card/${cardID}`, config)
const GetById = (id, config = {}) => APIClient.get(`${APIBOnboarding}/${resourceName}/${id}`, config)
const Create = (data = {}, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}`, data, config)
const Update = (data = {}, config = {}) => APIClient.put(`${APIBOnboarding}/${resourceName}`, data, config)
const Delete = (id, config = {}) => APIClient.delete(`${APIBOnboarding}/${resourceName}/${id}`, config)
const SetReminderReader = (id, config = {}) => APIClient.patch(`${APIBOnboarding}/${resourceName}/${id}`, config)
const Search = (data = {}, limit, index, config = {}) => APIClient.post(`${APIBOnboarding}/${resourceName}/reminder/search?limit=${limit}&index=${index}`, data, config)

const FollowUpRepository = {
  GetAll,
  GetAllPaging,
  GetAllReminder,
  GetAllReminderPaging,
  GetAllInCard,
  GetById,
  Create,
  Update,
  Delete,
  SetReminderReader,
  Search
}

export default FollowUpRepository
