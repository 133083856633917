import ActionsForms from '@/shared/mixins/actionsForm.js'
import Tools from '@/components/tools/Tools.js'
import { toast, loading } from '@/shared/utils/UIHelper'
import Roles from '@/shared/utils/roles.js'
import PermissionMixin from '@/store/module/permission/mixin'
import BudgetAddForm from './budgetadd-form.js'
import BudgetRepository from '@/shared/http/repositories/onboarding/budget.js'
import BusinessTypeRepository from '@/shared/http/repositories/settings/businesstype.js'
import CategoryRepository from '@/shared/http/repositories/settings/category.js'
import ProductRepository from '@/shared/http/repositories/settings/product.js'

const BudgetAddModel = {
  id: null,
  cardID: null,
  opportunityID: null,
  productID: null,
  budgetStatus: null,
  value: null
}

export default {
  name: 'BudgetAdd',
  mixins: [
    ActionsForms,
    PermissionMixin
  ],
  data () {
    return {
      data: BudgetAddModel,
      form: BudgetAddForm,
      lead: null,
      roles: Roles
    }
  },
  watch: {
    'form.businessTypeID.value': function (value) {
      if (value !== null) {
        this.onLoadAllCategory(value)
      } else {
        this.form.categoryID.value = null
      }
    },
    'form.categoryID.value': function (value) {
      if (value !== null) {
        this.onLoadAllProducts(value)
      } else {
        this.form.productID.value = null
      }
    }
  },
  computed: {
    cardID () {
      let result = null
      if (this.lead) {
        result = this.lead.id
      }
      return result
    }
  },
  methods: {
    showModal () {
      this.$refs['modal-budget'].show()
      this.onClear()
    },
    hideModal () {
      this.$refs['modal-budget'].hide()
      this.$emit('Reload')
    },  
    onLoad () {
      this.onLoadAllBusinessType()
    },
    onNew (lead) {
      this.lead = lead
      this.showModal()
      this.budgetStatus.value = 0
    },
    onEdit (lead, obj) {
      this.showModal()
      this.lead = lead
      this.data = obj
      this.$nextTick(() => {
        if(obj) {          
          this.form.businessTypeID.value = obj.product && obj.product.category ? obj.product.category.businessTypeID : null
          this.$nextTick(() => {
            this.form.categoryID.value = obj.product ? obj.product.categoryID : null
            this.$nextTick(() => {
              this.form.productID.value = obj.productID
              this.id = obj.id
              this.form.budgetStatus.value = obj.budgetStatus == 0 ? null : obj.budgetStatus
              this.form.opportunityValue.value = obj.value
            })
          })
        }
      })
    },
    onFormatData () {
      if(this.data) {
        this.data.value = this.form.opportunityValue.value
        this.data.budgetStatus = this.form.budgetStatus.value ? this.form.budgetStatus.value : 0
        this.data.opportunityID = this.lead.opportunityID
        this.data.cardID = this.cardID
      }
    },
    onCreate () {
      loading.push()
      this.onFormatData()
      BudgetRepository.Create(this.data)
        .then(response =>  {
          let { code, data } = response.data
          loading.close()
          if (code === 200) {
            toast.success(this.$lang.Common.Alerts.Success)
          }
          this.hideModal()
        })
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      this.onFormatData()
      BudgetRepository.Update(this.data)
      .then(response =>  {
        let { code, data } = response.data
        loading.close()
        if (code === 200) {
          toast.success(this.$lang.Common.Alerts.Success)
        }
        this.hideModal()
      })
        .catch(this.onException)
    },
    onLoadAllBusinessType() {
      BusinessTypeRepository.GetAll()
        .then(res => {
          let { code, data } = res.data
          if(code === 200) {
            this.form.businessTypeID.options = data.map(b => {
              return {
                text: b.name,
                value: b.id
              }
            })
          }
        })
        .catch(this.onException)
    },
    onLoadAllCategory (value) {
      CategoryRepository.GetAll()
        .then(res => {
          let { code, data } = res.data
          if(code === 200) {
            this.form.categoryID.options = data.filter(c => c.businessTypeID === value).map(c => {
              return {
                text: c.name,
                value: c.id
              }
            })
          }
        })
        .catch(this.onException)
    },
    onLoadAllProducts (value) {
      ProductRepository.GetAll()
        .then(res => {
          let { code, data } = res.data
          if(code === 200) {
            this.form.productID.options = data.filter(p => p.categoryID === value).map(p => {
              return {
                text: p.name,
                value: p.id
              }
            })
          }
        })
        .catch(this.onException)
    },
  },
}