import { mapActions } from 'vuex'
import SignInMixin from '@/store/module/signIn/mixin'
import { globalActions as authActions } from '@/store/module/auth/actionTypes'
import { toast } from '@/shared/utils/UIHelper'

export default {
  name: 'Authentication',
  data () {
    return {
      version: process.env.VUE_APP_VERSION
    }
  },
  mixins: [
    SignInMixin
  ],

  methods: {
    ...mapActions({
      signIn: authActions.SIGN_IN
    }),
    validateAndSignIn () {
      this.$validator.validateAll()
        .then((valid) => {
          if (valid) {
            this.signIn(this.credentials)
              .then((success) => {
                if (success) {
                  this.$router.push({ name: 'StartPage' })
                }
              })
              .catch((e) => {
                if (e.message === 'Not authorized') {
                  toast.error(this.$lang.Login.InvalidCredentials)
                } else {
                  toast.error(this.$lang.Login.ServiceOff)
                }
              })
          }
        })
    },
    getValidationState (fieldName) {
      const field = this.$validator.fields.find({ name: fieldName })

      if (!field) return null

      const isDirty = field.flags.dirty
      const isValid = !this.errors.has(fieldName)
      return isDirty ? isValid : null
    }
  }
}
