<template>
  <aside class="aside-menu px-3 pt-3">
    <div class="w-100">
      <h6> 
        {{title}}
        <label class="switch switch-text switch-pill switch-success switch-sm float-right">
          <small class="text-muted" style="font-size: 9px;" @click="closeAside">Fechar</small>
        </label>
      </h6>
      <hr>
    </div>
    <div class="aside-scrollable">
      <component v-if="isShowing" ref="dynamic-screen" :is="component" :parentComponent="self" :params="params"></component>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'AppAside',
  mounted () {
    this.$eventHub.on('Search', (options) => { this.onSearch(options) })
    this.$eventHub.on('ClearSearch', () => { this.onClearSearch() })
    this.$eventHub.on('Screen', (options) => { this.setScreen(options) })
    this.$eventHub.on('ClearScreen', () => { this.onClearScreen() })
    this.$eventHub.on('ShowAside', () => { this.showAside() })
    this.$eventHub.on('CloseAside', () => { this.closeAside() })
    this.$eventHub.on('ClearAll', () => { this.onClearAll() })
  },
  beforeDestroy () {
    this.closeAside()
    this.$eventHub.off('Search')
    this.$eventHub.off('ClearSearch')
    this.$eventHub.off('Screen')
    this.$eventHub.off('ClearScreen')
    this.$eventHub.off('ShowAside')
    this.$eventHub.off('CloseAside')
    this.$eventHub.off('ClearAll')
  },
  data () {
    return {
      title: null,
      self: null,
      component: null,
      params: null
    }
  },
  computed: {
    isShowing: function () {
      return document.body.classList.contains('aside-menu-hidden')
    }
  },
  methods: {
    showAside () {
      if (document.body.classList.contains('aside-menu-hidden')) {
        document.body.classList.toggle('aside-menu-hidden')
      }
    },
    closeAside () {
      if (!document.body.classList.contains('aside-menu-hidden')) {
        document.body.classList.toggle('aside-menu-hidden')
      }
    },
    onClearAll () {
      this.onClearSearch()
      this.closeAside()
    },
    onSearch (options) {
      this.title = options.title
      this.self = options.self
      this.component = options.component
      this.params = options.params
      this.showAside()
    },
    onClearSearch () {
      this.title = null
      this.self = null
      this.component = null
      this.params = null
    },
    setScreen (options) {
      this.title = options.title
      this.self = options.self
      this.component = options.component
      this.params = options.params
      this.showAside()

      this.$nextTick(() => {
        this.$refs['dynamic-screen'].onReload()
      })
    },
    onClearScreen () {
      this.title = null
      this.self = null
      this.component = null
      this.params = null
    }
  }
}
</script>
