import ActionRepository from '@/shared/http/repositories/onboarding/action.js'

export default {
  name: 'Timeline',
  props: {
    personID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      data: []
    }
  },
  mounted () {
    this.onLoadHistory()
  },
  methods: {
    onLoadHistory () {
      ActionRepository.GetHistory(this.personID)
        .then(res => {
          let { code, data } = res.data

          if(code === 200) {
            this.data = data
          }
        })
        .catch(this.onException)
    },
    toCurrency (number) {
      return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(number)
    },
    getDataProduct(value) {
      let result
      if(value) {
        let opt = value.split(';')
        if(opt && opt.length > 0) {
          let productStatus = ''
          switch(opt[2]){
            case 'DISAPPROVED' : productStatus = 'REPROVADO'; break
            case 'PENDENT': productStatus = 'PENDENTE'; break
            case 'APPROVED': productStatus = 'APROVADO'; break
            default: productStatus = 'PENDENTE'; break
          }
          result = opt[0] + " no valor de " + this.toCurrency(opt[1]) + " com a situação da aprovação " + productStatus
        }
      }
      return result
    }
  }
}