<template>
  <div class="row">
    <div
      class="col-12 pb-4"
      v-show="showMessage"
    >
      <div class="required-field-bottom">
        <small
          class="pl-2"
          style="color: #909090;"
        >
          {{ MessageDisplay }}
        </small>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TableEditMessage',
  props: {
    showMessage: {
      type: Boolean,
      required: false,
      default: true
    },
    message: {
      type: String,
      required: false
    }
  },
  computed: {
    MessageDisplay: function () {
      var msg = this.message
      if (this.showMessage) {
        if (!this.message) {
          msg = this.$lang.Common.Click_on_the_above_record_to_edit_it
        }
      }
      return msg
    }
  }
}
</script>
