import FollowUpRepository from '@/shared/http/repositories/onboarding/followup.js'
import { mutationTypes as mutation } from './mutations'

const GET_ALL_NOTIFICATIONS = 'getAllPermissons'

export const actionTypes = {
  GET_ALL_NOTIFICATIONS
}

export const actions = {
  async [GET_ALL_NOTIFICATIONS]({ commit }, payload) {
    FollowUpRepository.GetAllReminder(payload)
      .then((res) => {
        commit(mutation.SET_NOTIFICATIONS, res.data.data)
      })
  }
}
