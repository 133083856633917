export default {
  id: null,
  name: null,
  contact: null,
  contactName: null,
  contactPhone: null,
  contactCellPhone: null,
  email: null,
  phone: null,
  cellPhone: null,
  leadOriginID: null,
  description: null
}
