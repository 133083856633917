<template>
  <div class="animated fadeIn">
    <Alerts ref="alert"/>
    <Forms ref="frmPermission"
           :title="this.$lang.Permission.TitleForm"
           :showHelp="true"
           :showClearButton="true"
           :showSaveButton="true"
           v-on:Clear="onClear"
           v-on:Save="onSave"
    >
      <div class="form-group row">
        <div class="col-md-6">
          <label for="txtName"><small class="text-danger">*</small> {{$lang.Permission.LabelTypePermission}}:</label>
          <div class="input-group">
            <b-form-select v-model="form.permissionType.value" class="mb-3" @change="onChangePermissionType">
              <template slot="first">
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
              <option value="GRP">{{$lang.Permission.OptionGroup}}</option>
              <option value="URS">{{$lang.Permission.OptionUsers}}</option>
            </b-form-select>
          </div>
        </div>
        <div class="col-md-6" v-show="permissionTypeGroup">
          <label for="txtGroup"><small class="text-danger">*</small> {{$lang.Permission.LabelGroups}}:</label>
          <div class="input-group">
            <b-form-select v-model="form.groupID.value" :options="form.groupID.options" class="mb-3" @change="onChangeGroup">
              <template slot="first">
                <!-- this slot appears above the options from 'options' prop -->
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="col-md-6" v-show="permissionTypeUser">
          <label for="txtAccount"><small class="text-danger">*</small> {{$lang.Permission.LabelAccounts}}:</label>
          <div class="input-group">
            <b-form-select v-model="form.accountID.value" :options="form.accountID.options" class="mb-3" @change="onChangeAccount">
              <template slot="first">
                <!-- this slot appears above the options from 'options' prop -->
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group row hidden">
        <div class="col-md-12 pt-1">
          <h5 class="text-third"><i class="fa fa-shield"></i> {{$lang.Permission.Profile}}</h5>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-5">
          <label for="txtName">{{$lang.Permission.LabelAvailable}}:</label>
          <div class="input-group">
            <b-form-select v-model="form.permissionAvailable.value" :options="form.permissionAvailable.options" multiple :select-size="15" class="mb-3" >
            </b-form-select>
          </div>
        </div>
        <div class="col-md-2 text-center">
          <div class="d-none d-md-block">
            <b-button-group style="padding-top: 150px;">
              <button type="button" class="btn btn-default btn text-secundary" @click="onRemovePermissions"><i class="fa fa-arrow-circle-left"></i></button>
              <button type="button" class="btn btn-third btn text-secundary" @click="onAddPermissions"><i class="fa fa-arrow-circle-right"></i></button>
            </b-button-group>
          </div>
          <div class="d-block d-md-none">
            <b-button-group>
              <button type="button" class="btn btn-default btn text-secundary" @click="onRemovePermissions"><i class="fa fa-arrow-circle-up"></i></button>
              <button type="button" class="btn btn-third btn text-secundary" @click="onAddPermissions"><i class="fa fa-arrow-circle-down"></i></button>
            </b-button-group>
          </div>
        </div>
        <div class="col-md-5">
          <label for="txtName">{{$lang.Permission.LabelUsed}}:</label>
          <div class="input-group">
            <b-form-select v-model="form.permissionUsed.value" :options="form.permissionUsed.options" multiple :select-size="15" class="mb-3">
            </b-form-select>
          </div>
        </div>
      </div>
    </Forms>
  </div>
</template>
<script>
import Permission from './permission.js'

export default Permission
</script>
