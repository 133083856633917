import APIClient from '@/shared/http/clients/api'

const resourceName = 'carddetail'
const APIAccess = process.env.VUE_APP_ONBOARDING

const UpdateManager = (cardID, data = {}, config = {}) => APIClient.patch(`${APIAccess}/${resourceName}/${cardID}/manager`,data, config)
const UpdateDate = (cardID, property, date, endDate, config = {}) => APIClient.patch(`${APIAccess}/${resourceName}/${cardID}/${property}${date ? `?date=${date}&endDate=${endDate}` : ''}`, config)

const CardDetailRepository = {
  UpdateManager,
  UpdateDate
}

export default CardDetailRepository
