import ActionForms from '@/shared/mixins/actionsForm.js'
import { loading } from '@/shared/utils/UIHelper'

import FollowUpModel from '@/shared/models/onboarding/carddetails/followup'
import FollowupForm from './followup-form.js'

import ContactTypeRepository from '@/shared/http/repositories/settings/contacttype.js'
import FollowUpRepository from '@/shared/http/repositories/onboarding/followup.js'

import Roles from '@/shared/utils/roles.js'
import PermissionMixin from '@/store/module/permission/mixin'

export default {
  name: 'Followup',
  mixins: [
    ActionForms,
    PermissionMixin
  ],
  props: {
    cardID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      data: FollowUpModel,
      form: FollowupForm,
      dateTime: {
        date: null,
        hour: null
      }
    }
  },
  methods: {
    onLoad () {
      this.onLoadAllContactType()
    },
    onLoadAllContactType () {
      ContactTypeRepository.GetAll()
        .then(res => {
          let { code, data } = res.data

          if(code === 200) {
            this.form.contactTypeID.options = data.map(b => {
              return {
                text: b.name,
                value: b.id
              }
            })
          }
        })
        .catch(this.onException)
    },
    onCreate () {
      loading.push()
      this.data.reminder = ''
      if (this.dateTime.date) {
        this.data.reminder = this.moment(`${this.dateTime.date}T${this.dateTime.hour}:00`).format('MM-DD-YYYY HH:mm')
      }
      this.data.cardID = this.cardID

      FollowUpRepository.Create(this.data)
        .then((response) => {
          this.onResult(response)
          this.dateTime = {
            date: null,
            hour: null
          }
          this.$emit('onAddFollowUp')
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onGotToNotification () {
      this.$router.push({ name: 'Notificacao' })
    }
  }
}