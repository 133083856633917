<template>
  <div class="animated fadeIn">
    <Tables ref="tableGrid"
            :columns="['Cliente',
                       'Reponsável',
                       'Telefone',
                       'Celular',
                       'E-mail',
                       'Produto',
                       'Valor',
                       'Dt. Entrada',
                       'Dt. Perdido',
                       'Motivo',
                       'Realizado por',
                       $lang.Common.DataTable.Actions]"
            :showSearch="false"
            :showPrint="false"
            :showDownload="false"
            :showFullscreen="false"
            :showTipFooter="false"
            v-on:Load="onLoad"
    >
      <tr v-for="obj in this.objList" :key="obj.id">
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.opportunity ? obj.opportunity.name : ''" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="''" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.opportunity ? obj.opportunity.phone : ''" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.opportunity ? obj.opportunity.cellPhone : ''" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.opportunity ? obj.opportunity.email : ''" />
        <TableColumn :IsCustom="true" v-on:Edit="onEdit(obj)" :status="obj.status">
          <div v-if="obj.listProduct && obj.listProduct.length > 0">
            <div v-if="obj.listProduct.length > 1">
              <a href="javascript:void(0)" id="popover-target-1">{{obj.listProduct[0].name}} <br><small class="text-danger">Visualizar todos</small></a>
              <b-popover target="popover-target-1" triggers="hover" placement="right">
                <template #title>Produtos</template>                
                <a href="javascript:void(0)" v-for="(item, index) in obj.listProduct" :key="index">{{ item.name }}<br></a>
              </b-popover>
            </div>
            <div v-else>
              <a href="javascript:void(0)" v-for="(item, index) in obj.listProduct" :key="index">{{ item.name }}</a>
            </div>
          </div>
          <div v-else><a href="javascript:void(0)">N/D</a></div>
        </TableColumn>
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="formatValue(obj.opportunityValue + obj.totalBudget)" />
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.createDate" />        
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.leadStatusDate" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.reasonLoss ? obj.reasonLoss.name : ''" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
        <TableColumn :IsSlot="true" :status="obj.status">
          <span
            style="font-size: 16px; cursor: pointer;"
            class="text-warning"
            v-b-tooltip.hover title="Clique para reativar o Lead"
            @click="onReactive(obj)"
          >
            <i class="fas fa-sync-alt"></i>
          </span>
        </TableColumn>
      </tr>
    </Tables>
  </div>
</template>
<script>
import Tools from '@/components/tools/Tools'
import { toast, loading } from '@/shared/utils/UIHelper'
import ActionsForm from '@/shared/mixins/actionsForm'
import DashboardRepository from '@/shared/http/repositories/report/dashboard.js'
import CardRepository from '@/shared/http/repositories/onboarding/card.js'

export default {
  name: 'LostList',
  mixins: [ActionsForm],
  data () {
    return {
      data: null,
      form: null,
      filter: null
    }
  },
  methods:{
    onLoad () {
      if (this.filter) {
        this.onLoadLeadLost(this.filter)
      }
    },
    async onLoadLeadLost (search) {
      loading.push()
      this.filter = search
      DashboardRepository.GetLeadsLost(search, this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then((response) => {
          this.onResultGrid(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onEdit (obj) {
      if (obj) {
        this.$router.push({ name: 'QuadroDetalhes', params: { cardID: obj.id, back: 'Dashboard' } })
      }
    },
    toCurrency (number) {
      return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(number)
    },
    formatValue(value) {
      return Tools.formatMoneyBR(value)
    },
    async onReactive (obj) {
      loading.push()
      let data = {
        description: ''
      }

      await CardRepository.UpdateStatus(obj.id, 0, data)
        .then(res => {
          let { code } = res.data

          if (code === 200) {
            toast.success(this.$lang.Common.Alerts.Success)
            this.onLoad()
            // this.onLoadLeadLost()
          } else {
            toast.error(this.$lang.Common.Alerts.Error)
          }
        })
        .catch(er => {
          console.log(er)
        })

      loading.pop()

      // this.onBack()
    },
  }
}
</script>